import React, { FC } from "react";

import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

import theme from "application/theme";
import { Button } from "application";

import { ErrorPageWrapper } from "./styles";
import { Not404Found } from "./notFoundImg";
import { PathNames } from "application/routes";
import { useAppSelector } from "application/store";
import { authSelector } from "common/auth/store/authStore";

export const NotFound404Page: FC = () => {
  const navigate = useNavigate();
  const { isAuth } = useAppSelector(authSelector);

  const backToHomePage = () =>
    isAuth ? navigate(PathNames.organizations) : navigate(PathNames.login);

  return (
    <ErrorPageWrapper>
      <Box display="flex" flexDirection="column" gap={2.5} alignItems="center">
        <Not404Found />
        <Typography variant="h1" color={theme.palette.common.grey900}>
          404
        </Typography>
        <Typography variant="button" color={theme.palette.common.grey900}>
          Page not found...
        </Typography>
        <Typography variant="overline" color={theme.palette.common.grey700}>
          Sorry, the page you are looking for doesn't exist or has been moved.
        </Typography>
        <Button label="Take me home" onClick={backToHomePage} width={30} />
      </Box>
    </ErrorPageWrapper>
  );
};
