import axios from "axios";
import { path, isNil } from "ramda";
import {
  authService,
  handleErrorNotification,
  handleErrorNotificationMessage,
} from "common";
import axiosInstance from "./axiosInstance";
import { handleLogout } from 'common/auth/store/actions';

const errorMsg = "Something went wrong. Please, try again";

const httpSuccessHandler = (httpResponse: any) => {
  const response: any | null = httpResponse.data;

  if (httpResponse.status === 304) {
    return null;
  }

  if (response.responseCode && response.responseCode !== 200) {
    throw new Error(httpResponse);
  }

  return isNil(response.data) ? response : response.data;
};

const commonFailureHandler = (err: any): any => {
  if (err.code === "ECONNABORTED") {
    handleErrorNotificationMessage(errorMsg);
  } else if (path(["response", "status"], err) === 304) {
    return null;
  } else handleErrorNotification(err);
  if (path(["response", "status"], err) === 401) {
    handleLogout();
    authService.eraseSessionData();
  }

  if (axios.isCancel(err)) {
    throw new Error(err);
  }

  throw err;
};

export const clientRequest = (reqProps: any): any => {
  const { method = "GET" } = reqProps;
  const { headers = {} } = reqProps;
  const { url } = reqProps;

  if (!headers["content-type"] && ["POST", "PUT", "PATCH"].includes(method)) {
    headers["content-type"] = "application/json";
  }
  const { timeout, params, data, responseType } = reqProps;

  return axiosInstance({
    url,
    method,
    params,
    headers,
    timeout,
    data,
    responseType,
  })
    .then(httpSuccessHandler)
    .catch(commonFailureHandler);
};
