import { Typography } from "@mui/material";
import React from "react";
import { SuccessIcon } from "../assets/SuccessIcon";
import { HelperTextWrapper } from "../styles";
import { helperTextColorHandler } from "./utils";

type PasswordValidationTextT = {
  helperText: string;
  validationParam: boolean | null;
};

export const PasswordValidationText = ({
  helperText,
  validationParam,
}: PasswordValidationTextT) => {
  return (
    <HelperTextWrapper mb={0.25}>
      <SuccessIcon color={helperTextColorHandler(validationParam)} />
      <Typography
        variant="caption"
        color={helperTextColorHandler(validationParam)}
        ml={1}
      >
        {helperText}
      </Typography>
    </HelperTextWrapper>
  );
};
