import { IconButton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/system";
import theme from "application/theme";

export const QuantityPickerWrapper = styled(Box)<{ error: boolean }>`
  border: 1px solid ${({error}) => error ? theme.palette.common.red600 : theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 0.5)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const IconButtonStyled = styled(IconButton)`
  &:hover {
    background: transparent;
  }
`;

export const QuantityPickerInput = styled(TextField)`
  height: ${theme.spacing(5.5)};
  & .MuiOutlinedInput-root {
    font-size: ${theme.spacing(2)};
    height: ${theme.spacing(5.5)};
    font-weight: ${theme.typography.fontWeightRegular};
  }
  & .MuiOutlinedInput-input {
    text-align: center;
    padding: ${theme.spacing(2)} 0;
  }
  & fieldset {
    border: none;
  }
  & input[type="number"] {
    -moz-appearance: textfield;
  }
  & input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabelWrapper = styled(Typography)<{ error?: boolean}>`
  color: ${({error}) => error ? theme.palette.common.red600 : theme.palette.common.grey800};
  white-space: nowrap;
  display: block;
  text-align: left;
  margin-bottom: ${theme.spacing(0.75)};
`;

export const LabelQuantity = styled("span")`
  color: ${theme.palette.common.orange600} !important;
  margin-left: ${theme.spacing(0.5)};
`;
