import styled from "@emotion/styled";
import { Box } from "@mui/material";
import theme from "application/theme";

export const LoaderWrapper = styled(Box)`
  width: ${theme.spacing(60)};
  height: ${theme.spacing(25)};
  background-color: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.spacing(1)};
`;
