import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const SelectButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(1)};
  cursor: pointer;
`;

export const StyledSelect = styled("select")`
  cursor: pointer;
`;

export const SelectContainer = styled(Box)`
  position: relative;
  margin: ${theme.spacing(0)};
  display: flex;
  align-items: center;
`;

export const DropdownStyle = styled(Box)<{ visible: number }>`
  position: absolute;
  top: ${theme.spacing(5)};
  left: 0;
  max-height: 40vmax;
  display: flex;
  flex-direction: column;
  transition: max-height 0.5s ease;
  overflow: scroll;
  display: ${(props) => props.visible !== 1 && "none"};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
`;

export const DropdownItem = styled(Box)<{ active: number }>`
  font-family: Rubik;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${theme.spacing(3)};
  font-size: ${theme.spacing(1.75)};
  font-weight: ${(props) => (props.active ? 500 : 400)};
  color: ${theme.palette.common.grey800};
  padding: ${theme.spacing(0, 1)};
  cursor: pointer;
  background-color: ${(props) =>
    !!props.active ? theme.palette.common.blue50 : "white"};
  &:hover,
  :focus,
  :focus:hover {
    background-color: ${(props) =>
      !!props.active
        ? theme.palette.common.blue50
        : theme.palette.common.grey100};
    outline: none;
  }
`;
