import React from "react";

import Modal from "@mui/material/Modal";

import { IModal } from "./types";

import { ModalWrapper } from "./styles";

import theme from "application/theme";
import { CloseOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";

export const ModalComponent = ({
  isOpen,
  handleClose,
  title,
  description,
  children,
  padding,
  width = "55%",
}: IModal) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapper width={width} padding={padding}>
        <CloseOutlined
          sx={{
            position: "absolute",
            top: theme.spacing(3),
            right: theme.spacing(3),
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        {title ? (
          <Typography variant="h5" color={theme.palette.common.grey900}>
            {title}
          </Typography>
        ) : (
          <></>
        )}
        {description ? (
          <Typography variant="overline" color={theme.palette.common.grey800}>
            {description}
          </Typography>
        ) : (
          <></>
        )}
        <>{children}</>
      </ModalWrapper>
    </Modal>
  );
};
