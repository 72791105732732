import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Divider, Typography } from "@mui/material";

import {
  calculateDaysUntilDate,
  formatDate,
  formatDateToYYYYMMDD,
} from "application/helpers";
import { Button, ModalComponent, QuantityPicker } from "application";
import DatePickerWithText from "application/components/DatePicker";
import theme from "application/theme";

import { CompanyNameStyled } from "./styles";
import { useAppDispatch } from "application/store";
import { StyledWarning } from "common/requests/styles";
import { showLessThan60Days } from "common/requests/utils";
import { handleRenewRequest } from "../store/actions";
import { handleRenewSubscriptionRequest } from "common/requests/store/actions";

const schema = yup.object().shape({
  phoneCredits: yup.number(),
  emailCredits: yup.number(),
  seats: yup.number(),
});

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  data: {lastDate: string; requestId: number};
  organizationId: number;
  organizationName: string;
  type: "HISTORY" | "REQUESTS";
}

export const RenewSubscriptionModal = ({
  isOpen,
  handleClose,
  data,
  organizationId,
  organizationName,
  type
}: Props) => {
  const [seatsInTeam, setSeatsInTeam] = useState<number>(0);
  const [emailCredits, setEmailCredits] = useState<number>(0);
  const [phoneCredits, setPhoneCredits] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [lastSubscriptionDate, setLastSubscriptionDate] = useState<
    Date | [Date | null, Date | null] | null
  >(null);
  const [dateNotSelected, setDateNotSelected] = useState<boolean>(false);
  const [dateIsLess, setDateIsLess] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const onSubmit = () => {
    const date = new Date(data.lastDate);
    const lastDate: any = lastSubscriptionDate;
    if (!(emailCredits || phoneCredits)) setError(true);
    if (!lastSubscriptionDate) setDateNotSelected(true);
    if (lastDate < date) setDateIsLess(true);
    if (
      (emailCredits || phoneCredits) &&
      lastSubscriptionDate !== null &&
      lastDate > date
    ) {
      setError(false);
      handleClose();
      if (type === "HISTORY") {
        dispatch(
          handleRenewRequest({
            requestId: data.requestId,
            organizationId: organizationId,
            emailCredits,
            phoneCredits,
            seats: seatsInTeam,
            startDate: formatDateToYYYYMMDD(date),
            lastDate: formatDateToYYYYMMDD(lastDate),
          })
        );
      } else if (type === "REQUESTS") {
        dispatch(
          handleRenewSubscriptionRequest({
            organizationId,
            requestId: data.requestId,
            emailCredits,
            phoneCredits,
            seats: seatsInTeam,
            startDate: formatDateToYYYYMMDD(date),
            lastDate: formatDateToYYYYMMDD(lastDate),
          })
        );
      }
    }
  };

  useEffect(() => {
    if (emailCredits || phoneCredits) setError(false);
  }, [emailCredits, phoneCredits]);

  const onClose = () => {
    setPhoneCredits(0);
    setEmailCredits(0);
    setLastSubscriptionDate(null);
    setDateNotSelected(false);
    setDateIsLess(false);
    setError(false);
    handleClose();
  };

  const { register } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      phoneCredits: 0,
      emailCredits: 0,
      seats: 0,
    },
  });

  const getRegistrationDate = (
    param: Date | [Date | null, Date | null] | null
  ) => {
    setDateNotSelected(false);
    setDateIsLess(false);
    setLastSubscriptionDate(param);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={onClose}
      padding={theme.spacing(3)}
      title="Renew subscription"
      width={530}
    >
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        gap={theme.spacing(4)}
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography
            variant="overline"
            color={theme.palette.common.grey800}
            textAlign={"start"}
          >
            Renew &nbsp;
            <CompanyNameStyled>{organizationName}'s</CompanyNameStyled>&nbsp;
            subscription as discussed with the organisation's administrator.
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap={0.75}>
              <Typography
                variant="caption"
                color={theme.palette.common.grey800}
              >
                Last day of current subscription
              </Typography>
              <Box
                p={theme.spacing(1.15, 0)}
                display="flex"
                justifyContent="left"
                alignItems="center"
              >
                <Typography
                  textAlign="left"
                  variant="overline"
                  color={theme.palette.common.grey900}
                  mr={2}
                >
                  {formatDate(data.lastDate)}
                </Typography>
                {calculateDaysUntilDate(data.lastDate) < 60 && (
                  <StyledWarning>
                    {showLessThan60Days(calculateDaysUntilDate(data.lastDate))}
                  </StyledWarning>
                )}
              </Box>
            </Box>
            <Box display="flex" width={theme.spacing(29)}>
              <DatePickerWithText
                width={theme.spacing(29)}
                label="Last day of new subscription"
                startValue={null}
                getValue={getRegistrationDate}
                selectsRange={false}
              />
            </Box>
          </Box>
          <Typography
            variant="button"
            color={theme.palette.common.grey800}
            textAlign={"start"}
          >
            Assign seats and credits to &nbsp;
            <CompanyNameStyled>{organizationName}:</CompanyNameStyled>
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={theme.spacing(4)}
          >
            <QuantityPicker
              maxWidth={theme.spacing(17)}
              label="Seats in a team"
              register={register("seats")}
              getValue={setSeatsInTeam}
              defaultValue={0}
              error={error}
            />
            <QuantityPicker
              maxWidth={theme.spacing(17)}
              label="Email credits"
              register={register("emailCredits")}
              getValue={setEmailCredits}
              defaultValue={0}
              error={error}
            />
            <QuantityPicker
              maxWidth={theme.spacing(17)}
              label="Phone credits"
              register={register("phoneCredits")}
              getValue={setPhoneCredits}
              defaultValue={0}
              error={error}
            />
          </Box>

          {error || dateNotSelected || dateIsLess ? (
            <Typography variant="caption" color={theme.palette.common.red600}>
              {error
                ? "Select at least 1 credit export"
                : dateNotSelected
                ? "Choose the date of new subscription end"
                : "Choose date later than last day of subscription ends"}
            </Typography>
          ) : (
            <></>
          )}
          <Divider />
        </Box>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button label="Cancel" width={29} type="button" onClick={onClose} />
          <Button
            label="Renew subscription"
            width={29}
            type="submit"
            disabled={error || dateNotSelected || dateIsLess}
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
