import React from "react";

import { ModalComponent } from "application";

import { QuestionIcon } from "application/assets/QuestionIcon";
import Button from "application/components/Button";

import { useAppDispatch, useAppSelector } from "application/store";
import {
  closeAddModal,
  closeResetModal,
  isResetModalOpen,
  setCreatedOrganization,
} from "../store";

import theme from "application/theme";

import { Box, Typography } from "@mui/material";

import { RedIconBackground } from "./styles";

export const ResetModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isResetModalOpen);

  const handleClose = () => {
    dispatch(setCreatedOrganization({}));
    dispatch(closeResetModal());
    dispatch(closeAddModal());
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={theme.spacing(3)}
      width={475}
    >
      <Box
        textAlign="center"
        display={"flex"}
        flexDirection={"column"}
        gap={theme.spacing(3)}
      >
        <Box display="flex" width={"100%"} justifyContent="center">
          <RedIconBackground>
            <QuestionIcon />
          </RedIconBackground>
        </Box>
        <Box>
          <Typography variant="subtitle1" color={theme.palette.common.grey800}>
            Are you sure to cancel current request? This settings will not be
            saved.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button
            width={15}
            label="No"
            height={5.5}
            type="button"
            onClick={() => {
              dispatch(closeResetModal());
            }}
          />
          <Button
            label="Cancel"
            height={5.5}
            red
            width={15}
            type="submit"
            onClick={handleClose}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
