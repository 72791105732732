import React from "react";

import { useAppDispatch, useAppSelector } from "application/store";
import {
  openActivateModal,
  openArchiveModal,
  organizationsSelector,
} from "../store";

import Button from "application/components/Button";
import BadgeWithDot from "application/components/BadgeWithDot";
import { ExportIcon } from "application/assets/ExportIcon";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Edit } from "@mui/icons-material";

import theme from "application/theme";

import { LeftSideHeader } from "../styles";
import { handleExportOrgProfileById } from "../store/actions";

const ProfileHeader = ({
  name,
  onEdit,
  id,
  type,
}: {
  name: string;
  onEdit: () => void;
  id: number;
  type: string;
}) => {
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const { activationStatus } = organizationProfile;
  const dispatch = useAppDispatch();

  const handleExportToCsv = () => {
    dispatch(handleExportOrgProfileById(id));
  };

  return (
    <Box display={"flex"} justifyContent={"space-between"} mb={1.5}>
      <LeftSideHeader>
        <Box display={"flex"} alignItems={"center"}>
          <Typography
            variant="h4"
            color={theme.palette.common.grey900}
            mr={1.5}
          >
            {name}
          </Typography>
          <BadgeWithDot status={activationStatus} />
        </Box>
        <Typography variant="overline" color={theme.palette.common.grey700}>
          {type}
        </Typography>
      </LeftSideHeader>
      <Box display={"flex"}>
        <Button
          label="Export contacts"
          startAdornment={<ExportIcon />}
          mr={1.5}
          height={5.5}
          onClick={handleExportToCsv}
          type="button"
        />
        <Button
          label="Edit"
          startAdornment={<Edit sx={{ color: theme.palette.common.grey800 }} />}
          mr={1.5}
          height={5.5}
          onClick={onEdit}
          type="button"
        />
        {activationStatus === "ACTIVE" ? (
          <Button
            label="Archive"
            height={5.5}
            type="submit"
            onClick={() => dispatch(openArchiveModal())}
          />
        ) : (
          <Button
            label="Activate"
            height={5.5}
            type="submit"
            onClick={() => dispatch(openActivateModal())}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProfileHeader;