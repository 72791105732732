export enum ActivationStatus {
  active = "ACTIVE",
  inviteSent = "INVITE_SENT",
  unconfirmed = "UNCONFIRMED",
}

export interface SaasAdminI {
  id: number;
  name: string;
  email: string;
  registrationDate: string;
  lastDateOfActivity: string;
  status: ActivationStatus;
  manuallyUploadedCompanies: number;
}
