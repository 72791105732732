import {
  ListItemText,
  ListItem,
  ListItemButton,
  Box,
  ListItemIcon,
  Badge,
  Typography,
} from "@mui/material";

import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/system";

import theme from "application/theme";
import { IListProps } from "./types";

export const ListItemTextCustom = styled(ListItemText)<IListProps>`
  color: ${(props) =>
    props.disabled
      ? theme.palette.common.grey400
      : theme.palette.common.grey900};

  opacity: ${(props) => (props.open ? 1 : 0)};
  span {
    font-size: ${theme.spacing(2)};
  }
`;

export const LogoWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const ListItemStyled = styled(ListItem)<IListProps>`
  ${(props) =>
    props.disabled &&
    `
  * {cursor: not-allowed;}
  `}
  height: ${theme.spacing(6)};
  width: ${(props) => !props.open && theme.spacing(6)};
  border-radius: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};

  &:hover {
    background: ${theme.palette.common.grey100};
    border-radius: ${theme.spacing(1)};
  }

  svg path {
    fill: ${(props) =>
      props.disabled
        ? theme.palette.common.grey400
        : theme.palette.common.grey900};
  }

  .MuiListItemButton-root {
    margin: 0;
  }

  .MuiListItemButton-root:hover {
    background-color: transparent;
  }

  ${(props) =>
    props.active &&
    `
      color: ${theme.palette.common.orange800};
      background: ${theme.palette.common.orange50};

      svg path {
        fill: ${!props.disabled && theme.palette.common.orange800};
      }
      span {
        font-weight: 500;
        color: ${theme.palette.common.orange800};
      }
    `}

  ${(props) =>
    props.open &&
    `
      border-radius: ${theme.spacing(1, 0, 0, 1)};
      &:hover {
        background: ${theme.palette.common.grey100};
        border-radius: ${theme.spacing(1, 0, 0, 1)};
      }
    `}

  ${(props) =>
    props.open &&
    props.active &&
    `border-right: ${theme.spacing(0.25)} solid ${
      theme.palette.common.orange800
    };`}
`;

export const BuilderNavLogo = styled("img")<IListProps>`
  max-width: ${theme.spacing(17.5)};
  width: 100%;
  transition: ${theme.transitions.create(["width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.complex,
  })};
  height: ${theme.spacing(6.25)};
  margin-bottom: ${theme.spacing(2.5)};
`;

export const ListItemButtonStyled = styled(ListItemButton)`
  padding: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(1)};
  height: ${theme.spacing(4)};
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 0;
  justify-content: center;
`;

export const StyledBadge = styled(Badge)<{ active: number }>`
  & .MuiBadge-badge {
    right: -18px;
    top: 50%;
    background-color: ${(props) =>
      props.active ? theme.palette.common.white : theme.palette.common.blue50};
    color: ${(props) =>
      props.active
        ? theme.palette.common.orange800
        : theme.palette.common.blue700};
  }
`;

export const ListWrapper = styled(Box)<IListProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) =>
    !props.open &&
    `
      align-items: center;
    `};
  height: 100%;
  padding-top: ${theme.spacing(2.5)};
`;

const openedMixin = `
  width: ${theme.spacing(31.25)};
  overflow-x: hidden;
  padding: ${theme.spacing(4, 0, 2, 2)};
  transition: ${theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  })};
`;

const closedMixin = `
  overflow-x: hidden;
  width: ${theme.spacing(9.5)};
  padding: ${theme.spacing(4, 1, 2)};
  transition: ${theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })};
`;

export const DrawerStyled = styled(MuiDrawer)<IListProps>`
  width: ${theme.spacing(31.25)};
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;

  ${(props) =>
    props.open
      ? `
        ${openedMixin}
        & .MuiDrawer-paper {
          ${openedMixin}
        }
      `
      : `
        ${closedMixin}
        & .MuiDrawer-paper {
          ${closedMixin}
        }
  `}
`;


export const TypographyWithOverflow = styled(Typography)`
  max-width: ${theme.spacing(25)};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
