import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";
import { HistoryRequestI, OrganizationProfile } from "common";

export const emptyOrganizationProfile = {
  activationDate: null,
  activationStatus: "",
  companyName: "",
  contactPerson: "",
  emailContactPerson: "",
  emailCredits: null,
  id: 0,
  jobPosition: "",
  lastDateOfActivity: null,
  location: "",
  organizationType: null,
  phone: "",
  phoneCredits: null,
  registrationDate: null,
  seats: null,
  timezone: "",
  usedEmailCredits: null,
  usedPhoneCredits: null,
  usedSeats: null,
  website: "",
  organizationStructureAccessPermitted: false,
  companyDataCredits: null,
  usedCompanyDataCredits: null
};

export const initialState = {
  createdOrganization: {} as OrganizationProfile,
  organizations: [
    {
      activationDate: "",
      activationStatus: "",
      companyName: "",
      contactPerson: "",
      emailContactPerson: "",
      emailCredits: 0,
      id: 0,
      jobPosition: "",
      seats: 1,
      lastDateOfActivity: null,
      usedSeats: null,
      location: "",
      organizationType: null,
      phone: "",
      phoneCredits: 0,
      registrationDate: "",
      timezone: "",
      usedEmailCredits: 0,
      usedPhoneCredits: 0,
      website: "",
      status: "",
      type: "",
      organizationStructureAccessPermitted: false,
      companyDataCredits: 0,
      usedCompanyDataCredits: 0
    },
  ],
  pageable: {
    totalPages: 1,
    totalElements: 0,
    pageNumber: 0,
    numberOfElements: 0,
  },
  organizationProfile: emptyOrganizationProfile,
  suggestions: [
    {
      id: 0,
      organizationName: "",
    },
  ],
  registrationDate: [null, null] as [Date | null, Date | null],
  activationDate: [null, null] as [Date | null, Date | null],
  addModalOpen: false,
  resultModalOpen: false,
  resetModalOpen: false,
  activateModalOpen: false,
  successActivatedModalOpen: false,
  archiveModalOpen: false,
  isAddCreditsModalOpen: false,
  editModalOpen: false,
  renewSubscriptionModal: false,
  historyRequests: [] as HistoryRequestI[],
  addOrganizationEmailError: false,
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setCreatedOrganization: (state, { payload }) => {
      state.createdOrganization = payload;
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setOrganizationProfile: (state, action) => {
      state.organizationProfile = action.payload;
    },
    setSearchSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    setPageable: (state, action) => {
      state.pageable = action.payload;
    },
    setRegistrationDate: (state, action) => {
      state.registrationDate = action.payload;
    },
    setActivationDate: (state, action) => {
      state.activationDate = action.payload;
    },
    setHistoryRequests: (state, action) => {
      state.historyRequests = action.payload;
    },
    setAddOrganizationEmailError: (state, action) => {
      state.addOrganizationEmailError = action.payload;
    },
    openAddModal: (state) => {
      state.addModalOpen = true;
    },
    closeAddModal: (state) => {
      state.addModalOpen = false;
    },
    openResultModal: (state) => {
      state.resultModalOpen = true;
    },
    closeResultModal: (state) => {
      state.resultModalOpen = false;
    },
    openResetModal: (state) => {
      state.resetModalOpen = true;
    },
    closeResetModal: (state) => {
      state.resetModalOpen = false;
    },
    openActivateModal: (state) => {
      state.activateModalOpen = true;
    },
    closeActivateModal: (state) => {
      state.activateModalOpen = false;
    },
    openArchiveModal: (state) => {
      state.archiveModalOpen = true;
    },
    closeArchiveModal: (state) => {
      state.archiveModalOpen = false;
    },
    openSuccessActivatedModal: (state) => {
      state.successActivatedModalOpen = true;
    },
    closeSuccessActivatedModal: (state) => {
      state.successActivatedModalOpen = false;
    },
    openAddCreditsModal: (state) => {
      state.isAddCreditsModalOpen = true;
    },
    closeAddCreditsModal: (state) => {
      state.isAddCreditsModalOpen = false;
    },
    openEditModal: (state) => {
      state.editModalOpen = true;
    },
    closeEditModal: (state) => {
      state.editModalOpen = false;
    },
    openRenewSubscription: (state) => {
      state.renewSubscriptionModal = true;
    },
    closeRenewSubscription: (state) => {
      state.renewSubscriptionModal = false;
    },

  },
});

export default organizationsSlice.reducer;

export const organizationsSelector = () => store.getState().organizations;

export const isOpenModal = () => store.getState().organizations.addModalOpen;

export const isResultModalOpen = () =>
  store.getState().organizations.resultModalOpen;

export const isResetModalOpen = () =>
  store.getState().organizations.resetModalOpen;

export const addedOrgResponse = () =>
  store.getState().organizations.createdOrganization;

export const isActivatedModalOpen = () =>
  store.getState().organizations.activateModalOpen;

export const isArchivedModalOpen = () =>
  store.getState().organizations.archiveModalOpen;

export const isSuccessActivatedModalOpen = () =>
  store.getState().organizations.successActivatedModalOpen;

export const isEditModalOpen = () =>
  store.getState().organizations.editModalOpen;

export const { setOrganizations } = organizationsSlice.actions;
export const { setCreatedOrganization } = organizationsSlice.actions;
export const { setOrganizationProfile } = organizationsSlice.actions;
export const { setSearchSuggestions } = organizationsSlice.actions;
export const { setPageable } = organizationsSlice.actions;
export const { setRegistrationDate } = organizationsSlice.actions;
export const { setActivationDate } = organizationsSlice.actions;
export const { setHistoryRequests } = organizationsSlice.actions;
export const { setAddOrganizationEmailError } = organizationsSlice.actions;

export const { openAddModal } = organizationsSlice.actions;
export const { openResultModal } = organizationsSlice.actions;
export const { openResetModal } = organizationsSlice.actions;
export const { openActivateModal } = organizationsSlice.actions;
export const { openArchiveModal } = organizationsSlice.actions;
export const { openSuccessActivatedModal } = organizationsSlice.actions;
export const { openAddCreditsModal } = organizationsSlice.actions;
export const { openEditModal } = organizationsSlice.actions;
export const { openRenewSubscription } = organizationsSlice.actions;

export const { closeAddModal } = organizationsSlice.actions;
export const { closeResultModal } = organizationsSlice.actions;
export const { closeResetModal } = organizationsSlice.actions;
export const { closeActivateModal } = organizationsSlice.actions;
export const { closeArchiveModal } = organizationsSlice.actions;
export const { closeSuccessActivatedModal } = organizationsSlice.actions;
export const { closeAddCreditsModal } = organizationsSlice.actions;
export const { closeEditModal } = organizationsSlice.actions;
export const { closeRenewSubscription } = organizationsSlice.actions;
