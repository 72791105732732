import React from "react";

import { useNavigate } from "react-router-dom";

import {
  addedOrgResponse,
  closeAddModal,
  closeResultModal,
  isResultModalOpen,
  setCreatedOrganization,
} from "../store";

import { SuccessIcon } from "application/assets/SuccessIcon";
import Button from "application/components/Button";
import theme from "application/theme";

import { Box, Typography } from "@mui/material";

import { CompanyNameStyled, IconBackground } from "./styles";
import { PathNames } from "application/routes";
import { ModalComponent } from "application";
import { useAppDispatch, useAppSelector } from "application/store";
import { RoutesParam } from "application/routes/constants";
import { handleGetOrgWaitingForActivation } from "common/requests/store/actions";

export const ResultModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isResultModalOpen);
  const addedOrganization = useAppSelector(addedOrgResponse);

  const handleClose = () => {
    dispatch(setCreatedOrganization({}));
    dispatch(closeResultModal());
  };

  const onSubmit = () => {
    handleClose();
    dispatch(closeAddModal());
    navigate(`${PathNames.requests}/${RoutesParam.waitingForActivation}`);
    dispatch(
      handleGetOrgWaitingForActivation({
        companyName: "",
        registrationDateFrom: "",
        registrationDateTo: "",
      })
    );
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={theme.spacing(3)}
      width={475}
    >
      <Box
        textAlign="center"
        display={"flex"}
        flexDirection={"column"}
        gap={theme.spacing(3)}
      >
        <Box display="flex" width={"100%"} justifyContent="center">
          <IconBackground>
            <SuccessIcon />
          </IconBackground>
        </Box>
        <Box>
          <Typography variant="h5" color={theme.palette.common.grey800}>
            Request of&nbsp;
            <CompanyNameStyled>
              {addedOrganization.companyName}
            </CompanyNameStyled>
            &nbsp; was successful created.
          </Typography>
        </Box>
        <Typography variant="caption" color={theme.palette.common.grey700}>
          This organization appeared in requests tab up to activation.
        </Typography>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button
            label="Add one else organization"
            height={5.5}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Go to requests tab"
            height={5.5}
            // width={15}
            type="submit"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
