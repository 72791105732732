import React from "react";

export const AddCredits = () => {
  return (
    <svg
      width="83"
      height="82"
      viewBox="0 0 83 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="41.5" cy="41" r="41" fill="#EBF2FF" />
      <mask
        id="mask0_267_21798"
        maskUnits="userSpaceOnUse"
        x="20"
        y="20"
        width="42"
        height="42"
      >
        <rect x="20" y="20" width="42" height="42" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_267_21798)">
        <path
          d="M39.25 53.25V42.75H28.75V39.25H39.25V28.75H42.75V39.25H53.25V42.75H42.75V53.25H39.25Z"
          fill="#2863EC"
        />
      </g>
    </svg>
  );
};
