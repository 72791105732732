import { Breakpoint } from '@mui/material';

export interface MBreakpoints {
    keys: Breakpoint[];
    values: { [key in Breakpoint]: number };
    up: (key: Breakpoint | number) => string;
    down: (key: Breakpoint | number) => string;
    between: (start: Breakpoint | number, end: Breakpoint | number) => string;
    only: (key: Breakpoint) => string;
}
const VALUES = {
    xs: 320,
    sm: 480,
    md: 600,
    lg: 900,
    xl: 1200,
};

const breakpoints: Partial<MBreakpoints> = {
    values: VALUES,
};
export default breakpoints;
