import React from "react";
import { Provider } from "react-redux";

import { store } from "./store";

import { AppRouter } from "./routes";

import theme from "application/theme";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import withNetworkDetector from "./hoc/NetworkDetector";

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </ThemeProvider>
);

export default withNetworkDetector(App);
