import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import theme from "application/theme";

import { useAppDispatch, useAppSelector } from "application/store";
import {
  isEditModalOpen,
  openEditModal,
  organizationsSelector,
} from "../store";
import {
  handleEditOrganizationOnOrganization,
  handleGetAllHistoryRequests,
  handleGetOrganizationProfile,
} from "../store/actions";
import { handleGetTimezoneItems } from "common/dictionary";

import { SuccessActivatedModal } from "../modal/SuccessActivatedModal";
import { ActivateModal } from "../modal/ActivateModal";
import { ArchiveModal } from "../modal/ArchiveModal";
import { Box } from "@mui/system";
import ProfileHeader from "./ProfileHeader";
import ContactBlock from "./ContactBlock";
import InfoBlock from "./InfoBlock";

import { KeyboardArrowLeft } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { BackLink } from "../styles";
import { EditOrganizationModal } from "../modal/EditOrganizationModal";
import { OrganizationProfile as OrganizationProfileI } from "../store/types";
import RequestHistoryHeader from "./RequestHistoryHeader";
import RequestHistory from "./RequestHistory";

const OrganizationProfile = () => {
  const navigate = useNavigate();
  const { id: profileId } = useParams();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isEditModalOpen);

  const ogranizationProfile = () => {
    if (profileId !== undefined)
      dispatch(handleGetOrganizationProfile(profileId));
  };

  const organizationProfileRequest = () => {
    if (profileId !== undefined)
      dispatch(handleGetAllHistoryRequests(Number(profileId)));
  };
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const { companyName, organizationType, id } = organizationProfile;

  const onConfirmEdit = (data: OrganizationProfileI) => {
    if (profileId !== undefined) {
      dispatch(handleEditOrganizationOnOrganization(data, profileId));
    }
  };

  useEffect(() => {
    ogranizationProfile();
    organizationProfileRequest();
    dispatch(handleGetTimezoneItems());
  }, []);

  return (
    <Box pb={4}>
      <BackLink onClick={() => navigate(-1)}>
        <KeyboardArrowLeft sx={{ color: theme.palette.common.grey700 }} />
        <Typography
          variant="body2"
          color={theme.palette.common.grey700}
          ml={0.5}
        >
          Back
        </Typography>
      </BackLink>
      <ProfileHeader
        name={companyName}
        onEdit={() => dispatch(openEditModal())}
        type={organizationType ?? ""}
        id={id}
      />
      <InfoBlock />
      <ContactBlock />
      <RequestHistoryHeader />
      <RequestHistory />
      {profileId && isOpen && (
        <EditOrganizationModal
          organization={organizationProfile}
          onConfirm={onConfirmEdit}
        />
      )}
      <ActivateModal status={organizationProfile.activationStatus}/>
      <SuccessActivatedModal />
      <ArchiveModal />
    </Box>
  );
};

export default OrganizationProfile;
