import React from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormInput, ModalComponent } from "application";
import theme from "application/theme";
import Button from "application/components/Button";
import { NewManagerIcon } from "application/assets/NewManagerIcon";

import { Box, Typography } from "@mui/material";
import { useAppDispatch } from "application/store";
import { handleInviteSaasAdmin } from "./store/actions";
import { dateFormatter } from "common/organization/OrganizationsList/utils";

export const schema = yup.object().shape({
  userName: yup.string().required("User name is required"),
  userEmail: yup.string().email("Invalid email").required("Email is required"),
});

export const AddNewSaasAdminModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
  } = useForm<{ userName: string; userEmail: string }>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const onSubmit = async (data: { userName: string; userEmail: string }) => {
    const date = new Date();
    dispatch(
      handleInviteSaasAdmin({
        name: data.userName,
        email: data.userEmail,
        lastDateOfActivity: dateFormatter(date) ?? "",
        registrationDate: dateFormatter(date) ?? "",
      })
    );
  };
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={4}
      width={475}
    >
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box display="flex" justifyContent="center" mb={4} width="100%">
          <NewManagerIcon />
        </Box>
        <Typography variant="h5" color={theme.palette.common.grey800}>
          Add new manager
        </Typography>
        <Box
          maxWidth={theme.spacing(40)}
          mt={1.5}
          display="flex"
          flexDirection="column"
          gap={errors.userName?.message ? 3 : 0.5}
          mb={errors.userEmail?.message ? 6 : 3}
        >
          <Box>
            <FormInput
              label="Name"
              placeholder="Enter user name"
              register={register("userName")}
              value={watch("userName")}
              error={errors.userName?.message}
              isValid={dirtyFields.userName}
              name="name"
            />
          </Box>
          <Box>
            <FormInput
              label="Email"
              placeholder="Enter user email"
              register={register("userEmail")}
              value={watch("userEmail")}
              error={errors.userEmail?.message}
              isValid={dirtyFields.userEmail}
              name="email"
              type="email"
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button
            label="Cancel"
            height={5.5}
            width={15}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Sent Invite"
            height={5.5}
            width={15}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
