import React, { useState } from "react";

import { useAppDispatch, useAppSelector } from "application/store";

import theme from "application/theme";

import { requestsSelector } from "../store";
import { showLessThan60Days } from "../utils";

import { Box } from "@mui/system";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { ArchiveBtnStyled, StructureWrapper, StyledWarning } from "../styles";
import { Button, TableHeadComponent } from "application";
import {
  calculateDaysUntilDate,
  formatDate,
  getTimeByTimeZone,
} from "application/helpers";
import EmptyTableRequests from "../emptyTableData";
import { RenewSubscriptionsI } from "../store/types";
import { RenewSubscriptionModal } from "common/organization/modal/RequestHistoryRenewSubscription";
import { handleDeclineRenewSubscriptionRequest } from "../store/actions";

const tableHead = [
  "Manager Name",
  "Email",
  "Phone",
  "Location",
  "Request Date",
  "Last day of subscription",
  "Actions",
];

const RequestsForSubscriptionRenewalTable = () => {
  const { renewRequests } = useAppSelector(requestsSelector);
  const dispatch = useAppDispatch();

  const [isOpen, setOpen] = useState(false);
  const [orgData, setOrgData] = useState<RenewSubscriptionsI>(
    {} as RenewSubscriptionsI
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StructureWrapper>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: theme.spacing(1) }}
      >
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHeadComponent tableHead={tableHead} />

          <TableBody>
            {renewRequests.length ? (
              renewRequests.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{
                    height: theme.spacing(7.5),
                    "&:last-child td, &:last-child th": {
                      border: theme.spacing(2),
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.managerName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.phone}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                          variant="caption"
                          color={theme.palette.common.grey800}
                        >
                          {row.location}
                        </Typography>
                        <Typography
                          variant="caption"
                          color={theme.palette.common.grey600}
                        >
                          {getTimeByTimeZone(row.location)}
                        </Typography>
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.lastDate ? formatDate(row.lastDate) : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                      sx={{ display: "inline-block", alignItems: "center" }}
                      mr={2}
                    >
                      {row.lastDate ? formatDate(row.lastDate) : ""}
                    </Typography>
                    {calculateDaysUntilDate(row.lastDate) < 60 && (
                      <StyledWarning>
                        {showLessThan60Days(
                          calculateDaysUntilDate(row.lastDate)
                        )}
                      </StyledWarning>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap={2} alignItems="center">
                      <Box
                        onClick={() =>
                          dispatch(
                            handleDeclineRenewSubscriptionRequest(row.id)
                          )
                        }
                      >
                        <ArchiveBtnStyled variant="body2">
                          Decline
                        </ArchiveBtnStyled>
                      </Box>
                      <Button
                        label="Renew"
                        height={4}
                        fontSize={theme.spacing(1.75)}
                        onClick={() => {
                          setOrgData(row);
                          setOpen(true);
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!renewRequests.length ? <EmptyTableRequests /> : <></>}
      <RenewSubscriptionModal
        isOpen={isOpen}
        handleClose={handleClose}
        organizationId={orgData.orgId}
        organizationName={orgData.managerName}
        data={{ lastDate: orgData.lastDate, requestId: orgData.id }}
        type="REQUESTS"
      />
    </StructureWrapper>
  );
};

export default RequestsForSubscriptionRenewalTable;
