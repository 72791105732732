import theme from "application/theme";
import React from "react";

export const QuestionIcon = ({
  color = theme.palette.common.red600,
}: {
  color?: string;
}) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <mask
          id="mask0_263_16231"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="42"
          height="42"
        >
          <rect id="Bounding box" width="42" height="42" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_263_16231)">
          <path
            id="question_mark"
            d="M18.5499 28C18.5499 25.6375 18.7614 23.9385 19.1843 22.9031C19.6072 21.8677 20.5041 20.7375 21.8749 19.5125C23.0707 18.4625 23.9822 17.551 24.6093 16.7781C25.2364 16.0052 25.5499 15.1229 25.5499 14.1313C25.5499 12.9354 25.1489 11.9437 24.3468 11.1562C23.5447 10.3688 22.4291 9.975 20.9999 9.975C19.5124 9.975 18.3822 10.4271 17.6093 11.3313C16.8364 12.2354 16.2895 13.1542 15.9687 14.0875L11.4624 12.1625C12.0749 10.2958 13.1978 8.67708 14.8312 7.30625C16.4645 5.93542 18.5207 5.25 20.9999 5.25C24.0624 5.25 26.4176 6.10312 28.0655 7.80938C29.7134 9.51562 30.5374 11.5646 30.5374 13.9563C30.5374 15.4146 30.2239 16.6615 29.5968 17.6969C28.9697 18.7323 27.9853 19.9062 26.6437 21.2188C25.2145 22.5896 24.3468 23.6323 24.0405 24.3469C23.7343 25.0615 23.5812 26.2792 23.5812 28H18.5499ZM20.9999 38.5C20.0374 38.5 19.2134 38.1573 18.528 37.4719C17.8426 36.7865 17.4999 35.9625 17.4999 35C17.4999 34.0375 17.8426 33.2135 18.528 32.5281C19.2134 31.8427 20.0374 31.5 20.9999 31.5C21.9624 31.5 22.7864 31.8427 23.4718 32.5281C24.1572 33.2135 24.4999 34.0375 24.4999 35C24.4999 35.9625 24.1572 36.7865 23.4718 37.4719C22.7864 38.1573 21.9624 38.5 20.9999 38.5Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
