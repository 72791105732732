import React from "react";
import {Typography} from "@mui/material";
import theme from "application/theme";
import { formatNumbersWithSeparator } from 'application/utils/numberUtils';

interface Props {
    label: string;
    value?: number | null;
}
export const CreditsQuantityItem = ({ label, value }: Props) => {
    if (value && value > 0) {
        return (
            <Typography
                variant="caption"
                color={theme.palette.common.grey800}>
                {`${label}: ${formatNumbersWithSeparator(value)}`}
            </Typography>
        )
    }
    return <></>
}