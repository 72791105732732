import React, { useEffect } from "react";

import DatePickerWithText from "application/components/DatePicker";
import theme from "application/theme";
import { useAppDispatch, useAppSelector } from "application/store";

import { AddOrganizationModal, handleGetTimezoneItems } from "common";

import {
  handleGetOrganizationsSearchSuggestions,
  handleGetOrganizations,
} from "../store/actions";
import {
  organizationsSelector,
  setActivationDate,
  setRegistrationDate,
} from "../store";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { dateFormatter } from "./utils";

import { Autocomplete, Pagination } from "application";
import { ListHeaderWrapper, ListWrapper } from "../styles";
import OrganizationList from "./OrganizationsTable";
import { ResultModal } from "../modal/ResultModal";
import { ResetModal } from "../modal/ResetModal";
import { useLocation, useNavigate } from "react-router-dom";
import {useOrganizations} from "../hooks/useOrganizations";

const Organizations = () => {
  const dispatch = useAppDispatch();
  const { suggestions } = useAppSelector(organizationsSelector);
  const location = useLocation();
  const navigate = useNavigate();
  const {fetchOrganizations} = useOrganizations();

  const getActivationDate = (
    param: Date | [Date | null, Date | null] | null
  ) => {
    if (Array.isArray(param)) {
      const [startRegistrationDate, endRegistrationDate] = param;
      dispatch(setActivationDate([startRegistrationDate, endRegistrationDate]));
    }
  };
  const getRegistrationDate = (
    param: Date | [Date | null, Date | null] | null
  ) => {
    if (Array.isArray(param)) {
      const [startRegistrationDate, endRegistrationDate] = param;
      dispatch(
        setRegistrationDate([startRegistrationDate, endRegistrationDate])
      );
    }
  };

  const { pageable, registrationDate, activationDate } = useAppSelector(
    organizationsSelector
  );

  const currentPage = Number(location.search.split("?")[1]);

  const activationDateFrom = dateFormatter(activationDate[0]);
  const activationDateTo = dateFormatter(activationDate[1]);
  const registrationDateFrom = dateFormatter(registrationDate[0]);
  const registrationDateTo = dateFormatter(registrationDate[1]);

  useEffect(() => {
    dispatch(handleGetTimezoneItems());
    dispatch(handleGetOrganizationsSearchSuggestions());
  }, []);

  useEffect(() => {
    fetchOrganizations(0);
    if (!!registrationDate[0] || !!activationDate[0]) {
      navigate(`${location.pathname}`);
    }
  }, [registrationDate, activationDate]);

  return (
    <>
      <ListWrapper>
        <ListHeaderWrapper>
          <Box display="flex" flexDirection="column">
            <Typography
              variant="caption"
              color={theme.palette.common.grey800}
              sx={{ display: "block" }}
              mb={0.75}
            >
              Search for companies
            </Typography>
            <Autocomplete suggestions={suggestions} />
          </Box>
          <Box display={"flex"}>
            <Box>
              <DatePickerWithText
                label="Registration date"
                startValue={registrationDate}
                getValue={getRegistrationDate}
              />
            </Box>
            <Box ml={1.5}>
              <DatePickerWithText
                label="Activation date"
                startValue={activationDate}
                getValue={getActivationDate}
              />
            </Box>
          </Box>
        </ListHeaderWrapper>
        <OrganizationList />
        <Pagination
          totalElements={pageable.totalElements}
          numberOfElements={pageable.numberOfElements}
          totalPages={pageable.totalPages}
          pageRequest={fetchOrganizations}
          currentPage={isNaN(currentPage) ? 1 : currentPage}
          currentPath={location.pathname}
        />
      </ListWrapper>
      <AddOrganizationModal />
      <ResultModal />
      <ResetModal />
    </>
  );
};

export default Organizations;
