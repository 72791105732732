import React from "react";

import theme from "application/theme";

import { TableCell, TableRow, Typography } from "@mui/material";
import { TableHeadStyled } from "./styles";

interface Props {
  tableHead: string[];
  height?: number;
}

const TableHeadComponent = ({ tableHead, height = 6.25 }: Props) => {
  return (
    <TableHeadStyled>
      <TableRow sx={{ height: theme.spacing(height) }}>
        {tableHead.map((title) => (
          <TableCell key={title}>
            <Typography variant="body2" color={theme.palette.common.grey800}>
              {title}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHeadStyled>
  );
};

export default TableHeadComponent;
