import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";
import { Link } from "react-router-dom";

export const StructureWrapper = styled(Box)`
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const StructureWrapperStrict = styled(Box)`
  background-color: ${theme.palette.common.white};
  border-bottom-left-radius: ${theme.spacing(1)};
  border-bottom-right-radius: ${theme.spacing(1)};
  border-top: 1px solid ${theme.palette.common.grey300};
`;

export const NavbarItemWrapper = styled(Box)<{ active: number }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0, 0.5, 2, 0.5)};
  border-bottom: ${(props) =>
    props.active ? `2px solid ${theme.palette.common.blue700}` : "none"};
`;

export const NavbarBadge = styled(Box)<{ active: number }>`
  background-color: ${(props) =>
    props.active ? theme.palette.common.blue600 : theme.palette.common.grey500};
  border-radius: ${theme.spacing(2)};
  padding: ${theme.spacing(0, 1)};
`;

export const NavbarBadgeText = styled(Typography)<{ active: number }>`
  font-size: ${theme.spacing(1.5)};
  font-weight: 500;
  font-family: "Rubik";
  line-height: ${theme.spacing(2.75)};
  color: ${theme.palette.common.white};
`;

export const LinkStyled = styled(Link)`
  color: ${theme.palette.common.blue700};
  font-weight: 500;
  font-weight: ${theme.typography.fontWeightMedium};
  font-size: ${theme.spacing(1.75)};
  text-decoration: none;
  width: ${theme.spacing(8)};
  height: ${theme.spacing(4)};
`;

export const ArchiveBtnStyled = styled(Typography)`
  color: ${theme.palette.common.grey700};
  padding: ${theme.spacing(0.75, 0)};
  cursor: pointer;
`;

export const ViewBtnStyled = styled(ArchiveBtnStyled)`
  color: ${theme.palette.common.blue700};
`;

export const ListWrapper = styled(Box)`
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const ListHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(2, 2.5)};
`;

export const StyledWarning = styled(Box)`
  display: inline-block;
  text-align: center;
  height: ${theme.spacing(2.75)};
  padding: ${theme.spacing(0.25, 1)};
  border-radius: ${theme.spacing(2)};
  font-size: ${theme.spacing(1.5)};
  line-height: ${theme.spacing(2.25)};
  font-weight: 400;
  background-color: ${theme.palette.common.red50};
  color: ${theme.palette.common.red600};
`;
