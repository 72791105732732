import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "application/store";
import theme from "application/theme";
import {
  closeRenewSubscription,
  openRenewSubscription,
  organizationsSelector,
} from "common/organization/store";
import React, { useState } from "react";
import { ActivationBadge, TableContainerStyled } from "./styles";
import Button from "application/components/Button";
import { ArchiveBtnStyled } from "common/requests/styles";
import { Box } from "@mui/system";
import { Check, CloseOutlined } from "@mui/icons-material";
import { SurveyStatus } from "common/requests/consts";
import {
  acceptNewCreditsRequest,
  declineNewCreditsRequest,
  handleAcceptSeatsRequest,
  handleDeclineRenewRequestHistory,
  handleDeclineSeatsRequest,
} from "common/organization/store/actions";
import { HistoryRequestI } from "common/organization/store/types";
import { RenewSubscriptionModal } from "common/organization/modal/RequestHistoryRenewSubscription";
import { formatDate } from "application/helpers";
import EmptyTableRequests from "common/requests/emptyTableData";
import {CreditsQuantityItem} from "../../../../application";

type CreditsActionType = {
  requestId: number;
};

const tableHead = ["Name", "Date", "Quantity", "Done by", "Actions"];

const RequestHistory = () => {
  const { historyRequests, renewSubscriptionModal, organizationProfile } =
    useAppSelector(organizationsSelector);

  const [selectedRow, setSelectedRow] = useState<HistoryRequestI>(
    {} as HistoryRequestI
  );
  const dispatch = useAppDispatch();

  const handleOpenRenewSubscription = (row: HistoryRequestI) => {
    setSelectedRow(row);
    dispatch(openRenewSubscription());
  };

  const handleCloseRenewSubscription = () => {
    setSelectedRow({} as HistoryRequestI);
    dispatch(closeRenewSubscription());
  };

  const handleAccept = ({ requestId }: CreditsActionType) => {
    dispatch(
      acceptNewCreditsRequest({
        requestId,
        organizationId: organizationProfile.id,
      })
    );
  };

  const handleDecline = ({ requestId }: CreditsActionType) => {
    dispatch(
      declineNewCreditsRequest({
        requestId,
        organizationId: organizationProfile.id,
      })
    );
  };
  return (
    <>
      <TableContainerStyled>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ background: theme.palette.common.grey200 }}>
            <TableRow>
              {tableHead.map((e) => (
                <TableCell key={e}>
                  <Typography
                    variant="body2"
                    color={theme.palette.common.grey800}
                  >
                    {e}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {historyRequests.length
              ? historyRequests.map((row) => (
                  <TableRow
                    key={`${row.requestId}+${row.name}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {row.date ? formatDate(row.date) : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell component="th" scope="row" sx={{ p: `${theme.spacing(1,2)}`}}>
                      <Box display="flex" flexDirection="column">
                        {row.requestType === "CREDITS" ? (
                            <>
                              <CreditsQuantityItem label={"Company"} value={row.companyDataCreditsQuantity || 0}/>
                              <CreditsQuantityItem label={"Email"} value={row.emailCreditsQuantity || 0}/>
                              <CreditsQuantityItem label={"Phone"} value={row.phoneCreditsQuantity || 0}/>
                            </>
                        ) : (
                            <Box height={theme.spacing(5)} />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {row.doneBy}
                      </Typography>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.status === SurveyStatus.NEW ? (
                        <>
                          {row.requestType === "CREDITS" && (
                            <Box display="flex" gap={2} alignItems="center">
                              <Box
                                onClick={() =>
                                  handleDecline({
                                    requestId: row.requestId,
                                  })
                                }
                              >
                                <ArchiveBtnStyled variant="body2">
                                  Decline
                                </ArchiveBtnStyled>
                              </Box>
                              <Button
                                label="Accept"
                                height={4}
                                fontSize={theme.spacing(1.75)}
                                onClick={() =>
                                  handleAccept({
                                    requestId: row.requestId,
                                  })
                                }
                              />
                            </Box>
                          )}
                          {row.requestType === "SUBSCRIPTION_RENEWAL" && (
                            <Box display="flex" gap={2} alignItems="center">
                              <Box
                                onClick={() =>
                                  dispatch(
                                    handleDeclineRenewRequestHistory({
                                      requestId: row.requestId,
                                      organizationId: organizationProfile.id,
                                    })
                                  )
                                }
                              >
                                <ArchiveBtnStyled variant="body2">
                                  Decline
                                </ArchiveBtnStyled>
                              </Box>
                              <Button
                                label="Renew subscription"
                                height={4}
                                fontSize={theme.spacing(1.75)}
                                onClick={() => handleOpenRenewSubscription(row)}
                              />
                            </Box>
                          )}
                          {row.requestType === "SEATS" && (
                            <Box display="flex" gap={2} alignItems="center">
                              <Box
                                onClick={() =>
                                  dispatch(
                                    handleDeclineSeatsRequest({
                                      requestId: row.requestId,
                                      organizationId: organizationProfile.id,
                                    })
                                  )
                                }
                              >
                                <ArchiveBtnStyled variant="body2">
                                  Decline
                                </ArchiveBtnStyled>
                              </Box>
                              <Button
                                label="Add seats"
                                height={4}
                                fontSize={theme.spacing(1.75)}
                                onClick={() =>
                                  dispatch(
                                    handleAcceptSeatsRequest({
                                      requestId: row.requestId,
                                      organizationId: organizationProfile.id,
                                    })
                                  )
                                }
                              />
                            </Box>
                          )}
                        </>
                      ) : (
                        <ActivationBadge>
                          {row.status === SurveyStatus.ACCEPTED ? (
                            <Check
                              sx={{
                                width: theme.spacing(1.5),
                                height: theme.spacing(1.5),
                                color: theme.palette.common.grey900,
                              }}
                            />
                          ) : null}
                          {row.status === SurveyStatus.DECLINED ? (
                            <CloseOutlined
                              sx={{
                                width: theme.spacing(1.5),
                                height: theme.spacing(1.5),
                                color: theme.palette.common.grey900,
                              }}
                            />
                          ) : null}
                          <Typography
                            variant="caption"
                            color={theme.palette.common.grey900}
                            ml={0.5}
                            textTransform="capitalize"
                          >
                            {row.status.toLowerCase()}
                          </Typography>
                        </ActivationBadge>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
          {selectedRow && (
            <RenewSubscriptionModal
              isOpen={renewSubscriptionModal}
              handleClose={handleCloseRenewSubscription}
              data={{
                lastDate: selectedRow.subscriptionLastDate,
                requestId: selectedRow.requestId,
              }}
              organizationId={organizationProfile.id}
              organizationName={organizationProfile.companyName}
              type="HISTORY"
            />
          )}
        </Table>
        {!historyRequests.length ? <EmptyTableRequests /> : null}
      </TableContainerStyled>
    </>
  );
};

export default RequestHistory;
