import {
  Box,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const PaginationWrapper = styled(Box)`
  min-height: ${theme.spacing(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  padding: ${theme.spacing(1.25)};
  display: flex;
  align-items: center;
  &.MuiButtonBase-root:hover {
    background-color: transparent;
  }
`;
