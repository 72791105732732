import React, {useEffect, useState} from "react";

import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box, Typography} from "@mui/material";

import {Button, ModalComponent, QuantityPicker} from "application";
import theme from "application/theme";
import {QuestionIcon} from "application/assets/QuestionIcon";
import {CompanyNameStyled, IconBackground} from "./styles";
import {ContactPersonStyled} from "common/organization/styles";
import {ICreditRequests} from "../store/types";

import {
    handleAcceptOrgCredits,
} from "../store/actions";

import {SurveyStatus} from "../consts";
import {useAppDispatch} from "application/store";

const schema = yup.object().shape({
    phoneCredits: yup.number(),
    emailCredits: yup.number(),
});

interface Props {
    isOpen: boolean;
    setIsOpen: (b: boolean) => void;
    data: ICreditRequests;
}

export const AcceptCreditsModal = ({isOpen, setIsOpen, data}: Props) => {
    const dispatch = useAppDispatch();

    const [emailCredits, setEmailCredits] = useState<number>(
        data.emailCredits || 0
    );
    const [phoneCredits, setPhoneCredits] = useState<number>(
        data.phoneCredits || 0
    );
    const [companyDataCredits, setCompanyDataCredits] = useState<number>(data.companyDataCredits || 0);

    const [error, setError] = useState<boolean>(false);

    const handleClose = () => {
        setPhoneCredits(0);
        setEmailCredits(0);
        setError(false);
        setIsOpen(false);
    };

    const onSubmit = () => {
        if (!(emailCredits || phoneCredits || companyDataCredits)) {
            setError(true);
            return;
        }
        setError(false);
        setIsOpen(false);
        dispatch(
            handleAcceptOrgCredits(
                data.id,
                SurveyStatus.ACCEPTED,
                emailCredits,
                phoneCredits,
                companyDataCredits
            )
        );
    };

    useEffect(() => {
        if (emailCredits || phoneCredits || companyDataCredits) setError(false);
    }, [emailCredits, phoneCredits, companyDataCredits]);

    const {register} = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            phoneCredits: data.phoneCredits,
            emailCredits: data.emailCredits,
            companyDataCredits: data.companyDataCredits,
        },
    });

    return (
        <ModalComponent
            isOpen={isOpen}
            handleClose={handleClose}
            padding={theme.spacing(3)}
            width={545}
        >
            <Box
                textAlign="center"
                display={"flex"}
                flexDirection={"column"}
                gap={theme.spacing(3)}
            >
                <Box display="flex" width={"100%"} justifyContent="center">
                    <IconBackground>
                        <QuestionIcon color={theme.palette.common.blue800}/>
                    </IconBackground>
                </Box>
                <Box display="flex" flexDirection="column" gap={2.5}>
                    <Typography variant="subtitle1" color={theme.palette.common.grey800}>
                        Are you sure to activate request of new credit exports for&nbsp;
                        <CompanyNameStyled>{data.name}?</CompanyNameStyled>
                    </Typography>
                    <Box display="flex" justifyContent="center" gap={theme.spacing(4)}>
                        <QuantityPicker
                            label={"Company credits"}
                            register={register("companyDataCredits")}
                            getValue={setEmailCredits}
                            defaultValue={data.companyDataCredits || 0}
                            error={error}
                            max={999999}
                        />
                        {
                            data.organizationStructureAccessPermitted &&
                            <>
                                <QuantityPicker
                                    label={"Email credits"}
                                    register={register("emailCredits")}
                                    getValue={setEmailCredits}
                                    defaultValue={data.emailCredits || 0}
                                    error={error}
                                />
                                <QuantityPicker
                                    label={"Phone credits"}
                                    register={register("phoneCredits")}
                                    getValue={setPhoneCredits}
                                    defaultValue={data.phoneCredits || 0}
                                    error={error}
                                />
                            </>
                        }

                    </Box>
                    {error ? (
                        <Typography variant="caption" color={theme.palette.common.red600}>
                            Select at least 1 credit export
                        </Typography>
                    ) : (
                        <></>
                    )}
                    <Typography variant="caption" color={theme.palette.common.grey700}>
                        Then contact person:&nbsp;
                        <ContactPersonStyled>{data.name}</ContactPersonStyled>
                        &nbsp;will get email notification about it and access to the system.
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
                    <Button
                        label="Cancel"
                        height={5.5}
                        width={15}
                        type="button"
                        onClick={handleClose}
                    />
                    <Button
                        label="Activate"
                        height={5.5}
                        width={15}
                        type="submit"
                        disabled={error}
                        onClick={onSubmit}
                    />
                </Box>
            </Box>
        </ModalComponent>
    );
};
