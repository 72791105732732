import { TypographyOptions } from "@mui/material/styles/createTypography";

import { pxToRem } from "./utils/formatFontSize";

const FONT_PRIMARY = "Rubik";
const Typography: TypographyOptions = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,

  h1: {
    color: "inherit",
    fontWeight: "bold",
    fontSize: pxToRem(40),
    lineHeight: pxToRem(56),
    textTransform: "none",
  },
  h2: {
    color: "inherit",
    fontWeight: "bold",
    fontSize: pxToRem(36),
    lineHeight: pxToRem(48),
    textTransform: "none",
  },
  h3: {
    color: "inherit",
    fontWeight: "bold",
    fontSize: pxToRem(30),
    lineHeight: pxToRem(40),
    textTransform: "none",
    fontStyle: "normal",
    letterSpacing: "0.5%",
  },
  h4: {
    color: "inherit",
    fontWeight: 700,
    fontSize: pxToRem(32),
    lineHeight: pxToRem(44),
    textTransform: "none",
  },
  h5: {
    color: "inherit",
    fontWeight: 500,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(34),
    textTransform: "none",
  },
  h6: {
    color: "inherit",
    fontWeight: "bold",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    textTransform: "none",
  },
  subtitle1: {
    color: "inherit",
    fontWeight: 500,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(30),
    textTransform: "none",
  },
  subtitle2: {
    color: "inherit",
    fontWeight: 500,
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    textTransform: "none",
  },
  body1: {
    color: "inherit",
    fontWeight: "normal",
    fontSize: pxToRem(18),
    lineHeight: pxToRem(29),
    textTransform: "none",
  },
  body2: {
    color: "inherit",
    fontWeight: 500,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    textTransform: "none",
  },
  button: {
    color: "inherit",
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    textTransform: "none",
  },
  overline: {
    color: "inherit",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22),
    fontWeight: 400,
    textTransform: "none",
  },
  caption: {
    color: "inherit",
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    textTransform: "none",
  },
};

export default Typography;
