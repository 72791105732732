import React, {useEffect} from "react";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {OrganizationProfile} from "../store/types";
import {handleAddOrganization} from "../store/actions";
import {useAppDispatch, useAppSelector} from "application/store";
import {QuantityPicker, ModalComponent, Button} from "application";

import theme from "application/theme";
import {Box} from "@mui/material";

import {
    FormWrapper,
    FormContainer,
    FormItemContainer,
    ButtonContainer,
} from "../styles";

import {
    addedOrgResponse,
    isOpenModal,
    openResetModal,
    organizationsSelector,
    setAddOrganizationEmailError,
} from "../store";

import {schema} from "../form/formSchema";
import {AddOrganizationForm} from "../AddOrganizationForm";
import OrganizationStructureAccessToggler from "./OrganizationStructureAccessToggler";

interface Props {
    title?: string;
}

export const AddOrganizationModal = ({
                                         title = "Add new organization request",
                                     }: Props) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(isOpenModal);
    const addedOrganization = useAppSelector(addedOrgResponse);
    const {addOrganizationEmailError} = useAppSelector(organizationsSelector);
    const formDefaultValues = {
        organizationStructureAccessPermitted: true,
        seats: 1,
    }
    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, dirtyFields},
        reset,
        watch,
        control
    } = useForm<OrganizationProfile>({
        resolver: yupResolver(schema),
        defaultValues: formDefaultValues
    });

    const {
        emailContactPerson,
        organizationStructureAccessPermitted
    } = watch();

    const onSubmit = (data: OrganizationProfile) => {
        dispatch(handleAddOrganization(data));
    };

    useEffect(() => {
        !addedOrganization.id && reset();
    }, [addedOrganization, reset]);

    const onClose = () => {
        dispatch(openResetModal());
        dispatch(setAddOrganizationEmailError(false))
    };

    useEffect(() => {
        if (addOrganizationEmailError) {
            setError("emailContactPerson", {
                type: "onSubmit",
                message: "This email already assigned to org manager"
            });
        }
    }, [addOrganizationEmailError, setError]);

    useEffect(() => {
        dispatch(setAddOrganizationEmailError(false))
    }, [emailContactPerson, dispatch])

    const descriptionText =
        "Then this organization will appear in requests tab up to activation.";

    return (
        <ModalComponent
            isOpen={isOpen}
            padding={3}
            width={"65%"}
            handleClose={onClose}
            title={title}
            description={descriptionText}
        >
            <FormWrapper>
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <FormItemContainer>
                        <AddOrganizationForm
                            register={register}
                            errors={errors}
                            dirtyFields={dirtyFields}
                            watch={watch}
                            control={control}
                        />
                        <OrganizationStructureAccessToggler control={control}/>
                        <Box display="flex" gap={theme.spacing(3)}>
                            <QuantityPicker
                                label={"Company credits"}
                                register={register("companyDataCredits")}
                                max={999999}
                            />
                            { organizationStructureAccessPermitted &&
                                <>
                                    <QuantityPicker
                                        label={"Email credits"}
                                        register={register("emailCredits")}
                                    />
                                    <QuantityPicker
                                        label={"Phone credits"}
                                        register={register("phoneCredits")}
                                    />
                                </>
                            }
                        </Box>
                    </FormItemContainer>
                    <ButtonContainer>
                        <Button label="Cancel" width={15} type="button" onClick={onClose}/>
                        <Button
                            label="Save"
                            width={15}
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        />
                    </ButtonContainer>
                </FormContainer>
            </FormWrapper>
        </ModalComponent>
    );
};
