import { Add } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Button from "application/components/Button";
import theme from "application/theme";
import React from "react";
import { AddCreditsModal } from "./AddCreditsModal";
import { useAppDispatch, useAppSelector } from "application/store";
import { closeAddCreditsModal, openAddCreditsModal, organizationsSelector } from "../store";

const RequestHistoryHeader = () => {
  const { isAddCreditsModalOpen } = useAppSelector(organizationsSelector);
  const dispatch = useAppDispatch();
  const openModal = () => {
    dispatch(openAddCreditsModal());
  };
  const closeModal = () => {
    dispatch(closeAddCreditsModal());
  };
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems="center"
        mb={1.5}
      >
        <Typography variant="h5" color={theme.palette.common.grey900}>
          Requests History
        </Typography>
        <Button
          label="Add new credits"
          startAdornment={<Add sx={{ color: theme.palette.common.grey800 }} />}
          height={5.5}
          onClick={openModal}
          type="button"
        />
      </Box>
      {isAddCreditsModalOpen && (
        <AddCreditsModal
          isOpen={isAddCreditsModalOpen}
          handleClose={closeModal}
        />
      )}
    </>
  );
};

export default RequestHistoryHeader;
