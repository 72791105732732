import { IconButton } from "@mui/material";
import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const DatePickerInputStyled = styled("button")`
  cursor: pointer;
  height: ${theme.spacing(5)};
  border: 1px solid ${theme.palette.common.grey400};
  padding: ${theme.spacing(1.25, 1.75)};
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.white};
  font-size: ${theme.spacing(2)};
  font-family: Rubik;
  line-height: ${theme.spacing(2.75)};
  color: ${theme.palette.common.grey900};
  text-align: start;
  width: ${theme.spacing(34)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  padding: ${theme.spacing(1)};
  display: flex;
  align-items: center;
  border-radius: ${theme.spacing(1)};
  &.MuiButtonBase-root:hover {
    border-radius: ${theme.spacing(1)};
  }
`;

export const DatePickerWrapper = styled(Box)`
  .react-datepicker {
    border: none;
    border-radius: ${theme.spacing(1)};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    padding-bottom: ${theme.spacing(1.5)};
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-popper {
    padding-top: ${theme.spacing(0)};
  }
  .react-datepicker__header {
    border-bottom: none;
    background-color: ${theme.palette.common.white};
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: ${theme.spacing(5)};
    line-height: ${theme.spacing(5)};
    margin: ${theme.spacing(0)};
    font-weight: 400;
    font-family: Rubik;
    font-size: ${theme.spacing(1.75)};
    color: ${theme.palette.common.grey900};
  }

  .react-datepicker__day:hover {
    background-color: ${theme.palette.common.blue50};
    border-radius: ${theme.spacing(1)};
  }
  .react-datepicker__month {
    margin: ${theme.spacing(0, 1.5)};
  }
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range:hover {
    border-radius: ${theme.spacing(0)};
    background-color: ${theme.palette.common.blue50} !important;
    color: ${theme.palette.common.grey900};
  }
  .react-datepicker__day--range-end,
  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    background-color: ${theme.palette.common.blue700} !important;
    color: ${theme.palette.common.white} !important;
    border-radius: ${theme.spacing(1)};
  }
  .react-datepicker__day--range-start:hover,
  .react-datepicker__day--range-end:hover {
    background-color: ${theme.palette.common.blue700} !important;
    color: ${theme.palette.common.white};
    border-radius: ${theme.spacing(1)};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: ${theme.palette.common.white};
  }
  .react-datepicker__day--outside-month {
    color: ${theme.palette.common.grey700};
  }
  .react-datepicker__day--selected {
    background-color: ${theme.palette.common.blue700} !important;
    color: ${theme.palette.common.white};
    border-radius: ${theme.spacing(1)};
  }
`;
