import { Box } from "@mui/system";
import SaasAdminUsersManagement from "common/saasAdminUsersManagement/SaasAdminUsersManagement";
import React from "react";

const SaasAdminManagementPage = () => {
  return (
    <Box pb={4}>
      <SaasAdminUsersManagement />
    </Box>
  );
};

export default SaasAdminManagementPage;