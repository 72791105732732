import { ThemeProvider } from "@mui/material/styles";
import { NotInternetConnectionPage } from "application";
import theme from "application/theme";
import { LoaderModal } from "common";
import React, { useEffect, useState } from "react";

const withNetworkDetector =
  <P extends object>(ComposedComponent: React.ComponentType<P>) =>
  (props: P) => {
    const [isDisconnected, setIsDisconnected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? "online" : "offline";
      setIsDisconnected(condition === "offline");

      if (condition === "offline") {
        setIsLoading(true);
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 30000);

        return () => clearTimeout(loadingTimeout);
      }
    };

    useEffect(() => {
      handleConnectionChange();
      window.addEventListener("online", handleConnectionChange);
      window.addEventListener("offline", handleConnectionChange);

      return () => {
        window.removeEventListener("online", handleConnectionChange);
        window.removeEventListener("offline", handleConnectionChange);
      };
    }, []);

    if (isDisconnected && !isLoading) {
      return (
        <ThemeProvider theme={theme}>
          <NotInternetConnectionPage />
        </ThemeProvider>
      );
    } else if (isDisconnected && isLoading) {
      return <LoaderModal isOpen={true} />;
    } else {
      return <ComposedComponent {...props} />;
    }
  };

export default withNetworkDetector;
