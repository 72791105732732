import React, { useState } from "react";
import { Box, CssBaseline } from "@mui/material";

import { SideBar } from "./sidebar/SideBar";

import { ContentContainer, MainContentWrapper } from "./styles";

interface MainLayoutProps {
  children: JSX.Element;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SideBar isOpen={open} setIsOpen={setOpen} />
      <MainContentWrapper onClick={()=>setOpen(false)}>
        <ContentContainer maxWidth="lg">{children}</ContentContainer>
      </MainContentWrapper>
    </Box>
  );
};
