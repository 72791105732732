import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  dictionarySlice,
  errorSlice,
  loaderSlice,
  loadingSlice,
  organizationsSlice,
  requestsSlice,
} from "common";
import { authSlice } from "common/auth/store/authStore";
import { archivedOrganizationSlice } from "common/organizationArchive/store";
import { responseSlice } from "common/responseService";
import { saasAdminUsersManagementSlice } from "common/saasAdminUsersManagement/store";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const reducer = combineReducers({
  auth: authSlice.reducer,
  error: errorSlice.reducer,
  loading: loadingSlice.reducer,
  dictionary: dictionarySlice.reducer,
  organizations: organizationsSlice.reducer,
  saasAdminManagement: saasAdminUsersManagementSlice.reducer,
  loader: loaderSlice.reducer,
  requests: requestsSlice.reducer,
  response: responseSlice.reducer,
  archived: archivedOrganizationSlice.reducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
