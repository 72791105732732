export const getTimeByTimeZone = (timezoneOffset: string) => {
  const timezoneParts = timezoneOffset.match(/([+-])(\d{1,2}):?(\d{2})?/);
  if (!timezoneParts) return null;

  const [, sign, hour, minute] = timezoneParts;

  const utcOffsetMinutes =
    sign === "-"
      ? -Number(hour) * 60 - Number(minute || 0)
      : Number(hour) * 60 + Number(minute || 0);

  const now = new Date();
  const utcTime = new Date(now.getTime() + utcOffsetMinutes * 60 * 1000);

  const utcOffsetString = `UTC${sign}${hour}:${minute || "00"}`;

  const formattedTime = utcTime.toLocaleString(undefined, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${utcOffsetString} (${formattedTime})`;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("en-us", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

export const formatDateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 101).toString().slice(-2);
  const day = (date.getDate() + 100).toString().slice(-2);
  return `${year}-${month}-${day}`;
};

export const calculateDaysUntilDate = (targetDate: string) => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  const today = new Date();

  const target = new Date(targetDate);

  const timeDifference = target.getTime() - today.getTime();
  const daysDifference = Math.ceil(timeDifference / oneDayInMilliseconds);

  return daysDifference;
};

export const downloadCSV = (csvData: BlobPart, filename: string) => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
