export enum Errors {
    passwordConfirmation = 'Passwords must match',
    passwordMax = 'Password should not  be longest then 15 chars',
    passwordMin = 'Password should be more then 6 chars',
    passwordRequired = "Password is required",
    emailRequired = "Email is required",
    emailFormat = 'Invalid email format',
}

export enum SearchPage {
    expired = "?expired",
    welcome = "?welcome",
}
