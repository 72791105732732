import React from "react";

import {
  ListItemButtonStyled,
  ListItemIconStyled,
  ListItemStyled,
  ListItemTextCustom,
  StyledBadge,
} from "./styles";

import { IListItemProps } from "./types";

import { Badge } from "@mui/material";
import theme from "application/theme";
import { useNavigate } from "react-router-dom";

export const SideBarItem = ({ item, open, active, mb }: IListItemProps) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(item.path);
  };
  return (
    <ListItemStyled
      key={item.label}
      disablePadding
      open={open}
      disabled={item.disabled || false}
      active={active ? 1 : 0}
      sx={{ mb: mb }}
      onClick={handleNavigate}
    >
      <ListItemButtonStyled
        sx={{ justifyContent: open ? "initial" : "center" }}
      >
        <ListItemIconStyled sx={{ mr: open ? theme.spacing(1.5) : "auto" }}>
          {item.notifications && !open ? (
            <Badge
              overlap="circular"
              badgeContent=" "
              color="info"
              variant="dot"
            >
              {item.icon}
            </Badge>
          ) : (
            <>{item.icon}</>
          )}
        </ListItemIconStyled>
        {open ? (
          <StyledBadge
            badgeContent={item.notifications}
            active={active ? 1 : 0}
          >
            <ListItemTextCustom
              primary={item.label}
              open={open}
              disabled={item.disabled}
            />
          </StyledBadge>
        ) : (
          <ListItemTextCustom
            primary={item.label}
            open={open}
            disabled={item.disabled}
          />
        )}
      </ListItemButtonStyled>
    </ListItemStyled>
  );
};
