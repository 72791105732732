import React, { useState } from "react";
import Box from "@mui/material/Box";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { QuantityProps } from "./types";
import {
  IconButtonStyled,
  LabelQuantity,
  LabelWrapper,
  QuantityPickerInput,
  QuantityPickerWrapper,
} from "./styles";
import theme from "application/theme";
import { NumericFormat } from 'react-number-format';
import { formatNumbersWithSeparator } from 'application/utils/numberUtils';

const QuantityPicker = ({
  label,
  register: { onChange, onBlur, ref, name: fieldName, ...rest },
  getValue,
  defaultValue = 0,
  error,
  maxWidth = theme.spacing(22),
  min = 0,
  max = 9999,
}: QuantityProps) => {
  const [quantity, setQuantity] = useState(Math.max(defaultValue, min));
  const handleIncrement = () => {
    const newQuantity = Math.min(quantity + 1, max);
    setQuantity(newQuantity);
    onChange({
      target: {
        name: fieldName,
        value: newQuantity,
      },
    });
    if(getValue){
      getValue(newQuantity);
    }
  };

  const handleDecrement = () => {
    if (quantity > min) {
      const updatedQuantity = quantity - 1;
      setQuantity(updatedQuantity);
      onChange({ target: { name: fieldName, value: updatedQuantity } });
      if (getValue) {
        getValue(updatedQuantity);
      }
    }
  };
  const handleChangeQuantity = (quantity: number) => {
    let newQuantity = Math.min(quantity, max);
    newQuantity = Math.max(newQuantity, min);
    setQuantity(newQuantity);
    if (getValue) {
      getValue(newQuantity);
    }
    onChange({ target: { name: fieldName, value: newQuantity } });
  }

  const isValueAllowed = (values: any) => {
    if(values.value === '00') return false;
    return !(max && values.floatValue > max);
  }

  return (
    <Box maxWidth={maxWidth}>
      <LabelWrapper variant="caption" error={!!error}>
        {label}: <LabelQuantity>{formatNumbersWithSeparator(quantity) || 0}</LabelQuantity>
      </LabelWrapper>
      <QuantityPickerWrapper error={!!error}>
        <IconButtonStyled onClick={handleDecrement} size="small">
          <ArrowLeft />
        </IconButtonStyled>
        <NumericFormat value={quantity}
          // @ts-ignore
                       customInput={QuantityPickerInput}
                       type="text"
                       thousandSeparator=','
                       onValueChange={(values) => handleChangeQuantity(values.floatValue || 0)}
                       isAllowed={isValueAllowed}
        />
        <IconButtonStyled onClick={handleIncrement} size="small">
          <ArrowRight />
        </IconButtonStyled>
      </QuantityPickerWrapper>
    </Box>
  );
};

export default QuantityPicker;
