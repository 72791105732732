import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { LoginDataT } from "common";
import {
  UserPool,
  getSessionFromUser,
  login,
  logout,
} from "integration/cognito";
import { PathNames } from "application/routes";
import { setIsAuth } from "../store/authStore";
import { useAppDispatch } from "application/store";
import { handleGetSaasAdminProfileAfterLogin } from "common/saasAdminUsersManagement/store/actions";

export const useSignIn = () => {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const singIn = useCallback(
    async ({ email, password }: LoginDataT) => {
      setError(false);
      setPending(true);
      try {
        await login({ email, password });
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
          const data: any = await getSessionFromUser(cognitoUser);
          const saasAdmin = Object.entries(data.idToken.payload).find(
            (e) => e[1] === "SAAS_ADMIN"
          );
          if (saasAdmin) {
            navigate(`${PathNames.organizations}`);
            dispatch(setIsAuth(true));
            dispatch(handleGetSaasAdminProfileAfterLogin());
          } else {
            logout();
            setError(true);
          }
        }
      } catch (error: any) {
        setError(true);
      } finally {
        setPending(false);
      }
    },
    [navigate]
  );

  return { singIn, pending, error };
};
