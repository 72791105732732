import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import {
  ConfirmPasswordChange,
  CreateNewPassword,
  ForgotPassword,
  Login,
  Registration,
} from "common";
import { PathNames } from "../routes/constants";

const AuthPage: FC = () => {
  const { pathname } = useLocation();

  const showPage = () => {
    switch (pathname) {
      case PathNames.login:
        return <Login />;
      case PathNames.registration:
        return <Registration />;
      case PathNames.confirmChange:
        return <ConfirmPasswordChange />;
      case PathNames.createPassword:
        return <CreateNewPassword />;
      case PathNames.forgotPassword:
        return <ForgotPassword />;
      default:
        <Login />;
    }
  };

  return <div className="container">{showPage()}</div>;
};

export default AuthPage;
