import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "application/store";
import * as yup from "yup";
import { ModalComponent, QuantityPicker } from "application";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { AddCredits } from "application/assets/AddCredits";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactPersonStyled } from "../styles";
import { organizationsSelector } from "../store";
import Button from "application/components/Button";
import { handleCreateCreditRequest } from "../store/actions";
import { dateFormatter } from "../OrganizationsList/utils";

const schema = yup.object().shape({
  phoneCredits: yup.number(),
  emailCredits: yup.number(),
});

export const AddCreditsModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const { contactPerson, companyName, id, organizationStructureAccessPermitted } = organizationProfile;
  const [emailCredits, setEmailCredits] = useState<number>(0);
  const [phoneCredits, setPhoneCredits] = useState<number>(0);
  const [companyDataCredits, setCompanyDataCredits] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const onSubmit = async () => {
    if (!(emailCredits || phoneCredits || companyDataCredits)) {
      setError(true);
      return;
    }
    setError(false);
    const date = dateFormatter(new Date()) ?? "";
    dispatch(
        handleCreateCreditRequest({
          date: date,
          organizationId: id,
          emailCredits,
          phoneCredits,
          companyDataCredits,
        })
    );
  };
  const dispatch = useAppDispatch();
  const { register } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (emailCredits || phoneCredits) setError(false);
  }, [emailCredits, phoneCredits]);

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={4}
      width={545}
    >
      <Box textAlign="center" display="flex" flexDirection="column" gap={2.5}>
        <Box display="flex" justifyContent="center">
          <AddCredits />
        </Box>
        <Box>
          <Typography variant="h5" color={theme.palette.common.grey800}>
            Add new credit exports
          </Typography>
          <Typography variant="h5" color={theme.palette.common.turquoise600}>
            for {companyName}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" gap={theme.spacing(4)}>
          <QuantityPicker
              label={"Company credits"}
              register={register("companyDataCredits")}
              getValue={setCompanyDataCredits}
              error={error}
              max={999999}
          />
          {
              organizationStructureAccessPermitted &&
              <>
                <QuantityPicker
                    label={"Email credits"}
                    register={register("emailCredits")}
                    getValue={setEmailCredits}
                    error={error}
                />
                <QuantityPicker
                    label={"Phone credits"}
                    register={register("phoneCredits")}
                    getValue={setPhoneCredits}
                    error={error}
                />
              </>
          }
        </Box>
        {error ? (
          <Typography variant="caption" color={theme.palette.common.red600}>
            Select at least 1 credit export
          </Typography>
        ) : (
          <></>
        )}
        <Typography variant="caption" color={theme.palette.common.grey700}>
          Then contact person:&nbsp;
          <ContactPersonStyled>{contactPerson}</ContactPersonStyled>
          &nbsp;will get email notification about it.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={0.5}
        >
          <Button
            label="Cancel"
            height={5.5}
            width={15}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Add"
            disabled={error}
            height={5.5}
            width={15}
            type="submit"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
