import React from "react";

import { useNavigate, useParams } from "react-router-dom";

import { TabItem } from "./TabItem";

import { PathNames } from "application/routes";
import { RoutesParam } from "application/routes/constants";

import { Box } from "@mui/system";
import { useAppSelector } from "application/store";
import { requestsSelector } from "../store";

const NavBar = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const waitingForActivation = name === RoutesParam.waitingForActivation;
  const survey = name === RoutesParam.survey;
  const newExports = name === RoutesParam.newExports;
  const subscriptionRenewal = name === RoutesParam.subscriptionRenewal;

  const { requestsData, surveys, creditRequests, renewRequests } =
    useAppSelector(requestsSelector);

  return (
    <Box display="flex" justifyContent="space-between" mb={2.5}>
      <Box display="flex" gap={2}>
        <TabItem
          label="Waiting for activations"
          count={requestsData.length}
          active={waitingForActivation}
          onClick={() =>
            navigate(
              `${PathNames.requests}/${RoutesParam.waitingForActivation}`
            )
          }
        />
        <TabItem
          label="Requests from survey"
          count={surveys.length}
          active={survey}
          onClick={() =>
            navigate(`${PathNames.requests}/${RoutesParam.survey}`)
          }
        />
        <TabItem
          label="Requests for new credits"
          count={creditRequests.length}
          active={newExports}
          onClick={() =>
            navigate(`${PathNames.requests}/${RoutesParam.newExports}`)
          }
        />
        <TabItem
          label="Requests for subscription renewal"
          count={renewRequests.length}
          active={subscriptionRenewal}
          onClick={() =>
            navigate(`${PathNames.requests}/${RoutesParam.subscriptionRenewal}`)
          }
        />
      </Box>
    </Box>
  );
};

export default NavBar;
