import React from "react";

export const BackArrow = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_156_11618"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_156_11618)">
        <path
          d="M11.6667 15L6.66675 10L11.6667 5L12.8334 6.16667L9.00008 10L12.8334 13.8333L11.6667 15Z"
          fill="#4D4D4D"
        />
      </g>
    </svg>
  );
};
