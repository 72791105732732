import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import DatePickerHeader from "./DatePickerHeader";

import theme from "application/theme";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

import { DatePickerInputStyled, DatePickerWrapper } from "./styles";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  label: string;
  getValue: (param: Date | [Date | null, Date | null] | null) => void;
  startValue: Date | [Date | null, Date | null] | null;
  selectsRange?: boolean;
  width?: string;
}

const DatePickerWithText = ({
  label,
  startValue,
  getValue,
  selectsRange = true,
  width = theme.spacing(34),
}: DatePickerProps) => {
  const [date, setDate] = useState<Date | [Date | null, Date | null] | null>(
    startValue
  );
  const DatePickerCustomInput = forwardRef(
    ({ value, onClick }: any, ref: any) => (
      <DatePickerInputStyled
        onClick={onClick}
        ref={ref}
        sx={{ width: width ? width : "" }}
      >
        {value ? value : "All"}
        <KeyboardArrowDown sx={{ color: theme.palette.common.grey700 }} />
      </DatePickerInputStyled>
    )
  );

  const handleDatesChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;
    setDate(dates);
    if (startDate && endDate) getValue(dates);
  };

  const handleDateChange = (dates: Date | null) => {
    setDate(dates ? dates : null);
    getValue(dates);
  };

  const handleClear = () => {
    setDate(selectsRange ? [null, null] : null);
    getValue(selectsRange ? [null, null] : null);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={0.75}>
        <Box>
          <Typography
            variant="caption"
            textAlign="left"
            color={theme.palette.common.grey800}
            sx={{ display: "block" }}
          >
            {label}
          </Typography>
        </Box>
        {date && (
          <Box
            onClick={handleClear}
            sx={{ cursor: "pointer" }}
            display="flex"
            alignItems="start"
          >
            <Typography variant="caption" color={theme.palette.common.blue800}>
              Clear
            </Typography>
          </Box>
        )}
      </Box>
      <DatePickerWrapper>
        {selectsRange && Array.isArray(date) ? (
          <DatePicker
            selectsRange={true}
            onChange={handleDatesChange}
            startDate={date[0] as Date}
            endDate={date[1] as Date}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }: any) => (
              <DatePickerHeader
                date={date}
                changeYear={changeYear}
                changeMonth={changeMonth}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            customInput={<DatePickerCustomInput />}
            dateFormat="MMM dd, yyyy"
          />
        ) : (
          <DatePicker
            selectsRange={false}
            onChange={handleDateChange}
            selected={date as Date}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }: any) => (
              <DatePickerHeader
                date={date}
                changeYear={changeYear}
                changeMonth={changeMonth}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
              />
            )}
            customInput={<DatePickerCustomInput />}
            dateFormat="MMM dd, yyyy"
          />
        )}
      </DatePickerWrapper>
    </Box>
  );
};

export default DatePickerWithText;
