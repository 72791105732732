import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { ListWrapper } from "./styles";
import { Typography } from "@mui/material";
import theme from "application/theme";
import Button from "application/components/Button";
import { Add } from "@mui/icons-material";
import SaasAdminTable from "./SaasAdminUsersTable";
import { AddNewSaasAdminModal } from "./AddNewSaasAdminModal";
import { useAppDispatch, useAppSelector } from "application/store";
import { handleGetAllSaasAdmins } from "./store/actions";
import { saasAdminSelector, setIsAddAdminModalOpen } from "./store";

const UsersManagement = () => {
  const dispatch = useAppDispatch();
  const { isAddAdminModalOpen } = useAppSelector(saasAdminSelector);
  const openAddAdminModal = () => {
    dispatch(setIsAddAdminModalOpen(true));
  };
  const closeAddAdminModal = () => {
    dispatch(setIsAddAdminModalOpen(false));
  };

  useEffect(() => {
    dispatch(handleGetAllSaasAdmins());
  }, []);
  return (
    <>
      <Box display="flex" alignItems="center" p={theme.spacing(4, 0, 2.5, 0)}>
        <Typography variant="h5" color="inherit" noWrap flexGrow={1}>
          Admin users management
        </Typography>
        <Button
          label="Add new manager"
          height={5.5}
          type="submit"
          startAdornment={<Add sx={{ color: theme.palette.common.white }} />}
          onClick={openAddAdminModal}
        />
      </Box>
      <ListWrapper>
        <SaasAdminTable />
      </ListWrapper>
      <AddNewSaasAdminModal
        isOpen={isAddAdminModalOpen}
        handleClose={closeAddAdminModal}
      />
    </>
  );
};

export default UsersManagement;
