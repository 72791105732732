import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";
import { IProps } from "./types";

export const InputStyled = styled(TextField)<Partial<IProps>>`
  height: ${theme.spacing(5.5)};
  color: ${theme.palette.common.grey800};
  font-weight: ${theme.typography.fontWeightMedium};

  .MuiInputLabel-root {
    color: ${theme.palette.common.grey800};
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: ${theme.spacing(1.75)};
  }

  .MuiOutlinedInput-root {
    font-size: ${theme.spacing(2)};
    height: ${theme.spacing(5.5)};
    font-weight: ${theme.typography.fontWeightRegular};
  }

  .MuiFormHelperText-root {
    margin-top: ${theme.spacing(1)};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-radius: ${theme.spacing(1)};
    border: 1px solid
      ${(props) =>
        props.error
          ? theme.palette.common.red600
          : !props.error && props.isvalid
          ? theme.palette.common.green800
          : theme.palette.common.grey400};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid
      ${(props) =>
        props.error
          ? theme.palette.common.red600
          : !props.error && props.isvalid
          ? theme.palette.common.green800
          : theme.palette.common.blue700};
  }

  label.Mui-focused {
    color: ${(props) =>
      props.error ? theme.palette.common.red600 : theme.palette.common.grey400};
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.palette.common.grey400};
  }

  .MuiInputLabel-root.Mui-error .MuiOutlinedInput-notchedOutline {
    color: ${theme.palette.common.red600};
    font-size: ${theme.spacing(1.75)};
  }

  .MuiFormHelperText-root {
    margin-left: ${theme.spacing(0)};
  }

  input:-internal-autofill-selected {
    background-color: ${(props) =>
      !!props.autofillcolor
        ? props.autofillcolor
        : theme.palette.common.white} !important;
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) =>
        !!props.autofillcolor
          ? props.autofillcolor
          : theme.palette.common.white}
      inset;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input:disabled {
    cursor: not-allowed;
  }
`;
