import { OrganizationProfile } from "common";
import { clientRequest } from "../requestHandler";
import {
  OrganizationsFilterT,
  OrganizationsDataI,
  CreditRequestI,
  IDataFilter,
  RenewRequestI,
} from "./types";
import { StatusT } from "common/requests/store/types";
import { OrgStatus } from "common/organization/consts";

export const getOrganizations = ({
  companyName,
  activationDateFrom,
  activationDateTo,
  registrationDateFrom,
  registrationDateTo,
  page,
  size,
}: OrganizationsFilterT): OrganizationsDataI => {
  return clientRequest({
    url: `/api/organizations/searches`,
    method: "POST",
    data: {
      companyName,
      activationDateFrom,
      activationDateTo,
      registrationDateFrom,
      registrationDateTo,
    },
    params: {
      page,
      size,
    },
  });
};

export const addOrganization = (
  organization: Omit<OrganizationProfile, "id">
) =>
  clientRequest({
    url: "/api/organizations",
    method: "POST",
    data: organization,
  });

export const editOrganization = (organization: OrganizationProfile) =>
  clientRequest({
    url: `/api/organizations/${organization.id}`,
    method: "PUT",
    data: organization,
  });

export const getAllOrganizations = (page= 0) =>
    getOrganizations({
        companyName: "",
        activationDateFrom: "",
        activationDateTo: "",
        registrationDateFrom: "",
        registrationDateTo: "",
        page,
        size: 10
    });

export const getOrganizationProfile = (id: string | number) =>
  clientRequest({
    url: `/api/organizations/${id}`,
    method: "GET",
  });

export const getOrganizationProfileCredit = (id: number) =>
  clientRequest({
    url: `/api/organizations/${id}/credit-requests`,
    method: "GET",
  });

export const createCreditRequest = (data: Partial<CreditRequestI>) =>
  clientRequest({
    url: `/api/organizations/credit-requests`,
    method: "POST",
    data: data,
  });

export const getOrganizationsSearchSuggestions = () =>
  clientRequest({
    url: `/api/organizations/suggestions`,
    method: "GET",
  });

export const activateOrganizations = (id: number) =>
  clientRequest({
    url: `/api/organizations/${id}`,
    method: "PATCH",
    params: {
      status: OrgStatus.ACTIVE,
    },
  });

export const activateArchivedOrganization = (id: number) =>
  clientRequest({
    url: `/api/organizations/archived/${id}`,
    method: "PUT",
  });

export const archiveOrganizations = (id: number, reason: string) =>
  clientRequest({
    url: `/api/organizations/${id}`,
    method: "PATCH",
    params: {
      status: OrgStatus.ARCHIVED,
      reason: reason,
    },
  });

export const getOrgWaitingForActivation = (filterData: IDataFilter) =>
  clientRequest({
    url: `/api/admin/organizations/waiting-for-activation/searches`,
    method: "POST",
    data: filterData,
  });

export const getWaitingForActivationSearchSuggestions = () =>
  clientRequest({
    url: `/api/admin/organizations/waiting-for-activation/suggestions`,
    method: "GET",
  });

export const getAllNewSurveys = () =>
  clientRequest({
    url: `/api/organizations/surveys`,
    method: "GET",
  });

export const getSurveyById = (id: number) =>
  clientRequest({
    url: `/api/organizations/surveys/${id}`,
    method: "GET",
  });

export const updateSurveyStatus = (id: number, status: StatusT) =>
  clientRequest({
    url: `/api/organizations/surveys/${id}`,
    method: "PATCH",
    params: {
      status: status,
    },
  });

export const updateOrgCreditsStatus = (id: number, status: StatusT) =>
  clientRequest({
    url: `/api/organizations/credit-requests/${id}`,
    method: "PATCH",
    params: {
      status: status,
    },
  });

export const acceptOrgCredits = (
  id: number,
  status: StatusT,
  emailCredits: number,
  phoneCredits: number,
  companyDataCredits: number
) =>
  clientRequest({
    url: `/api/organizations/credit-requests/${id}`,
    method: "PATCH",
    params: {
      status: status,
    },
    data: {
      emailCredits,
      phoneCredits,
      companyDataCredits
    },
  });

export const getAllNewCreditsRequests = () =>
  clientRequest({
    url: `/api/organizations/credit-requests`,
    method: "GET",
  });

export const exportOrgProfileById = (id: number) =>
  clientRequest({
    url: `/api/organizations/${id}/exports`,
    method: "GET",
  });

export const exportAllOrgProfile = () =>
  clientRequest({
    url: `/api/organizations/exports`,
    method: "GET",
  });

export const acceptCreditRequest = (id: number) =>
  clientRequest({
    url: `/api/organizations/credit-requests/${id}`,
    method: "PATCH",
    params: {
      status: "ACCEPTED",
    },
  });

export const declineCreditRequest = (id: number) =>
  clientRequest({
    url: `/api/organizations/credit-requests/${id}`,
    method: "PATCH",
    params: {
      status: "DECLINED",
    },
  });

export const renewRequest = (data: RenewRequestI) =>
  clientRequest({
    url: `/api/admin/subscriptions`,
    method: "POST",
    data,
  });

export const getAllRenewalSubscriptions = () =>
  clientRequest({
    url: `/api/admin/saas-admins/subscription-renew-requests`,
    method: "GET",
  });

export const getAllHistoryRequests = (organizationId: number) =>
  clientRequest({
    url: `/api/admin/saas-admins/${organizationId}/request-histories`,
    method: "GET",
  });

export const updateSeatsRequest = ({
  requestId,
  status,
}: {
  requestId: number;
  status: "NEW" | "ACCEPTED" | "DECLINED";
}) =>
  clientRequest({
    url: `/api/organizations/seats-requests/${requestId}`,
    method: "PATCH",
    params: {
      status,
    },
  });

export const declineRenewRequest = ({
  requestId,
  status,
}: {
  requestId: number;
  status: "NEW" | "ACCEPTED" | "DECLINED";
}) =>
  clientRequest({
    url: `/api/organization/subscription-renew-requests/${requestId}`,
    method: "PATCH",
    params: {
      status,
    },
  });
