import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";

export const ContactCardWrapper = styled(Box)`
  height: ${theme.spacing(5.75)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContactLink = styled("a")`
  color: ${theme.palette.common.blue800};
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const TypographyWithOverflow = styled(Typography)`
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
