import React from "react";

import { useAppDispatch, useAppSelector } from "application/store";
import { handleArchiveOrganizations } from "../store/actions";
import {
  closeArchiveModal,
  isArchivedModalOpen,
  organizationsSelector,
} from "../store";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  ModalComponent,
  SelectComponent,
  reasonOfBlockOptions,
} from "application";

import { InventoryIcon } from "common/layout/mainLayout/assets/Inventory";
import Button from "application/components/Button";
import theme from "application/theme";

import { Box, Typography } from "@mui/material";

import { CompanyNameStyled, RedIconBackground } from "./styles";

export const ArchiveModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isArchivedModalOpen);
  const { organizationProfile } = useAppSelector(organizationsSelector);

  const schema = yup.object().shape({
    reasonOfBlock: yup.string(),
  });

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    dispatch(closeArchiveModal());
  };

  const onSubmit = () => {
    const organizationId = organizationProfile.id;
    dispatch(
      handleArchiveOrganizations(organizationId, getValues("reasonOfBlock"))
    );
    handleClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={theme.spacing(3)}
      width={475}
    >
      <Box
        textAlign="center"
        display={"flex"}
        flexDirection={"column"}
        gap={theme.spacing(3)}
      >
        <Box display="flex" width={"100%"} justifyContent="center">
          <RedIconBackground>
            <InventoryIcon
              color={theme.palette.common.red600}
              width={theme.spacing(5.25)}
              height={theme.spacing(5.25)}
            />
          </RedIconBackground>
        </Box>
        <Box>
          <Typography variant="h5" color={theme.palette.common.grey800}>
            Are you sure to archive&nbsp;
            <CompanyNameStyled>
              {organizationProfile.companyName}?
            </CompanyNameStyled>
          </Typography>
        </Box>
        <Box width={"100%"}>
          <SelectComponent
            placeholder="Select reason"
            label="Select reason of archiving"
            options={reasonOfBlockOptions}
            defaultValue={"BREAK_RULES"}
            name="reasonOfBlock"
            height={theme.spacing(5)}
            register={register("reasonOfBlock")}
          />
        </Box>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button
            label="No"
            height={5.5}
            width={15}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Archive"
            height={5.5}
            width={15}
            red
            type="submit"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
