import theme from "application/theme";

export const warningColor = (param: number) => {
  if (param <= 60)
    return {
      bgColor: theme.palette.common.red50,
      textColor: theme.palette.common.red600,
    };
  return;
};

export const showLessThan60Days = (day: number) => {
  if (day <= 60) return `${day} days`;
  return;
};
