export enum Errors {
  organizationNameRequired = "Organization name is required",
  organizationTypeRequired = "Organization type is required",
  contactLastNameRequired = "Last name is required",
  phoneRequired = "Phone number is required",
  phoneFormat = "Invalid phone number format",
  websiteRequired = "Invalid website format",
  timezoneRequired = "Timezone is required",
  locationRequired = "Location is required",
  jobPositionRequired = "Job Position is required",
  contactPersonRequired = "Contact Person is required",
  emailRequired = "Email is required",
  emailFormat = "Invalid email format",
  seatsMin = "Minimum number of seats is 1",
  seatsRequired = "Please set number of seats",
}
