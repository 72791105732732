import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { handleError, handleLoading } from "common";
import { forgotPassword } from "integration/cognito";
import { ResetPasswordDataT } from "./types";
import { PathNames } from "application/routes";

export const useResetPassword = () => {
  const navigate = useNavigate();

  const resetPassword = useCallback(async ({ email }: ResetPasswordDataT) => {
    handleLoading(true);
    try {
      const result = await forgotPassword(email);
      navigate(PathNames.confirmChange);
    } catch (error: any) {
      handleError({
        error: error.code,
        message: `${error.name}: ${
          error.customData?.tokenResponse?.error.message ||
          error.message ||
          error.code
        }`,
      });
    } finally {
      handleLoading(false);
    }
  }, []);

  return { resetPassword };
};
