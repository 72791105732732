import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { Errors, SearchPage } from "../constants";
import { LoginDataT } from "./types";
import { useSignIn } from "./useSignIn";

import {
  TextFieldStyled,
  InfoWrapper,
  FormWrapper,
  SubmitButton,
  LinkStyled,
} from "../styles";
import { Box, useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import { PathNames } from "application/routes";
import { HidePasswordIcon } from "../assets/HidePasswordIcon";
import { ShowPasswordIcon } from "../assets/ShowPasswordIcon";
import { AuthPageWrapper } from "../AuthPageLayout";
import { useLocation } from "react-router-dom";

const schema = yup
  .object({
    email: yup
      .string()
      .email(Errors.emailFormat)
      .required(Errors.emailRequired),
    password: yup.string().required(Errors.passwordRequired),
  })
  .required();

export const Login: FC = () => {
  const { singIn, pending, error } = useSignIn();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const location = useLocation();
  const firstAuth = location.search === SearchPage.welcome;
  const theme: MTheme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDataT>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: LoginDataT) => {
    await singIn(data);
  };

  return (
    <AuthPageWrapper>
      <Box sx={{ width: "100%" }}>
        <InfoWrapper>
          {firstAuth ? (
            <Typography variant="h4" mb={1.5} mt={12}>
              Welcome back <p>to BuilderBinder</p>
            </Typography>
          ) : (
            <Typography variant="h4" mb={1.5} mt={12}>
              Log In
            </Typography>
          )}
          <Typography variant="h5" color={theme.palette.common.grey800} mb={6}>
            <p>Please, Log In to your account </p>
          </Typography>

          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              {error && (
                <Box mb={3.5}>
                  <Typography
                    variant="caption"
                    color={theme.palette.common.red700}
                  >
                    That email and password combination is incorrect
                  </Typography>
                </Box>
              )}
              <TextFieldStyled
                {...register("email")}
                fullWidth
                type="Email"
                id="email"
                label="Enter your email"
                size="small"
                color="secondary"
                error={!!errors.email}
                helperText={!!errors.email && errors.email?.message}
                sx={{ marginBottom: 2.5 }}
              />
              <TextFieldStyled
                {...register("password")}
                fullWidth
                type={showPassword ? "text" : "password"}
                id="password"
                label="Enter your password"
                size="small"
                color="secondary"
                error={!!errors.password}
                helperText={!!errors.password && errors.password?.message}
                sx={{ marginBottom: 0.75 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <ShowPasswordIcon
                            color={
                              !!errors.password
                                ? theme.palette.common.red600
                                : theme.palette.common.grey600
                            }
                          />
                        ) : (
                          <HidePasswordIcon
                            color={
                              !!errors.password
                                ? theme.palette.common.red600
                                : theme.palette.common.grey600
                            }
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{ display: "flex", justifyContent: "end" }} mb={3.5}>
                <LinkStyled to={PathNames.forgotPassword}>
                  Forgot Password?
                </LinkStyled>
              </Box>

              <SubmitButton
                type="submit"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={pending}
              >
                Log In
              </SubmitButton>
            </form>
          </FormWrapper>
        </InfoWrapper>
      </Box>
    </AuthPageWrapper>
  );
};
