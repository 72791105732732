import { ThunkDispatchT, VoidThunk } from "application";
import { handleErrorNotification, hideLoader, showLoader } from "common";

import {
  setIsAddAdminModalOpen,
  setIsDeleteModalOpen,
  setSaasAdminList,
  setSaasAdminProfile,
} from ".";

import {
  deleteSaasAdmin,
  getAllSaasAdmins,
  getSaasAdminProfile,
  inviteSaasAdmin,
  resendInvite,
  setSaasAdminLastDayOfActivity,
} from "integration/api/saasAdmin";

import { InviteSaasAdminI } from "integration/api/saasAdmin/types";
import { handleResponse } from "common/responseService";
import { formatDateToYYYYMMDD } from "application/helpers";
import { SaasAdminI } from "./types";

export const handleGetAllSaasAdmins = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response = await getAllSaasAdmins();
      dispatch(setSaasAdminList(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetSaasAdminProfile = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response = await getSaasAdminProfile();
      dispatch(setSaasAdminProfile(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleSetSaasAdminLastDayOfActivity = (id: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const today = formatDateToYYYYMMDD(new Date());
      await setSaasAdminLastDayOfActivity(id, today);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleDeleteSaasAdmin = (id: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setIsDeleteModalOpen(false));
    dispatch(showLoader());
    try {
      await deleteSaasAdmin(id);
      handleResponse({
        message: `Saas admin successfully deleted`,
      });
      dispatch(handleGetAllSaasAdmins());
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleInviteSaasAdmin = (data: InviteSaasAdminI): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setIsAddAdminModalOpen(false));
    dispatch(showLoader());
    try {
      await inviteSaasAdmin(data);
      handleResponse({
        message: `Invitation successfully send`,
      });
      await dispatch(handleGetAllSaasAdmins());
    } catch (error) {
      dispatch(setIsAddAdminModalOpen(false));
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleResendInviteSaasAdmin = (
  email: string,
  name: string
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await resendInvite(email, name);
      handleResponse({
        message: `Invitation successfully resend`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetSaasAdminProfileAfterLogin = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: SaasAdminI = await getSaasAdminProfile();
      dispatch(setSaasAdminProfile(response));
      dispatch(handleSetSaasAdminLastDayOfActivity(response.id));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};
