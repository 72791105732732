import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";

import theme from "application/theme";

import { InputAdornment, TextField, Typography } from "@mui/material";
import { Close, Search } from "@mui/icons-material";

import { ListboxItem, ListboxWrapper, StyledAutocomplete } from "./styles";

const CustomListbox = (props: React.HTMLAttributes<HTMLElement>) => {
  const { children, ...other } = props;
  return (
    <ListboxWrapper component="ul" role="listbox" {...other}>
      {children}
    </ListboxWrapper>
  );
};

const Autocomplete = ({
  suggestions,
}: {
  suggestions: { id: number; organizationName: string }[];
}) => {
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();
  return (
    <StyledAutocomplete
      clearOnBlur={false}
      inputValue={inputValue}
      ListboxComponent={CustomListbox}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="autocomplete-box"
      options={suggestions}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search"
          onClick={(event) => {
            event.stopPropagation();
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  sx={{
                    color: inputValue
                      ? theme.palette.common.grey800
                      : theme.palette.common.grey600,
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: params.inputProps.value && (
              <InputAdornment
                position="end"
                onClick={() => setInputValue("")}
                sx={{ cursor: "pointer" }}
              >
                <Close sx={{ color: theme.palette.common.grey800 }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      getOptionLabel={(option: any) => option.organizationName}
      renderOption={(props, option: any) => (
        <ListboxItem
          {...props}
          onClick={() =>
            navigate(`${PathNames.organizationProfile}/${option.id}`)
          }
          key={option.id}
        >
          <Search
            sx={{
              color: theme.palette.common.grey600,
              marginRight: theme.spacing(1),
            }}
          />
          <Typography variant="overline" color={theme.palette.common.grey800}>
            {option.organizationName}
          </Typography>
        </ListboxItem>
      )}
    />
  );
};

export default Autocomplete;
