import React from "react";
import { Box } from "@mui/system";
import theme from "application/theme";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  BtnStyled,
  LinkStyled,
  NoDataImg,
  NoDataWrapper,
  StyledWarning,
} from "./styles";
import { useAppDispatch, useAppSelector } from "application/store";
import { OrganizationProfile, organizationsSelector } from "common";
import { notActiveDays, showNotActiveDays, warningColor } from "common/organization/utils";
import { PathNames } from "application/routes";
import { EditOrganizationModal } from "common/organization/modal/EditOrganizationModal";
import {
  emptyOrganizationProfile,
  isEditModalOpen,
  openEditModal,
  setOrganizationProfile,
} from "common/organization/store";
import { handleEditOrganizationOnList } from "common/organization/store/actions";
import noOrganizations from "application/assets/noOrganizations.png";
import {useOrganizations} from "common/organization/hooks/useOrganizations";
import { formatNumbersWithSeparator } from 'application/utils/numberUtils';
import CreditsItem from './CreditsItem';


const tableHead = [
  "Organization Name",
  "Used seats",
  "Activation date",
  "Company Credits used",
  "Email Credits used",
  "Phone Credits used",
  "Last day of activity",
  "Actions",
];

const OrganizationList = () => {
  const { organizations } = useAppSelector(organizationsSelector);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isEditModalOpen);
  const [organizationRow, setOrganizationRow] =
    React.useState<OrganizationProfile>({} as OrganizationProfile);
  const {fetchOrganizations, pageable:{pageNumber}} = useOrganizations();

  const handleOpen = (row: OrganizationProfile) => {
    setOrganizationRow(row);
    dispatch(openEditModal());
  };

  const onConfirmEdit = async (data: OrganizationProfile) => {
    await dispatch(handleEditOrganizationOnList(data));
    fetchOrganizations(pageNumber);
  };

  return (
    <Box>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ background: theme.palette.common.grey200 }}>
            <TableRow sx={{ height: theme.spacing(7.5) }}>
              {tableHead.map((e) => (
                <TableCell key={e}>
                  <Typography
                    variant="body2"
                    color={theme.palette.common.grey800}
                  >
                    {e}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.length > 0 &&
              organizations.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    height: theme.spacing(7.5),
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.companyName}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {formatNumbersWithSeparator(row.usedSeats || 0)}/{formatNumbersWithSeparator(row.seats || 0)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {new Date(row.activationDate)
                        .toDateString()
                        .split(" ")
                        .slice(1)
                        .join(" ")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <CreditsItem credits={row.companyDataCredits || 0} usedCredits={row.usedCompanyDataCredits || 0}/>
                  </TableCell>
                  <TableCell>
                    <CreditsItem credits={row.emailCredits || 0} usedCredits={row.usedEmailCredits || 0}/>
                  </TableCell>
                  <TableCell>
                    <CreditsItem credits={row.phoneCredits || 0} usedCredits={row.usedPhoneCredits || 0}/>
                  </TableCell>
                  {row.lastDateOfActivity ? (
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                        sx={{ display: "inline-block", alignItems: "center" }}
                        mr={2}
                      >
                        {new Date(row.lastDateOfActivity)
                          .toDateString()
                          .split(" ")
                          .slice(1)
                          .join(" ")}
                      </Typography>
                      {notActiveDays(row.lastDateOfActivity) >= 30 && (
                        <StyledWarning
                          bgcolor={
                            warningColor(notActiveDays(row.lastDateOfActivity))
                              .bgColor
                          }
                          color={
                            warningColor(notActiveDays(row.lastDateOfActivity))
                              .textColor
                          }
                          sx={{ display: "inline-block", alignSelf: "center" }}
                        >
                          {showNotActiveDays(
                            notActiveDays(row.lastDateOfActivity)
                          )}
                        </StyledWarning>
                      )}
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  <TableCell>
                    <BtnStyled
                      variant="caption"
                      onClick={() => {
                        handleOpen(row);
                      }}
                    >
                      Edit
                    </BtnStyled>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      <LinkStyled
                        to={`${PathNames.organizationProfile}/${row.id}`}
                        onClick={() =>
                          dispatch(
                            setOrganizationProfile(emptyOrganizationProfile)
                          )
                        }
                      >
                        View
                      </LinkStyled>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {organizations.length === 0 && (
          <NoDataWrapper>
            <NoDataImg src={noOrganizations} alt="" />
            <Typography
              variant="overline"
              fontWeight={500}
              color={theme.palette.common.grey800}
            >
              No organizations found
            </Typography>
            <Typography variant="caption" color={theme.palette.common.grey600}>
              Please adjust your filters and try again
            </Typography>
          </NoDataWrapper>
        )}
      </TableContainer>
      {organizationRow.id && isOpen && (
        <EditOrganizationModal
          organization={organizationRow}
          onConfirm={onConfirmEdit}
        />
      )}
    </Box>
  );
};

export default OrganizationList;
