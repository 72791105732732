import * as React from "react";

import { Box, Typography } from "@mui/material";

import theme from "application/theme";
import { TypographyWithOverflow } from "./styles";

interface Props {
  username: string;
  email: string;
}
export const SideBarFooterItem = ({ username, email }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
      p={theme.spacing(1.5, 2, 3)}
    >
      <Typography variant="button" color={theme.palette.common.grey900}>
        {username}
      </Typography>
      <TypographyWithOverflow
        variant="caption"
        color={theme.palette.common.grey700}
      >
        {email}
      </TypographyWithOverflow>
    </Box>
  );
};
