import { ThunkDispatchT, VoidThunk } from "application";
import {
  OrganizationProfile,
  handleErrorNotification,
  handleGetOrganizationProfile,
  hideLoader,
  showLoader,
} from "common";

import {
  acceptOrgCredits,
  activateOrganizations,
  addOrganization,
  archiveOrganizations,
  declineRenewRequest,
  getAllNewCreditsRequests,
  getAllNewSurveys,
  getAllRenewalSubscriptions,
  getOrgWaitingForActivation,
  getSurveyById,
  getWaitingForActivationSearchSuggestions,
  renewRequest,
  updateOrgCreditsStatus,
  updateSurveyStatus,
} from "integration/api/organization";

import { ISelectedSurvey, ISurveys, SearchSuggestions, StatusT } from "./types";
import {
  setCreditRequests,
  setRenewRequests,
  setRequestsData,
  setSearchSuggestions,
  setSelectedSurvey,
  setSurveys,
} from ".";
import { IDataFilter, RenewRequestI } from "integration/api/organization/types";
import { handleResponse } from "common/responseService";
import { SurveyStatus } from "../consts";
import { getObjectWithoutId } from "./utils";

export const handleGetSearchSuggestions = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: SearchSuggestions =
        await getWaitingForActivationSearchSuggestions();
      dispatch(setSearchSuggestions(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetOrgWaitingForActivation = (
  filterData: IDataFilter
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());

    try {
      const response: OrganizationProfile = await getOrgWaitingForActivation(
        filterData
      );
      dispatch(setRequestsData(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetAllNewSurveys = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: ISurveys = await getAllNewSurveys();
      dispatch(setSurveys(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetSurveyById = (id: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: ISelectedSurvey = await getSurveyById(id);
      dispatch(
        setSelectedSurvey({ ...response, location: response.location || "" })
      );
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleUpdateSurveyStatus = (
  id: number,
  status: StatusT
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());

    try {
      const response = await updateSurveyStatus(id, status);
      dispatch(handleGetAllNewSurveys());
      dispatch(handleGetAllCreditRequests());
      dispatch(
        handleGetOrgWaitingForActivation({
          companyName: "",
          registrationDateFrom: "",
          registrationDateTo: "",
        })
      );
      handleResponse({
        message: `Request of ${response.organizationName} was successful activated.`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleActivateRequestOrg = (id: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: OrganizationProfile = await activateOrganizations(id);
      dispatch(
        handleGetOrgWaitingForActivation({
          companyName: "",
          registrationDateFrom: "",
          registrationDateTo: "",
        })
      );
      dispatch(handleGetAllRenewalSubscriptions());
      dispatch(handleGetAllCreditRequests());
      dispatch(handleGetAllNewSurveys());
      dispatch(handleGetOrganizationProfile(response.id));
      handleResponse({
        message: `Request of ${response.companyName} was successful activated.`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleArchiveOrg = (id: number, reason: string): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: OrganizationProfile = await archiveOrganizations(
        id,
        reason
      );
      handleResponse({
        message: `Request of ${response.companyName} was successful archived.`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleAddOrganizationFromSurvey = (
  organization: OrganizationProfile,
  id: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      await addOrganization(getObjectWithoutId(organization));
      dispatch(handleUpdateSurveyStatus(id, SurveyStatus.ACCEPTED));
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

export const handleGetAllCreditRequests = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      const response: ISurveys = await getAllNewCreditsRequests();
      dispatch(setCreditRequests(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleUpdateOrgCreditsStatus = (
  id: number,
  status: StatusT
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await updateOrgCreditsStatus(id, status);
      dispatch(handleGetAllRenewalSubscriptions());
      dispatch(handleGetAllCreditRequests());
      dispatch(handleGetAllNewSurveys());
      dispatch(
        handleGetOrgWaitingForActivation({
          companyName: "",
          registrationDateFrom: "",
          registrationDateTo: "",
        })
      );
      handleResponse({
        message: `Request was successful ${status}.`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleAcceptOrgCredits = (
  id: number,
  status: StatusT,
  emailCredits: number,
  phoneCredits: number,
  companyDataCredits: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await acceptOrgCredits(id, status, emailCredits, phoneCredits, companyDataCredits);
      dispatch(handleGetAllRenewalSubscriptions());
      dispatch(handleGetAllCreditRequests());
      dispatch(handleGetAllNewSurveys());
      dispatch(
        handleGetOrgWaitingForActivation({
          companyName: "",
          registrationDateFrom: "",
          registrationDateTo: "",
        })
      );
      handleResponse({
        message: `Request was successful ${status}.`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetAllRenewalSubscriptions = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response = await getAllRenewalSubscriptions();
      dispatch(setRenewRequests(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleRenewSubscriptionRequest = (
  data: RenewRequestI
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await renewRequest(data);
      await declineRenewRequest({
        requestId: data.requestId,
        status: "ACCEPTED",
      });
      dispatch(handleGetAllRenewalSubscriptions());
      dispatch(handleGetAllCreditRequests());
      dispatch(handleGetAllNewSurveys());
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleDeclineRenewSubscriptionRequest = (
  requestId: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await declineRenewRequest({ requestId, status: "DECLINED" });
      dispatch(handleGetAllRenewalSubscriptions());
      dispatch(handleGetAllCreditRequests());
      dispatch(handleGetAllNewSurveys());
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};
