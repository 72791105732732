import { ArchiveReason } from "common/organizationArchive/store/types";
import { clientRequest } from "../requestHandler";
import { ActivationStatus } from "common";

export const getAllArchivedOrganizations = () =>
  clientRequest({
    url: "/api/organizations/archived/searches",
    method: "POST",
    params: {
      page: 0,
      size: 10,
    },
    data: {
      companyName: "",
      blockDateFrom: "",
      blockDateTo: "",
      reason: "BREAK_RULES",
    },
  });

export const getAllArchivedOrganizationsPageRequest = ({
  page,
  blockDateFrom,
  blockDateTo,
  reason,
}: {
  page: number;
  blockDateFrom: string;
  blockDateTo: string;
  reason: ArchiveReason | null;
}) =>
  clientRequest({
    url: "/api/organizations/archived/searches",
    method: "POST",
    params: {
      page,
      size: 10,
    },
    data: {
      companyName: "",
      blockDateFrom,
      blockDateTo,
      reason,
    },
  });

export const getArchivedOrganizationsSuggestions = () =>
  clientRequest({
    url: "/api/organizations/archived/suggestions",
    method: "GET",
  });

export const handleUpdateStatus = ({
  id,
  date,
  status,
  reasone,
}: {
  id: number | string;
  date: string;
  status: ActivationStatus;
  reasone?: ArchiveReason;
}) =>
  clientRequest({
    url: `/api/organizations/${id}`,
    method: "PATCH",
    params: {
      status,
      date,
      reasone,
    },
  });

export const reactivateOrganization = ({
  id,
  emailCredits,
  phoneCredits,
  companyDataCredits
}: {
  id: number | string;
  emailCredits?: number;
  phoneCredits?: number;
  companyDataCredits?: number;
}) =>
  clientRequest({
    url: `/api/organizations/archived/${id}`,
    method: "PUT",
    params: {
      emailCredits,
      phoneCredits,
      companyDataCredits
    },
  });
