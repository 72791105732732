import { Components, Direction, Transitions } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Mixins } from "@mui/material/styles/createMixins";
import { Typography } from "@mui/material/styles/createTypography";
import { Shadows } from "@mui/material/styles/shadows";
import { ZIndex } from "@mui/material/styles/zIndex";
import { Shape, Spacing } from "@mui/system";

import breakpoints, { MBreakpoints } from "./breakpoints";
import palette, { PaletteOptions } from "./pallete";
import typography from "./typography";
import "@mui/material/styles/createPalette";
import { ColorsPallet } from "./pallete";

declare module "@mui/material/styles/createPalette" {
  interface CommonColors extends ColorsPallet {}
}

export interface MTheme {
  shape: Shape;
  breakpoints: MBreakpoints;
  direction: Direction;
  mixins: Mixins;
  components?: Components;
  palette: PaletteOptions;
  shadows: Shadows;
  spacing: Spacing;
  transitions: Transitions;
  typography: Typography;
  zIndex: ZIndex;
  unstable_strictMode?: boolean;
}

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette,
  typography,
  breakpoints,
});

export default theme;
