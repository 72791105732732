import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";

export const initialState = {
  message: null,
};

export const responseSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    logResponse: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { logResponse } = responseSlice.actions;
export const responseSelector = () => store.getState().response;
