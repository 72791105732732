import { ThunkDispatchT, VoidThunk } from "application";
import {
  CreditRequestI,
  OrganizationsDataI,
  OrganizationsFilterT,
  RenewRequestI,
} from "integration/api/organization/types";
import {
  acceptCreditRequest,
  addOrganization,
  archiveOrganizations,
  createCreditRequest,
  declineCreditRequest,
  updateSeatsRequest,
  editOrganization,
  exportAllOrgProfile,
  exportOrgProfileById,
  getAllHistoryRequests,
  getAllOrganizations,
  getOrganizationProfile,
  getOrganizations,
  getOrganizationsSearchSuggestions,
  renewRequest,
  declineRenewRequest,
  activateArchivedOrganization,
} from "integration/api/organization";
import {
  HistoryRequestI,
  OrganizationProfile,
  handleErrorNotification,
  hideLoader,
  showLoader,
} from "common";
import {
  closeActivateModal,
  closeAddCreditsModal,
  closeEditModal,
  openResultModal,
  openSuccessActivatedModal,
  setAddOrganizationEmailError,
  setCreatedOrganization,
  setHistoryRequests,
  setOrganizationProfile,
  setOrganizations,
  setPageable,
  setSearchSuggestions,
} from ".";
import { downloadCSV } from "application/helpers";
import { getObjectWithoutId } from "common/requests/store/utils";

export const handleGetAllOrganizations = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: OrganizationsDataI = await getAllOrganizations();
      dispatch(setOrganizations(response.content));
      dispatch(
        setPageable({
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          pageNumber: response.pageable.pageNumber,
          numberOfElements: response.numberOfElements,
        })
      );
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleCreateCreditRequest = ({
  date,
  phoneCredits,
  emailCredits,
  organizationId,
    companyDataCredits
}: CreditRequestI): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(closeAddCreditsModal());
    dispatch(showLoader());
    try {
      await createCreditRequest({
        date,
        phoneCredits,
        emailCredits,
        companyDataCredits,
        organizationId,
      });
      dispatch(handleGetAllHistoryRequests(organizationId));
      dispatch(handleGetOrganizationProfile(organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};
export const handleGetOrganizationProfile = (
  id: string | number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: OrganizationsDataI = await getOrganizationProfile(id);
      dispatch(setOrganizationProfile(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleAddOrganization = (
  organization: OrganizationProfile
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());

    try {
      const response: OrganizationProfile = await addOrganization(
        getObjectWithoutId(organization)
      );
      dispatch(setCreatedOrganization(response));
      dispatch(openResultModal());
    } catch (error: any) {
      if (error.response.data.message.includes("Email already exists:")) {
        dispatch(setAddOrganizationEmailError(true));
      }
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleEditOrganizationOnList = (
  organization: OrganizationProfile
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(closeEditModal());
    dispatch(showLoader());
    try {
      const response: OrganizationProfile = await editOrganization(
        organization
      );
      dispatch(setCreatedOrganization(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleEditOrganizationOnOrganization = (
  organization: OrganizationProfile,
  profileId: string | number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(closeEditModal());
    dispatch(showLoader());
    try {
      const response: OrganizationProfile = await editOrganization(
        organization
      );
      dispatch(handleGetOrganizationProfile(profileId));
      dispatch(setCreatedOrganization(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetOrganizationsSearchSuggestions = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: OrganizationsDataI =
        await getOrganizationsSearchSuggestions();
      dispatch(setSearchSuggestions(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetOrganizations = ({
  companyName,
  activationDateFrom,
  activationDateTo,
  registrationDateFrom,
  registrationDateTo,
  page,
  size,
}: OrganizationsFilterT): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: OrganizationsDataI = await getOrganizations({
        companyName,
        activationDateFrom,
        activationDateTo,
        registrationDateFrom,
        registrationDateTo,
        page,
        size,
      });
      dispatch(setOrganizations(response.content));
      dispatch(
        setPageable({
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          pageNumber: response.pageable.pageNumber,
          numberOfElements: response.numberOfElements,
        })
      );
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleActivateOrganizations = (id: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await activateArchivedOrganization(id);
      dispatch(closeActivateModal());
      dispatch(openSuccessActivatedModal());
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleArchiveOrganizations = (
  id: number,
  reason: string
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const resp = await archiveOrganizations(id, reason);
      dispatch(handleGetOrganizationProfile(resp.id));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleExportOrgProfileById = (id: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response = await exportOrgProfileById(id);
      downloadCSV(response, `org_profile-${id}.csv`);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleExportAllOrgProfile = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response = await exportAllOrgProfile();
      downloadCSV(response, "org_profiles.csv");
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const acceptNewCreditsRequest = ({
  requestId,
  organizationId,
}: {
  requestId: number;
  organizationId: number;
}): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await acceptCreditRequest(requestId);
      dispatch(handleGetAllHistoryRequests(organizationId));
      dispatch(handleGetOrganizationProfile(organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const declineNewCreditsRequest = ({
  requestId,
  organizationId,
}: {
  requestId: number;
  organizationId: number;
}): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await declineCreditRequest(requestId);
      dispatch(handleGetAllHistoryRequests(organizationId));
      dispatch(handleGetOrganizationProfile(organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetAllHistoryRequests = (
  organizationId: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: HistoryRequestI[] = await getAllHistoryRequests(
        organizationId
      );
      dispatch(setHistoryRequests(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleRenewRequest = (data: RenewRequestI): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await renewRequest(data);
      await declineRenewRequest({
        requestId: data.requestId,
        status: "ACCEPTED",
      });
      dispatch(handleGetAllHistoryRequests(data.organizationId));
      dispatch(handleGetOrganizationProfile(data.organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleDeclineSeatsRequest = ({
  requestId,
  organizationId,
}: {
  requestId: number;
  organizationId: number;
}): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await updateSeatsRequest({ requestId, status: "DECLINED" });
      dispatch(handleGetAllHistoryRequests(organizationId));
      dispatch(handleGetOrganizationProfile(organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleAcceptSeatsRequest = ({
  requestId,
  organizationId,
}: {
  requestId: number;
  organizationId: number;
}): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await updateSeatsRequest({ requestId, status: "ACCEPTED" });
      dispatch(handleGetAllHistoryRequests(organizationId));
      dispatch(handleGetOrganizationProfile(organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleDeclineRenewRequestHistory = ({
  requestId,
  organizationId,
}: {
  requestId: number;
  organizationId: number;
}): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await declineRenewRequest({ requestId, status: "DECLINED" });
      dispatch(handleGetAllHistoryRequests(organizationId));
      dispatch(handleGetOrganizationProfile(organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};
