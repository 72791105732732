import theme from "application/theme";

import { Autocomplete, Box, styled } from "@mui/material";

export const StyledAutocomplete = styled(Autocomplete)`
  width: ${theme.spacing(45)};
  & .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
    height: ${theme.spacing(2.5)};
  }
  & .MuiOutlinedInput-root {
    padding: ${theme.spacing(1.25, 1.75)} !important;
  }
  & .MuiAutocomplete-popupIndicator {
    display: none;
  }
  & fieldset {
    border-radius: ${theme.spacing(1)};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid ${theme.palette.common.blue700} !important;
    }
    &.Mui-fo &:hover fieldset {
      border-color: ${theme.palette.common.grey400};
    }
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.common.grey400};
  }
  & .MuiOutlinedInput-input::placeholder {
    font-size: ${theme.spacing(2)};
    line-height: ${theme.spacing(2.75)};
    opacity: 1;
    color: ${theme.palette.common.grey600};
  }
`;

export const ListboxItem = styled("li")`
  padding: ${theme.spacing(0.75, 1.75)};
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${theme.palette.common.grey100};
  }
`;

export const ListboxWrapper = styled(Box)`
  height: ${theme.spacing(18)};
  border-radius: ${theme.spacing(1)};
  padding: 0 !important;
`;
