import axios from "axios";
import type { AxiosRequestConfig } from "axios";
import { getAuthToken } from "integration/cognito";

const baseURL = process.env.REACT_APP_BASE_URL;
const config: AxiosRequestConfig = {
  baseURL,
  timeout: 30000,
};
const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
  async (apiConfig: AxiosRequestConfig) => {
    const token = await getAuthToken();
    let newHeaders = apiConfig.headers || {};
    if (token) {
      newHeaders = {
        ...newHeaders,
        Authorization: `Bearer ${token}`,
      };
    }
    return { ...apiConfig, headers: newHeaders };
  }
);

export default axiosInstance;
