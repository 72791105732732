import { typeOfOrganizationConst } from "application";
import { OrganizationProfile } from "./types";

export const preparedOrganizationData = (orgData: OrganizationProfile) => {
  if (!orgData) {
    return {};
  }

  return {
    ...orgData,
    organizationType:
      orgData.organizationType || typeOfOrganizationConst.MARKETING,
  };
};
