import React, { useState } from "react";
import {
  DropdownItem,
  DropdownStyle,
  SelectButton,
  SelectContainer,
} from "./styles";
import { ClickAwayListener, Typography } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import theme from "application/theme";

interface CustomSelectI {
  onChange: (value: string) => void;
  label: string;
  values: Array<any>;
  height?: number;
  top?: boolean;
  itemWidth?: string;
  selected: string | number;
  fontWeight?: number;
  maxHeight?: number;
}

const CustomSelect = ({
  onChange,
  label,
  values,
  height,
  top,
  itemWidth,
  selected,
  fontWeight,
  maxHeight,
}: CustomSelectI) => {
  const [open, setOpen] = useState(false);

  const toggleSelect = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (value: string) => {
    if (onChange) onChange(value);
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <SelectContainer>
        <SelectButton onClick={toggleSelect}>
          <Typography
            variant="body2"
            mr={1}
            color={theme.palette.common.grey900}
            fontWeight={fontWeight}
          >
            {label}
          </Typography>
          {open ? (
            <ArrowDropUp sx={{ color: theme.palette.common.grey900 }} />
          ) : (
            <ArrowDropDown sx={{ color: theme.palette.common.grey900 }} />
          )}
        </SelectButton>
        <DropdownStyle
          visible={open ? 1 : 0}
          height={Number(maxHeight) < Number(height) ? maxHeight : height}
          maxHeight={maxHeight}
          sx={{
            top: top
              ? Number(maxHeight) < Number(height)
                ? `-${maxHeight}px`
                : `-${height}px`
              : theme.spacing(5),
          }}
        >
          {values.map((value) => (
            <DropdownItem
              onClick={() => handleChange(value)}
              active={value == selected ? 1 : 0}
              key={value}
              sx={{ width: itemWidth ? itemWidth : "100%" }}
            >
              {value}
            </DropdownItem>
          ))}
        </DropdownStyle>
      </SelectContainer>
    </ClickAwayListener>
  );
};

export default CustomSelect;
