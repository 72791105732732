import * as React from "react";

import { useLocation } from "react-router-dom";

import { IListItem, SideBarProps } from "./types";

import { SideBarItem } from "./SideBarItem";

import { FormatListBulletedIcon } from "../assets/FormatListBulleted";
import { NotificationIcon } from "../assets/Notification";

import theme from "application/theme";

import { SideBarFooterItem } from "./SideBarFooterItem";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import LogoIcon from "../assets/Logo.svg";
import LogoFullIcon from "../assets/LogoFull.svg";
import { InventoryIcon } from "../assets/Inventory";
import { LogOutIcon } from "../assets/LogOut";

import {
  BuilderNavLogo,
  DrawerStyled,
  ListWrapper,
  LogoWrapper,
} from "./styles";

import { PathNames, RoutesParam } from "application/routes/constants";
import { LockIcon } from "../assets/LockIcon";
import useToggleModal from "application/hooks/useToggleModal";
import { SidebarItembutton } from "./SidebarItembutton";
import { LogoutConfirmationModal } from "./LogoutModal";
import { useAppSelector } from "application/store";
import { saasAdminSelector } from "common/saasAdminUsersManagement/store";

const listOfItems: IListItem[] = [
  {
    icon: <FormatListBulletedIcon />,
    label: "Organizations List",
    notifications: 0,
    path: PathNames.organizations,
  },
  {
    icon: <NotificationIcon />,
    label: "Requests",
    notifications: 0,
    path: `${PathNames.requests}/${RoutesParam.waitingForActivation}`,
  },
  {
    icon: <InventoryIcon />,
    label: "Archive",
    notifications: 0,
    path: PathNames.archive,
  },
  {
    icon: <LockIcon />,
    label: "Admin",
    notifications: 0,
    path: PathNames.saasAdminManagement,
  },
];

export const SideBar = ({ isOpen, setIsOpen }: SideBarProps) => {
  const { pathname } = useLocation();
  const { isOpen: isLogoutModal, openModal, closeModal } = useToggleModal();
  const { saasAdminProfile } = useAppSelector(saasAdminSelector);

  const secondSlashIndex = pathname.indexOf("/", 1);
  const pathFirstPart =
    secondSlashIndex !== -1
      ? pathname.substring(0, secondSlashIndex)
      : pathname;

  return (
    <Box sx={{ display: "flex" }}>
      <DrawerStyled
        variant="permanent"
        open={isOpen}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <LogoWrapper sx={{ paddingRight: isOpen ? theme.spacing(2) : "0" }}>
          <BuilderNavLogo
            src={isOpen ? LogoFullIcon : LogoIcon}
            alt="logo"
            open={isOpen}
          />
        </LogoWrapper>
        <Divider sx={{ marginRight: isOpen ? theme.spacing(2) : "0" }} />

        <ListWrapper open={isOpen}>
          <List sx={{ paddingTop: 0 }}>
            {listOfItems.map((item: IListItem) => (
              <SideBarItem
                item={item}
                open={isOpen}
                key={item.path}
                active={item.path.includes(pathFirstPart) ? 1 : 0}
              />
            ))}
          </List>
          <Box>
            {isOpen && (
              <>
                <SideBarFooterItem
                  username={saasAdminProfile.name || ""}
                  email={saasAdminProfile.email || ""}
                />
                <Divider
                  sx={{ marginRight: isOpen ? theme.spacing(2) : "0" }}
                />
              </>
            )}
            <List>
              <SidebarItembutton
                item={{
                  icon: <LogOutIcon />,
                  label: "Log Out",
                }}
                onClick={openModal}
                open={isOpen}
              />
            </List>
          </Box>
        </ListWrapper>
      </DrawerStyled>
      <LogoutConfirmationModal
        isOpen={isLogoutModal}
        handleClose={closeModal}
      />
    </Box>
  );
};
