import { ThunkDispatchT, VoidThunk } from "application";
import { setIsAuth, setLoading } from "./authStore";
import { getAuthToken } from "integration/cognito";
import { handleGetSaasAdminProfile } from "common/saasAdminUsersManagement/store/actions";
import { store } from 'application/store';

export const handleIsAuth = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setLoading(true));
    const token = await getAuthToken();
    if (token) {
      dispatch(setIsAuth(true));
      dispatch(handleGetSaasAdminProfile());
    } else {
      dispatch(setIsAuth(false));
    }
    dispatch(setLoading(false));
  };
};

export const handleLogout = () => {
  store.dispatch(setIsAuth(false));
};
