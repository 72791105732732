import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import React from "react";
import AuthImage from "./AuthImage";
import { LoginPageContentWrapper, LoginPageWrapper } from "./styles";

export const AuthPageWrapper = ({ children }: { children: JSX.Element }) => {
  const theme: MTheme = useTheme();
  return (
    <LoginPageWrapper>
      <AuthImage />
      <LoginPageContentWrapper>
        {children}
        <Typography variant="caption" color={theme.palette.common.grey700}>
          ©BuilderBinder copyright
        </Typography>
      </LoginPageContentWrapper>
    </LoginPageWrapper>
  );
};
