export enum PathNames {
  home = "/",
  login = "/login",
  registration = "/registration",
  confirmChange = "/confirm-password",
  forgotPassword = "/forgot-password",
  createPassword = "/create-new-password",
  error = "/error",
  organizations = "/organizations",
  organizationProfile = "/organization-profile",
  archive = "/archive",
  requests = "/requests",
  saasAdminManagement = "/saas-admin-management",
}

export enum RoutesParam {
  waitingForActivation = "waiting-for-activation",
  survey = "survey",
  newExports = "new-exports",
  subscriptionRenewal = "subscription-renewal",
}
