import React, { FC, useLayoutEffect } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { errorsSelector, Notify, Loader, LoaderModal } from "common";

import { PathNames } from "./constants";

import { authRoutes, publicRoutes } from "./routes";
import { responseSelector } from "common/responseService";
import { useAppDispatch, useAppSelector } from "application/store";
import { authSelector } from "common/auth/store/authStore";
import { handleIsAuth } from "common/auth/store/actions";
import { isLoaderSelector } from "common/loaderModal/loaderSlice";

export const AppRouter: FC = () => {
  const error = useSelector(errorsSelector);
  const response = useSelector(responseSelector);
  const dispatch = useAppDispatch();
  const { isAuth, isLoading } = useAppSelector(authSelector);
  const loader = useSelector(isLoaderSelector);

  useLayoutEffect(() => {
    dispatch(handleIsAuth());
  }, []);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {isAuth
          ? authRoutes.map(({ path, component }) => (
              <Route path={path} element={component} key={path} />
            ))
          : publicRoutes.map(({ path, component }) => (
              <Route path={path} element={component} key={path} />
            ))}
        <Route path="/*" element={<Navigate to={PathNames.error} />} />
      </Routes>
      <Loader />
      <Notify message={error?.message || ""} type={"error"} />
      <Notify message={response?.message || ""} type={"success"} />
      <LoaderModal isOpen={loader.isLoading} />
    </BrowserRouter>
  );
};
