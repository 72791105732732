import React from "react";
import { Modal } from "@mui/material";
import { LoaderWrapper } from "./styles";
import StyledLoader from "common/loader/StyledLoader";

export const LoaderModal = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Modal
      open={isOpen}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoaderWrapper>
        <StyledLoader size={82} />
      </LoaderWrapper>
    </Modal>
  );
};
