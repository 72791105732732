import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const MainContentWrapper = styled("main")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${theme.palette.common.grey100};
`;

export const ContentContainer = styled(Box)`
  max-width: 100%;
  padding: ${theme.spacing(4, 4, 0)};
  height: 100%;
`;