import { Box, styled } from "@mui/material";
import theme from "application/theme";

export const NoDataWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-items: center;
  padding-top: ${theme.spacing(4)};
`;

export const NoDataImg = styled("img")`
  width: ${theme.spacing(21.5)};
  height: ${theme.spacing(16)};
  margin-bottom: ${theme.spacing(2)};
`;
