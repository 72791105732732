import { Box, styled } from "@mui/system";
import { Link } from "react-router-dom";
import theme from "application/theme";
import { Typography } from "@mui/material";

export const ListWrapper = styled(Box)`
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const ListHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(2, 2.5)};
`;

export const StyledWarning = styled(Box)`
  display: flex;
  text-align: center;
  height: ${theme.spacing(2.75)};
  padding: ${theme.spacing(0.25, 1)};
  border-radius: ${theme.spacing(2)};
  font-size: ${theme.spacing(1.5)};
  line-height: ${theme.spacing(2.25)};
  font-weight: 400;
`;

export const LinkStyled = styled(Link)`
  color: ${theme.palette.common.blue700};
  font-weight: 500;
  font-weight: ${theme.typography.fontWeightMedium};
  font-size: ${theme.spacing(1.75)};
  text-decoration: none;

  border-radius: ${theme.spacing(1)};
  border: 1px solid ${theme.palette.common.blue700};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: ${theme.spacing(8)};
  height: ${theme.spacing(4)};
  padding: ${theme.spacing(0.75, 2)};
`;

export const BtnStyled = styled(Typography)`
  color: ${theme.palette.common.blue700};
  font-weight: ${theme.typography.fontWeightMedium};
  font-size: ${theme.spacing(1.75)};
  line-height: ${theme.spacing(2.5)};
  text-decoration: none;
  margin-right: ${theme.spacing(2)};
  cursor: pointer;
`;

export const NoDataWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-items: center;
  padding-top: ${theme.spacing(4)};
`;

export const NoDataImg = styled("img")`
  width: ${theme.spacing(21.5)};
  height: ${theme.spacing(16)};
  margin-bottom: ${theme.spacing(2)};
`;
