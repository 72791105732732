import { createSlice } from "@reduxjs/toolkit";
import { getTimezones } from "./api";
import { prepareTimezones } from "./utils";
import { TimezoneT } from "./types";
import { VoidThunk, ThunkDispatchT } from "application";
import { RootState } from "application/store";
import { handleErrorNotification } from "common";

export const initialState = {
  timezone: [],
};

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    setTimezone: (state, { payload }) => {
      state.timezone = payload;
    },
  },
});

export const handleGetTimezoneItems = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    getTimezones()
      .then((response: { zones: TimezoneT }) => {
        const preparedTimezoneOptions = prepareTimezones(response.zones);
        dispatch(setTimezone(preparedTimezoneOptions));
      })
      .catch((error: any) => {
        handleErrorNotification(error);
      });
  };
};

export const { setTimezone } = dictionarySlice.actions;

export const timezoneSelector = (state: RootState) => state.dictionary.timezone;
