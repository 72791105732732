import { ThunkDispatchT, VoidThunk } from "application";
import { handleErrorNotification, hideLoader, showLoader } from "common";
import {
  getAllArchivedOrganizations,
  getAllArchivedOrganizationsPageRequest,
  getArchivedOrganizationsSuggestions,
  reactivateOrganization,
} from "integration/api/archivedOrganizations";
import { ArchiveReason, ArchivedOrganizationsResponse } from "./types";
import {
  closeReactivateModal,
  setArchivedOrganizations,
  setArchivedOrganizationsSearchSuggestions,
  setPageable,
} from ".";

export const handleGetAllArchivedOrganizations = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: ArchivedOrganizationsResponse =
        await getAllArchivedOrganizations();
      dispatch(setArchivedOrganizations(response.content));
      dispatch(
        setPageable({
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          pageNumber: response.pageable.pageNumber,
          numberOfElements: response.numberOfElements,
        })
      );
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetAllArchivedOrganizationsSearch = ({
  page,
  blockDateFrom,
  blockDateTo,
  reason,
}: {
  page: number;
  blockDateFrom: string;
  blockDateTo: string;
  reason: ArchiveReason | null;
}): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: ArchivedOrganizationsResponse =
        await getAllArchivedOrganizationsPageRequest({
          page,
          blockDateFrom,
          blockDateTo,
          reason,
        });
      dispatch(setArchivedOrganizations(response.content));
      dispatch(
        setPageable({
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          pageNumber: response.pageable.pageNumber,
          numberOfElements: response.numberOfElements,
        })
      );
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetArchivedOrganizationsSearchSuggestions =
  (): VoidThunk => {
    return async (dispatch: ThunkDispatchT) => {
      dispatch(showLoader());
      try {
        const response = await getArchivedOrganizationsSuggestions();
        dispatch(setArchivedOrganizationsSearchSuggestions(response));
      } catch (error) {
        handleErrorNotification(error);
      } finally {
        dispatch(hideLoader());
      }
    };
  };

export const handleReactivateOrganization = ({
  id,
  emailCredits,
  phoneCredits,
  companyDataCredits
}: {
  id: number | string;
  emailCredits?: number;
  phoneCredits?: number;
  companyDataCredits?: number;
}): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(closeReactivateModal());
    dispatch(showLoader());
    try {
      await reactivateOrganization({
        id,
        emailCredits,
        phoneCredits,
        companyDataCredits
      });
      dispatch(handleGetAllArchivedOrganizations());
      dispatch(handleGetArchivedOrganizationsSearchSuggestions());
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};
