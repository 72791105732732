import React, { useEffect } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import DatePickerWithText from "application/components/DatePicker";
import {
  Autocomplete,
  Pagination,
  SelectComponent,
  reasonOfBlockOptions,
} from "application";

import theme from "application/theme";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import OrganizationList from "./OrganizationsTable";

import { ListWrapper, ListHeaderWrapper } from "./OrganizationsTable/styles";
import { useLocation, useNavigate } from "react-router-dom";
import {
  handleGetAllArchivedOrganizations,
  handleGetAllArchivedOrganizationsSearch,
  handleGetArchivedOrganizationsSearchSuggestions,
} from "../store/actions";
import { useAppDispatch, useAppSelector } from "application/store";
import { archivedOrganizationsSelector, setBlockDate } from "../store";
import { ArchiveReason } from "../store/types";
import { dateFormatter } from "common/organization/OrganizationsList/utils";

const schema = yup.object().shape({
  reasonOfBlock: yup.string(),
});

const OrganizationsArchive = () => {
  const dispatch = useAppDispatch();
  const { pageable, blockDate, suggestions } = useAppSelector(archivedOrganizationsSelector);
  const navigate = useNavigate();
  const location = useLocation();

  const blockDateFrom = dateFormatter(blockDate[0]);
  const blockDateTo = dateFormatter(blockDate[1]);

  const pageRequest = async (page: number) => {
    dispatch(
      handleGetAllArchivedOrganizationsSearch({
        page,
        blockDateFrom: blockDateFrom ?? "",
        blockDateTo: blockDateTo ?? "",
        reason: ArchiveReason.brakeRules,
      })
    );
  };

  const getBlockDate = (
    param: Date | [Date | null, Date | null] | null
  ) => {
    if (Array.isArray(param)) {
      const [startBlockDate, endBlockDate] = param;
      dispatch(
        setBlockDate([startBlockDate, endBlockDate])
      );
    }
  };

  const { register } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(handleGetAllArchivedOrganizations());
    dispatch(handleGetArchivedOrganizationsSearchSuggestions())
  }, []);
  
  useEffect(() => {
    pageRequest(isNaN(currentPage) ? 0 : currentPage - 1);
    if (!!blockDate[0]) {
      navigate(`${location.pathname}`);
    }
  }, [blockDate]);

  const currentPage = Number(location.search.split("?")[1]);

  return (
    <ListWrapper sx={{ boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)" }}>
      <ListHeaderWrapper>
        <Box display="flex" flexDirection="column">
          <Typography
            variant="caption"
            color={theme.palette.common.grey800}
            sx={{ display: "block" }}
            mb={0.75}
          >
            Search for organizations
          </Typography>
          <Autocomplete suggestions={suggestions} />
        </Box>
        <Box display={"flex"}>
          <Box>
            <DatePickerWithText
              label="Block date"
              startValue={blockDate}
              getValue={getBlockDate}
            />
          </Box>
          <Box ml={1.5}>
            <SelectComponent
              placeholder="Select reason"
              label="Reason of block"
              options={reasonOfBlockOptions}
              defaultValue={"BREAK_RULES"}
              name="reasonOfBlock"
              width={theme.spacing(27.5)}
              height={theme.spacing(5)}
              register={register("reasonOfBlock")}
            />
          </Box>
        </Box>
      </ListHeaderWrapper>
      <OrganizationList />
      <Pagination
        totalElements={pageable.totalElements}
        numberOfElements={pageable.numberOfElements}
        totalPages={pageable.totalPages}
        pageRequest={pageRequest}
        currentPage={isNaN(currentPage) ? 1 : currentPage}
        currentPath={location.pathname}
      />
    </ListWrapper>
  );
};

export default OrganizationsArchive;
