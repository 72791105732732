import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";
import { ArchivedOrganizationI } from "./types";

export const initialState = {
  archivedOrganizations: [] as ArchivedOrganizationI[],
  pageable: {
    totalPages: 1,
    totalElements: 0,
    pageNumber: 0,
    numberOfElements: 0,
  },
  reactivateModalOpen: false,
  suggestions: [
    {
      id: 0,
      organizationName: "",
    },
  ],
  blockDate: [null, null] as [Date | null, Date | null],
};

export const archivedOrganizationSlice = createSlice({
  name: "archivedOrganizations",
  initialState,
  reducers: {
    setArchivedOrganizations: (state, action) => {
      state.archivedOrganizations = action.payload;
    },
    setArchivedOrganizationsSearchSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    setPageable: (state, action) => {
      state.pageable = action.payload;
    },
    setBlockDate: (state, action) => {
      state.blockDate = action.payload;
    },
    openReactivateModal: (state) => {
      state.reactivateModalOpen = true;
    },
    closeReactivateModal: (state) => {
      state.reactivateModalOpen = false;
    },
  },
});

export default archivedOrganizationSlice.reducer;

export const archivedOrganizationsSelector = () => store.getState().archived;

export const { setArchivedOrganizations } = archivedOrganizationSlice.actions;
export const { setArchivedOrganizationsSearchSuggestions } =
  archivedOrganizationSlice.actions;
export const { setPageable } = archivedOrganizationSlice.actions;
export const { setBlockDate } = archivedOrganizationSlice.actions;

export const { openReactivateModal } = archivedOrganizationSlice.actions;
export const { closeReactivateModal } = archivedOrganizationSlice.actions;
