import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

type CognitoConfigType = {
  UserPoolId: string;
  ClientId: string;
};

const cognitoConfig: CognitoConfigType = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? "",
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? "",
};
export const UserPool = new CognitoUserPool(cognitoConfig);

export const login = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const user = new CognitoUser({ Username: email, Pool: UserPool });
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });
  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: (data) => {
        resolve(data);
      },
    });
  });
};
export const logout = () => {
  const user = UserPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
};

export const signup = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return new Promise((resolve, reject) => {
    UserPool.signUp(
      email,
      password,
      [new CognitoUserAttribute({ Name: "email", Value: email })],
      [],
      async (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      }
    );
  });
};

export const validateEmail = async ({
  email,
  code,
}: {
  email: string;
  code: string;
}) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUserFromEmail(email);
    cognitoUser.confirmRegistration(code, false, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const getSessionFromUser = (cognitoUser: any) => {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err: any, session: any) => {
      resolve(session);
      reject(err);
    });
  });
};

export const getAuthToken = async () => {
  const cognitoUser = UserPool.getCurrentUser();
  if (cognitoUser) {
    const session: any = await getSessionFromUser(cognitoUser);
    if (session) {
      return session.getAccessToken().getJwtToken();
    }
  }
};

export const getCognitoUserFromEmail = (email: string) => {
  return new CognitoUser({
    Username: email.toLowerCase(),
    Pool: UserPool,
  });
};

export function forgotPassword(email: string) {
  // setup cognitoUser first
  const user = new CognitoUser({ Username: email, Pool: UserPool });

  // call forgotPassword on cognitoUser
  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
}

export function confirmPassword({
  email,
  verificationCode,
  newPassword,
}: {
  email: string;
  verificationCode: string;
  newPassword: string;
}) {
  const user = new CognitoUser({ Username: email, Pool: UserPool });

  return new Promise((resolve, reject) => {
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
}
