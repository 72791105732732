import React from "react";
import theme from "application/theme";
import { Box } from "@mui/system";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import { range } from "ramda";
import CustomSelect from "../CustomSelect";
import { StyledIconButton } from "./styles";

interface DatePickerHeaderI {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: DatePickerHeaderI) => {
  const years = range(2023, new Date().getFullYear() + 1);
  return (
    <Box display="flex" justifyContent="space-between" m={1}>
      <StyledIconButton
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <NavigateBefore sx={{ color: theme.palette.common.grey800 }} />
      </StyledIconButton>

      <CustomSelect
        label={months[date.getMonth()]}
        values={months}
        selected={months[date.getMonth()]}
        onChange={(value) => changeMonth(months.indexOf(value))}
      />
      <CustomSelect
        label={date.getFullYear().toString()}
        values={years}
        selected={date.getFullYear().toString()}
        onChange={(value) => changeYear(Number(value))}
      />
      <StyledIconButton
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <NavigateNext sx={{ color: theme.palette.common.grey800 }} />
      </StyledIconButton>
    </Box>
  );
};

export default DatePickerHeader;
