import { Box } from "@mui/material";
import { styled } from "@mui/system";

import theme from "application/theme";

export const ListWrapper = styled(Box)`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const ListHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(2, 2.5)};
`;

export const FormWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const FormContainer = styled("form")`
  width: 100%;
`;

export const FormItemContainer = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing(3)};
  margin: ${theme.spacing(4, 0)};
`;

export const ButtonContainer = styled(Box)`
  margin-left: calc(100% - ${theme.spacing(32.5)});
  width: ${theme.spacing(32.5)};
  display: flex;
  justify-content: space-between;
`;

export const PaginationWrapper = styled(Box)`
  min-height: ${theme.spacing(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftSideHeader = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const BackLink = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: ${theme.spacing(4)};
`;

export const InfoCardWrapper = styled(Box)`
  background: ${theme.palette.common.white};
  padding: ${theme.spacing(3)};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.palette.common.grey400};
  width: 100%;
  border-radius: ${theme.spacing(1)};
`;

export const WarningBadge = styled(Box)`
  height: ${theme.spacing(3.5)};
  padding: ${theme.spacing(0, 1.5)};
  border-radius: ${theme.spacing(2)};
`;
export const ContactPersonStyled = styled("span")`
  color: ${theme.palette.common.turquoise600};
  font-weight: 500;
  display: inline;
`;
