import React from "react";

import { Typography } from "@mui/material";

import theme from "application/theme";

import { NavbarItemWrapper, NavbarBadge, NavbarBadgeText } from "../styles";

interface TabProps {
  label: string;
  count: number;
  active?: boolean;
  onClick: () => void;
}

export const TabItem = ({ label, count, active, onClick }: TabProps) => {
  return (
    <NavbarItemWrapper active={active ? 1 : 0} onClick={onClick}>
      <Typography
        variant="overline"
        color={
          active ? theme.palette.common.blue700 : theme.palette.common.grey600
        }
        mr={count && 1}
        fontWeight={active ? 500 : 400}
      >
        {label}
      </Typography>
      {count ? (
        <NavbarBadge active={active ? 1 : 0}>
          <NavbarBadgeText active={active ? 1 : 0}>{count}</NavbarBadgeText>
        </NavbarBadge>
      ) : (
        <></>
      )}
    </NavbarItemWrapper>
  );
};
