import React from "react";
import { BadgeWrapper, Dot } from "./style";
import { Typography } from "@mui/material";
import { ActivationStatus } from "common";
import { badgeHelper } from "./utils";

const BadgeWithDot = ({ status }: { status: ActivationStatus | string }) => {
  return (
    <BadgeWrapper status={status}>
      <Dot status={status} />
      <Typography variant="overline">{badgeHelper(status).text}</Typography>
    </BadgeWrapper>
  );
};

export default BadgeWithDot;
