import React, { useState } from "react";

import { Box } from "@mui/system";

import theme from "application/theme";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import {
  ArchiveBtnStyled,
  LinkStyled,
  ListHeaderWrapper,
  ListWrapper,
  StructureWrapperStrict,
} from "../styles";

import { useAppDispatch, useAppSelector } from "application/store";
import { requestsSelector } from "../store";
import { ArchiveModal } from "../modal/ArchiveModal";

import { PathNames } from "application/routes/constants";
import DatePickerWithText from "application/components/DatePicker";
import { TableHeadComponent, Autocomplete, Button } from "application";

import { formatDate } from "application/helpers";

import {
  handleActivateRequestOrg,
  handleGetOrgWaitingForActivation,
} from "../store/actions";

import { dateFormatter } from "common/organization/OrganizationsList/utils";
import { OrganizationProfile } from "common/organization";
import EmptyTableRequests from "../emptyTableData";
import { formatNumbersWithSeparator } from 'application/utils/numberUtils';

const tableHead = [
  "Organization Name",
  "Category",
  "Use case",
  "Registration Date",
  "Company credits",
  "Phone credits",
  "Email credits",
  "Created",
  "Actions",
];

const WaitingForActivationTable = () => {
  const { requestsData, suggestions } = useAppSelector(requestsSelector);
  const [isOpenArchive, setIsOpenArchive] = useState(false);
  const [orgData, setOrgData] = useState<OrganizationProfile>(
    {} as OrganizationProfile
  );
  const dispatch = useAppDispatch();

  const getRegistrationDate = (
    param: Date | [Date | null, Date | null] | null
  ) => {
    if (Array.isArray(param)) {
      const [startRegistrationDate, endRegistrationDate] = param;
      dispatch(
        handleGetOrgWaitingForActivation({
          companyName: "",
          registrationDateFrom: dateFormatter(startRegistrationDate) ?? "",
          registrationDateTo: dateFormatter(endRegistrationDate) ?? "",
        })
      );
    }
  };

  return (
    <ListWrapper sx={{ boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)" }}>
      <ListHeaderWrapper>
        <Box display="flex" flexDirection="column">
          <Typography
            variant="caption"
            color={theme.palette.common.grey800}
            sx={{ display: "block" }}
            mb={0.75}
          >
            Search for companies
          </Typography>
          <Autocomplete suggestions={suggestions} />
        </Box>
        <Box display={"flex"}>
          <Box>
            <DatePickerWithText
              label="Registration date"
              startValue={[null, null]}
              getValue={getRegistrationDate}
            />
          </Box>
        </Box>
      </ListHeaderWrapper>
      <StructureWrapperStrict>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderBottomLeftRadius: theme.spacing(1),
            borderBottomRightRadius: theme.spacing(1),
          }}
        >
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHeadComponent tableHead={tableHead} />
            <TableBody>
              {requestsData.length ? (
                requestsData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      height: theme.spacing(7.5),
                      "&:last-child td, &:last-child th": {
                        border: theme.spacing(2),
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {row.companyName}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {row.organizationType ?? "-"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {row.jobPosition}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {row.registrationDate
                          ? formatDate(row.registrationDate)
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: theme.spacing(14) }}>
                      <Typography
                          variant="caption"
                          color={theme.palette.common.grey800}
                      >
                        {formatNumbersWithSeparator(row.companyDataCredits)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: theme.spacing(14) }}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {formatNumbersWithSeparator(row.phoneCredits)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: theme.spacing(14) }}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {formatNumbersWithSeparator(row.emailCredits)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {row.activationDate
                          ? formatDate(row.activationDate)
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box gap={2} display="flex" alignItems="center">
                        <ArchiveBtnStyled
                          variant="body2"
                          onClick={() => {
                            setOrgData(row);
                            setIsOpenArchive(true);
                          }}
                        >
                          Archive
                        </ArchiveBtnStyled>
                        <Typography
                          variant="caption"
                          color={theme.palette.common.grey800}
                        >
                          <LinkStyled
                            to={`${PathNames.organizationProfile}/${row.id}`}
                          >
                            View
                          </LinkStyled>
                        </Typography>
                        <Button
                          label="Activate"
                          width={11.25}
                          fontSize={theme.spacing(1.75)}
                          height={4}
                          type="submit"
                          onClick={() => {
                            dispatch(handleActivateRequestOrg(row.id));
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
          {!requestsData.length ? <EmptyTableRequests /> : <></>}
        </TableContainer>
      </StructureWrapperStrict>
      <ArchiveModal
        isOpen={isOpenArchive}
        setIsOpen={setIsOpenArchive}
        id={orgData.id}
        name={orgData.companyName ?? ""}
      />
    </ListWrapper>
  );
};

export default WaitingForActivationTable;
