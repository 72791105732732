import {useAppDispatch, useAppSelector} from "application/store";
import {organizationsSelector} from "../store";
import {dateFormatter} from "../OrganizationsList/utils";
import {handleGetOrganizations} from "../store/actions";


export const useOrganizations = () => {
    const { organizations } = useAppSelector(organizationsSelector);
    const dispatch = useAppDispatch();

    const { pageable, registrationDate, activationDate } = useAppSelector(
        organizationsSelector
    );
    const activationDateFrom = dateFormatter(activationDate[0]);
    const activationDateTo = dateFormatter(activationDate[1]);
    const registrationDateFrom = dateFormatter(registrationDate[0]);
    const registrationDateTo = dateFormatter(registrationDate[1]);

    const fetchOrganizations = async (page: number) => {
        dispatch(
            handleGetOrganizations({
                page: page,
                size: 10,
                companyName: "",
                activationDateFrom: activationDateFrom ?? "",
                activationDateTo: activationDateTo ?? "",
                registrationDateFrom: registrationDateFrom ?? "",
                registrationDateTo: registrationDateTo ?? "",
            })
        );
    }
    return {
        fetchOrganizations,
        organizations,
        pageable
    }
}