import React from "react";
import { InputAdornment, Typography } from "@mui/material";
import {
  CheckCircleOutline,
  EmailOutlined,
  ErrorOutline,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import theme from "application/theme";

import { InputStyled } from "./styles";

import { FormInputProps } from "./types";

const FormInput = ({
  label,
  name,
  defaultValue,
  error,
  value,
  placeholder,
  register: { onChange, onBlur, ref, name: fieldName, ...rest },
  type = "text",
  isValid,
  disabled = false,
}: FormInputProps) => {
  return (
    <Box
      sx={{ width: "100%" }}
      mb={theme.spacing(1)}
      display="flex"
      flexDirection="column"
      alignItems="start"
    >
      {label && (
        <Typography
          variant="caption"
          color={
            error ? theme.palette.common.red700 : theme.palette.common.grey800
          }
          sx={{ display: "block" }}
          mb={0.75}
        >
          {label}
        </Typography>
      )}
      <InputStyled
        placeholder={placeholder || "Enter data"}
        type={type}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        name={fieldName}
        {...rest}
        fullWidth
        value={value}
        id={name}
        error={!!error}
        isvalid={isValid ? 1 : 0}
        disabled={disabled}
        helperText={error}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {type === "email" && (
                <EmailOutlined
                  sx={{
                    color: error
                      ? theme.palette.common.red600
                      : theme.palette.common.grey600,
                  }}
                />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {error ? (
                <ErrorOutline
                  sx={{
                    color: theme.palette.common.red600,
                  }}
                />
              ) : (
                !error &&
                isValid && (
                  <CheckCircleOutline
                    sx={{
                      color: theme.palette.common.green800,
                    }}
                  />
                )
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default FormInput;
