import { Grid } from "@mui/material";
import React from "react";
import { useAppSelector } from "application/store";
import { organizationsSelector } from "common/organization/store";
import { getTimeByTimeZone } from "application/helpers";
import ContactCard from "application/components/ContactCard";

const ContactBlock = () => {
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const {
    phone,
    location,
    jobPosition,
    emailContactPerson,
    timezone,
    website,
    contactPerson
  } = organizationProfile;

  return (
    <Grid container spacing={2.5} mb={4}>
      <Grid item xs={3}>
        <ContactCard name="Contact Person" label={contactPerson} />
      </Grid>
      <Grid item xs={3}>
        <ContactCard name="Location" label={location} />
      </Grid>
      <Grid item xs={3}>
        <ContactCard
          name="Contact Person Email"
          label={emailContactPerson}
          url={`mailto:${emailContactPerson}`}
          link
          withIcon
        />
      </Grid>
      <Grid item xs={3}>
        <ContactCard name="Company Website"
                     label={website}
                     url={website || '#'}
                     link withIcon />
      </Grid>
      <Grid item xs={3}>
        <ContactCard name="Contact Person Job Position" label={jobPosition} />
      </Grid>
      <Grid item xs={3}>
        <ContactCard name="Time Zone" label={getTimeByTimeZone(timezone) || ""} />
      </Grid>
      <Grid item xs={3}>
        <ContactCard name="Phone" label={phone} link />
      </Grid>
    </Grid>
  );
};

export default ContactBlock;
