import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { RoutesParam } from "application/routes/constants";

import { AddOrganizationModal, handleGetTimezoneItems } from "common";

import {
  RequestsForNewCreditsTable,
  RequestsForSubscriptionRenewalTable,
  RequestsFromSurveyTable,
  WaitingForActivationTable,
} from "../tabs";
import { useAppDispatch } from "application/store";
import { ResultModal } from "common/organization/modal/ResultModal";
import { ResetModal } from "common/organization/modal/ResetModal";
import {
  handleGetAllCreditRequests,
  handleGetAllNewSurveys,
  handleGetAllRenewalSubscriptions,
  handleGetOrgWaitingForActivation,
  handleGetSearchSuggestions,
} from "../store/actions";

const RequestsTables = () => {
  const { name } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(handleGetTimezoneItems());
    dispatch(
      handleGetOrgWaitingForActivation({
        companyName: "",
        registrationDateFrom: "",
        registrationDateTo: "",
      })
    );
    dispatch(handleGetAllNewSurveys());
    dispatch(handleGetAllCreditRequests());
    dispatch(handleGetAllRenewalSubscriptions());
    dispatch(handleGetSearchSuggestions());
  }, [dispatch]);

  return (
    <>
      {name === RoutesParam.waitingForActivation && (
        <WaitingForActivationTable />
      )}
      {name === RoutesParam.survey && <RequestsFromSurveyTable />}
      {name === RoutesParam.newExports && <RequestsForNewCreditsTable />}
      {name === RoutesParam.subscriptionRenewal && (
        <RequestsForSubscriptionRenewalTable />
      )}

      <ResultModal />
      <ResetModal />
      <AddOrganizationModal />
    </>
  );
};

export default RequestsTables;
