import { Box, styled } from "@mui/material";
import theme from "application/theme";

export const HeaderWrapper = styled(Box)`
  padding: ${theme.spacing(0, 0, 2.5, 0)};
  align-items: center;
  width: 100%;
  justify-content: space-between;
  display: flex;
`;
