import * as yup from "yup";
import { Errors } from "./consts";

const phoneNumberRegex =
  /^(\+?)(\d{1,3})(\s?)(\d{3})(\s?)(\d{3})(\s?)(\d{2})(\s?)(\d{2})$/;

export const schema = yup.object().shape({
  companyName: yup.string().required(Errors.organizationNameRequired),
  organizationType: yup.string().required(Errors.organizationTypeRequired),
  contactPerson: yup.string().required(Errors.contactPersonRequired),
  jobPosition: yup.string().required(Errors.jobPositionRequired),
  location: yup.string().required(Errors.locationRequired),
  timezone: yup.string().required(Errors.timezoneRequired),
  phone: yup
    .string()
    .matches(phoneNumberRegex, Errors.phoneFormat)
    .required(Errors.phoneRequired),
  emailContactPerson: yup
    .string()
    .email(Errors.emailFormat)
    .required(Errors.emailRequired),
  website: yup.string().url(Errors.websiteRequired),
  seats: yup.number().min(1, Errors.seatsMin).required(Errors.seatsRequired),
  emailCredits: yup.number(),
  phoneCredits: yup.number(),
  companyDataCredits: yup.number(),
  organizationStructureAccessPermitted: yup.boolean(),
});
