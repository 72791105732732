import React, { FC, useState } from "react";
import { Typography } from "@mui/material";
import { InfoWrapper, FormWrapper, SubmitButton } from "../styles";
import { useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import { AuthPageWrapper } from "../AuthPageLayout";
import { useCreateNewPassword } from "./useCreateNewPassword";
import { CreatePasswordForm } from "../PasswordInputs/CreatePasswordForm";
import { useLocation } from "react-router-dom";

export const CreateNewPassword: FC = () => {
  const theme: MTheme = useTheme();
  const { createNewPassword } = useCreateNewPassword();
  const location = useLocation();
  const [password, setPassword] = useState<string | null>(null);
  const email = location.search.split("?email=").pop()?.split("&")[0] ?? "";
  const code = location.search.split("code=")[1] ?? "";

  const onSubmit = async () => {
    if (password) {
      createNewPassword({ email, verificationCode: code, newPassword: password });
    }
  };

  return (
    <AuthPageWrapper>
      <InfoWrapper mt={12}>
        <Typography variant="h4" mb={1.5}>
          Create New Password
        </Typography>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={6}>
          Please, create new password for your <p>account</p>
        </Typography>
        <FormWrapper>
          <CreatePasswordForm getPassword={setPassword} />
          <SubmitButton
            type="submit"
            variant="contained"
            onClick={() => onSubmit()}
          >
            Set password
          </SubmitButton>
        </FormWrapper>
      </InfoWrapper>
    </AuthPageWrapper>
  );
};
