import { createSlice } from '@reduxjs/toolkit';
import { store } from 'application/store';

export const initialState = {
  error: null,
  message: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    logError: {
      reducer: (state, action) => action.payload,
      // @ts-ignore
      prepare: (error: Error) => ({ payload: error }),
    },
  },
});

export const { logError } = errorSlice.actions;
export const errorsSelector = () => store.getState().error;
