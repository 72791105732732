import React from "react";
import { Box } from "@mui/system";
import theme from "application/theme";

import { useAppDispatch, useAppSelector } from "application/store";

import { PathNames } from "application/routes";

import {
  emptyOrganizationProfile,
  setOrganizationProfile,
} from "common/organization/store";
import noOrganizations from "application/assets/noOrganizations.png";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { BtnStyled, LinkStyled, NoDataImg, NoDataWrapper } from "./styles";
import { ReactivateOrganization } from "common/organizationArchive/ReactivateOrganizationModal";
import {
  archivedOrganizationsSelector,
  closeReactivateModal,
  openReactivateModal,
} from "common/organizationArchive/store";
import {
  ArchiveReason,
  ArchivedOrganizationI,
} from "common/organizationArchive/store/types";
import { formatDate } from "application/helpers";

const tableHead = [
  "Organization Name",
  "Archived by",
  "Exports used",
  "Activation date",
  "Date of archiving",
  "Reason of archiving",
  "Actions",
];

const OrganizationList = () => {
  const { archivedOrganizations, reactivateModalOpen } = useAppSelector(
    archivedOrganizationsSelector
  );

  const dispatch = useAppDispatch();
  const [organizationRow, setOrganizationRow] =
    React.useState<ArchivedOrganizationI>({} as ArchivedOrganizationI);

  const handleOpen = (row: ArchivedOrganizationI) => {
    dispatch(openReactivateModal());
    setOrganizationRow(row);
  };
  const handleClose = () => {
    dispatch(closeReactivateModal());
  };

  return (
    <Box>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table sx={{ minWidth: 650 }} aria-label="table">
          <TableHead
            sx={{
              background: theme.palette.common.grey200,
            }}
          >
            <TableRow
              sx={{
                height: theme.spacing(6.25),
              }}
            >
              {tableHead.map((e) => (
                <TableCell key={e}>
                  <Typography
                    variant="body2"
                    color={theme.palette.common.grey800}
                  >
                    {e}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {archivedOrganizations.length > 0 &&
              archivedOrganizations.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    height: theme.spacing(7.5),
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.companyName}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.archivedBy ? row.archivedBy : "-"}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        Emails: {row?.usedEmailCredits || 0}/{row?.emailCredits}
                      </Typography>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        Phone: {row?.usedPhoneCredits || 0}/{row?.phoneCredits}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {new Date(row.activationDate)
                        .toDateString()
                        .split(" ")
                        .slice(1)
                        .join(" ")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.archivationDate
                        ? formatDate(row.archivationDate)
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.archiveReason === ArchiveReason.brakeRules
                        ? "Break rules"
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      <LinkStyled
                        to={`${PathNames.organizationProfile}/${row.id}`}
                        onClick={() => {
                          dispatch(
                            setOrganizationProfile(emptyOrganizationProfile)
                          );
                        }}
                      >
                        View
                      </LinkStyled>
                    </Typography>

                    <BtnStyled
                      variant="caption"
                      onClick={() => handleOpen(row)}
                    >
                      Reactivate
                    </BtnStyled>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {archivedOrganizations.length === 0 && (
          <NoDataWrapper>
            <NoDataImg src={noOrganizations} alt="" />
            <Typography
              variant="overline"
              fontWeight={500}
              color={theme.palette.common.grey800}
            >
              No organizations found
            </Typography>
            <Typography variant="caption" color={theme.palette.common.grey600}>
              Please adjust your filters and try again
            </Typography>
          </NoDataWrapper>
        )}
      </TableContainer>
      {reactivateModalOpen && organizationRow && (
        <ReactivateOrganization
          isOpen={reactivateModalOpen}
          handleClose={handleClose}
          data={organizationRow}
        />
      )}
    </Box>
  );
};

export default OrganizationList;
