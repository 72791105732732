import React, { useEffect, useState } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ModalComponent, QuantityPicker } from "application";
import theme from "application/theme";

import { Box, Typography } from "@mui/material";
import { ReactivateArrow } from "application/assets/ReactivateArrow";
import Button from "application/components/Button";

import { ContactPersonStyled, IconBackground } from "./styles";
import { ArchiveReason, ArchivedOrganizationI } from "./store/types";
import { useAppDispatch } from "application/store";
import { handleReactivateOrganization } from "./store/actions";

const schema = yup.object().shape({
  phoneCredits: yup.number(),
  emailCredits: yup.number(),
});

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  data: ArchivedOrganizationI;
}

export const ReactivateOrganization = ({
  isOpen,
  handleClose,
  data,
}: Props) => {
  const dispatch = useAppDispatch();
  const [emailCredits, setEmailCredits] = useState<number>(data.emailCredits);
  const [phoneCredits, setPhoneCredits] = useState<number>(data.phoneCredits);
  const [companyDataCredits, setCompanyDataCredits] = useState<number>(data.companyDataCredits);

  const [error, setError] = useState<boolean>(false);
  const onSubmit = async () => {
    if (!(emailCredits || phoneCredits || companyDataCredits)){
      setError(true);
      return
    }
    setError(false);
    dispatch(
      handleReactivateOrganization(prepareRequest())
    );
  };
  const prepareRequest = () => {
    return data.organizationStructureAccessPermitted ?
      {
        id: data.id,
        emailCredits,
        phoneCredits,
        companyDataCredits,
      } : {
        id: data.id,
        companyDataCredits
      }
  }

  const { register } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneCredits: data.phoneCredits,
      emailCredits: data.emailCredits,
      companyDataCredits: data.companyDataCredits,
    },
  });

  useEffect(() => {
    if (emailCredits || phoneCredits) setError(false);
  }, [emailCredits, phoneCredits]);

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={theme.spacing(4)}
      width={545}
    >
      <Box
        textAlign="center"
        display={"flex"}
        flexDirection={"column"}
        gap={theme.spacing(3)}
      >
        <Box display="flex" width={"100%"} justifyContent="center">
          <IconBackground>
            <ReactivateArrow />
          </IconBackground>
        </Box>
        <Box>
          <Typography variant="h5" color={theme.palette.common.grey800}>
            Are you sure to reactivate of
          </Typography>
          <Typography variant="h5" color={theme.palette.common.turquoise600}>
            {data.companyName}?
          </Typography>
          <Typography variant="caption" color={theme.palette.common.grey700}>
            Reminder: this company was archived {data.lastDateOfActivity}
          </Typography>
          <Typography variant="caption" color={theme.palette.common.grey700}>
            ,&nbsp;because{" "}
            {data.archiveReason === ArchiveReason.brakeRules
              ? "break rules"
              : ""}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <QuantityPicker
            label={"Company credits"}
            register={register("companyDataCredits")}
            getValue={setCompanyDataCredits}
            defaultValue={data.companyDataCredits || 0}
            error={error}
          />
          {
            data.organizationStructureAccessPermitted &&
            <>
              <QuantityPicker
                label={"Email credits"}
                register={register("emailCredits")}
                getValue={setEmailCredits}
                defaultValue={data.emailCredits || 0}
                error={error}
              />
              <QuantityPicker
                label={"Phone credits"}
                register={register("phoneCredits")}
                getValue={setPhoneCredits}
                defaultValue={data.phoneCredits || 0}
                error={error}
              />
            </>
          }
        </Box>
        {error ? (
          <Typography variant="caption" color={theme.palette.common.red600}>
            Select at least 1 credit export
          </Typography>
        ) : (
          <></>
        )}
        <Typography variant="caption" color={theme.palette.common.grey700}>
          Then contact person:&nbsp;
          <ContactPersonStyled>{data.contactPerson}</ContactPersonStyled>
          &nbsp;will get email notification about it and access to the system.
        </Typography>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button
            label="Cancel"
            height={5.5}
            width={15}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Reactivate"
            height={5.5}
            disabled={error}
            width={15}
            type="submit"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
