import theme from "application/theme";
import React from "react";

export const DeleteIcon = ({
  color = theme.palette.common.red600,
}: {
  color?: string;
}) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <mask
          id="mask0_931_28229"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="42"
          height="42"
        >
          <rect id="Bounding box" width="42" height="42" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_931_28229)">
          <path
            id="delete"
            d="M12.25 36.75C11.2875 36.75 10.4635 36.4073 9.77813 35.7219C9.09271 35.0365 8.75 34.2125 8.75 33.25V10.5H7V7H15.75V5.25H26.25V7H35V10.5H33.25V33.25C33.25 34.2125 32.9073 35.0365 32.2219 35.7219C31.5365 36.4073 30.7125 36.75 29.75 36.75H12.25ZM29.75 10.5H12.25V33.25H29.75V10.5ZM15.75 29.75H19.25V14H15.75V29.75ZM22.75 29.75H26.25V14H22.75V29.75Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
