import React from "react";

import { useSelector } from "react-redux";

import {
  SelectComponent,
  FormInput,
  typeOfOrganizationConst,
  typeOfOrganizationOptions,
  QuantityPicker,
} from "application";

import { IOrganizationForm, timezoneSelector } from "common";
import { Box } from "@mui/material";

export const AddOrganizationForm = ({
  errors,
  register,
  watch,
  dirtyFields,
}: IOrganizationForm) => {
  const timezone = useSelector(timezoneSelector);

  return (
    <>
      <FormInput
        name="name"
        placeholder="Organization name"
        label="Organization name"
        error={errors.companyName?.message}
        register={register("companyName")}
        value={watch("companyName")}
        isValid={dirtyFields.companyName}
      />
      <SelectComponent
        label="Type of organization"
        options={typeOfOrganizationOptions}
        name="organizationType"
        defaultValue={
          watch("organizationType") || typeOfOrganizationConst.MARKETING
        }
        placeholder="Select Type of organization"
        error={errors.type?.message}
        register={register("organizationType")}
      />
      <FormInput
        name="contactPerson"
        placeholder="Contact Person"
        label="Contact Person"
        error={errors.contactPerson?.message}
        register={register("contactPerson")}
        value={watch("contactPerson")}
        isValid={dirtyFields.contactPerson}
      />
      <FormInput
        name="jobPosition"
        placeholder="Job Position"
        label="Job Position"
        error={errors.jobPosition?.message}
        register={register("jobPosition")}
        value={watch("jobPosition")}
        isValid={dirtyFields.jobPosition}
      />
      <FormInput
        name="location"
        placeholder="Location"
        label="Location"
        error={errors.location?.message}
        register={register("location")}
        defaultValue={watch("location") || ""}
        value={watch("location")}
        isValid={dirtyFields.location}
      />
      <SelectComponent
        placeholder="Select Time Zone"
        label="Time Zone"
        options={timezone}
        defaultValue={watch("timezone") || ""}
        name="timezone"
        error={errors.timezone?.message}
        register={register("timezone")}
      />
      <FormInput
        name="phone"
        placeholder="+380 935019296"
        label="Phone"
        error={errors.phone?.message}
        register={register("phone")}
        value={watch("phone")}
        isValid={dirtyFields.phone}
      />
      <FormInput
        name="emailContactPerson"
        type="email"
        placeholder="Rayna.Botosh@example.com"
        label="Email Contact Person"
        error={errors.emailContactPerson?.message}
        register={register("emailContactPerson")}
        value={watch("emailContactPerson")}
        isValid={dirtyFields.emailContactPerson}
      />
      <FormInput
        name="website"
        placeholder="Enter link to website"
        label="Company Website (optional)"
        error={errors.website?.message}
        register={register("website")}
        value={watch("website")}
        isValid={dirtyFields.website}
      />
      <Box>
        <QuantityPicker
          min={1}
          label={"Team members in a Team"}
          defaultValue={1}
          error={!!errors.seats}
          register={register("seats")}
        />
      </Box>
    </>
  );
};
