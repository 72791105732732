import { Box } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";
import { ActivationStatus } from "common";
import { badgeHelper } from "./utils";

export const BadgeWrapper = styled(Box)<{ status: ActivationStatus | string }>`
  background-color: ${(props) => badgeHelper(props.status).background};
  color: ${(props) => badgeHelper(props.status).color};
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0, 1.5)};
  border-radius: ${theme.spacing(2)};
  height: ${theme.spacing(3.5)};
`;

export const Dot = styled(Box)<{ status: ActivationStatus | string }>`
  border-radius: 50%;
  width: ${theme.spacing(0.75)};
  height: ${theme.spacing(0.75)};
  background-color: ${(props) => badgeHelper(props.status).color};
  margin-right: ${theme.spacing(0.75)};
`;
