import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";

export const initialState = {
  isAuth: false,
  isLoading: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setLoading: (state, action) => {
        state.isLoading = action.payload;
      },
  },
});

export default authSlice.reducer;

export const authSelector = () => store.getState().auth;

export const { setIsAuth } = authSlice.actions;
export const { setLoading } = authSlice.actions;
