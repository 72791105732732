import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { handleError, handleLoading } from "common";
import { confirmPassword } from "integration/cognito";
import { CreatePasswordDataT } from "./types";
import { PathNames } from "application/routes";

export const useCreateNewPassword = () => {
  const navigate = useNavigate();

  const createNewPassword = useCallback(
    async ({ email, verificationCode, newPassword }: CreatePasswordDataT) => {
      handleLoading(true);
      try {
        const result = await confirmPassword({
          email,
          verificationCode,
          newPassword,
        });
        navigate(PathNames.login);
      } catch (error: any) {
        handleError({
          error: error.code,
          message: `${error.name}: ${
            error.customData?.tokenResponse?.error.message ||
            error.message ||
            error.code
          }`,
        });
      } finally {
        handleLoading(false);
      }
    },
    []
  );

  return { createNewPassword };
};
