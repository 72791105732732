import React, { useState } from "react";

import { Box } from "@mui/system";

import theme from "application/theme";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { ArchiveBtnStyled, LinkStyled, StructureWrapper } from "../styles";

import { useAppSelector } from "application/store";

import { PathNames } from "application/routes/constants";
import {Button, CreditsQuantityItem, TableHeadComponent} from "application";
import { formatDate } from "application/helpers";

import { AcceptCreditsModal } from "../modal/AcceptCreditsModal";
import { DeclineModal } from "../modal/DeclineModal";
import EmptyTableRequests from "../emptyTableData";

import { requestsSelector } from "../store";
import { ICreditRequests } from "../store/types";

const tableHead = [
  "Organization Name",
  "Quantity",
  "Request Date",
  "Actions",
];

const RequestsForNewCreditsTable = () => {
  const { creditRequests } = useAppSelector(requestsSelector);
  const [isOpen, setOpen] = useState(false);
  const [isOpenDecline, setOpenDecline] = useState(false);

  const [orgData, setOrgData] = useState<ICreditRequests>(
    {} as ICreditRequests
  );

  return (
    <StructureWrapper>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: theme.spacing(1) }}
      >
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHeadComponent tableHead={tableHead} />
          <TableBody>
            {creditRequests.length ? (
              creditRequests.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    height: theme.spacing(7.5),
                    "&:last-child td, &:last-child th": {
                      border: theme.spacing(2),
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{
                    minWidth: theme.spacing(18.75) ,
                    p: `${theme.spacing(1,2)}`
                  }}>
                    <Box display="flex" flexDirection="column">
                      <CreditsQuantityItem
                          label='Company'
                          value={row.companyDataCredits}
                      />
                      <CreditsQuantityItem
                          label='Emails'
                          value={row.emailCredits}
                      />
                      <CreditsQuantityItem
                          label='Phone'
                          value={row.phoneCredits}
                      />
                    </Box>
                  </TableCell>
                  <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.date ? formatDate(row.date) : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box gap={2} display="flex" alignItems="center">
                      <ArchiveBtnStyled
                        variant="body2"
                        onClick={() => {
                          setOrgData(row);
                          setOpenDecline(true);
                        }}
                      >
                        Decline
                      </ArchiveBtnStyled>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        <LinkStyled
                          to={`${PathNames.organizationProfile}/${row.organizationId}`}
                        >
                          View
                        </LinkStyled>
                      </Typography>
                      <Button
                        label="Accept"
                        height={4}
                        fontSize={theme.spacing(1.75)}
                        onClick={() => {
                          setOrgData(row);
                          setOpen(true);
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        {!creditRequests.length ? <EmptyTableRequests /> : <></>}
      </TableContainer>

      <DeclineModal
        isOpen={isOpenDecline}
        setIsOpen={setOpenDecline}
        id={orgData.id}
        name={orgData.name}
        isCredit
      />
      {isOpen && (
        <AcceptCreditsModal isOpen={isOpen} setIsOpen={setOpen} data={orgData} />
      )}
    </StructureWrapper>
  );
};

export default RequestsForNewCreditsTable;
