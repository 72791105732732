import { Box, styled } from "@mui/system";
import theme from "application/theme";
import { IModalPropsStyle } from "./types";

export const ModalWrapper = styled(Box)<IModalPropsStyle>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.palette.common.white};
  border: ${theme.spacing(0.25)} solid ${theme.palette.common.grey100};
  box-shadow: ${theme.spacing(0, 0.125, 0.25, 0)} rgba(16, 24, 40, 0.06);
  border-radius: ${theme.spacing(1)};
  width: ${(props) => props.width && props.width};
  padding: ${(props) => (props.padding ? props.padding : theme.spacing(3))};
  min-width: ${theme.spacing(55)};
`;