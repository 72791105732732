import React from "react";
import theme from "application/theme";

import { Box, Typography } from "@mui/material";
import { NoDataImg, NoDataWrapper } from "./styles";

import noOrganizations from "application/assets/noOrganizations.png";

interface Props {
  title?: string;
  subtitle?: string;
}

const EmptyTableRequests = ({
  title = "No organizations found",
  subtitle = "Please adjust your filters and try again",
}: Props) => {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      p={theme.spacing(3)}
    >
      <NoDataWrapper>
        <NoDataImg src={noOrganizations} alt="" />
        <Typography
          variant="overline"
          fontWeight={500}
          color={theme.palette.common.grey800}
        >
          {title}
        </Typography>
        <Typography variant="caption" color={theme.palette.common.grey600}>
          {subtitle}
        </Typography>
      </NoDataWrapper>
    </Box>
  );
};

export default EmptyTableRequests;
