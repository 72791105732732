import React from "react";
import { BadgeWrapper } from "./style";
import { Typography } from "@mui/material";
import { ActivationStatus } from "common";
import { badgeHelper } from "./utils";
import { Check } from "@mui/icons-material";
import theme from "application/theme";

const BadgeWithCheck = ({ status }: { status: ActivationStatus | string }) => {
  return (
    <BadgeWrapper status={status}>
      <Check sx={{ width: theme.spacing(1.75), height: theme.spacing(1.75)}} />
      <Typography variant="caption" ml={0.5}>{badgeHelper(status).text}</Typography>
    </BadgeWrapper>
  );
};

export default BadgeWithCheck;
