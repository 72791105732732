import { Box } from "@mui/system";
import React from "react";
import InfoCard from "./InfoCard";
import { useAppSelector } from "application/store";
import { organizationsSelector } from "common/organization/store";

const InfoBlock = () => {
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const {
    usedEmailCredits,
    usedPhoneCredits,
    emailCredits,
    phoneCredits,
    usedSeats,
    seats,
    lastDateOfActivity,
    companyDataCredits,
    usedCompanyDataCredits,
    organizationStructureAccessPermitted
  } = organizationProfile;

  return (
    <Box display={"flex"} justifyContent={"space-between"} mb={4}>
      <InfoCard
        name="Company credits used"
        mr={1.5}
        type="credits"
        usedCredits={usedCompanyDataCredits}
        credits={companyDataCredits}
      />
      {
        organizationStructureAccessPermitted &&
        <>
          <InfoCard
            name="Email credits used"
            mr={1.5}
            type="credits"
            usedCredits={usedEmailCredits}
            credits={emailCredits}
          />
          <InfoCard
            name="Phone credits used"
            mr={1.5}
            type="credits"
            usedCredits={usedPhoneCredits}
            credits={phoneCredits}
          />
        </>
      }

      <InfoCard
        name="Last date of activity"
        mr={1.5}
        type="date"
        date={lastDateOfActivity}
      />
      <InfoCard
        name="The number of used seats"
        type="seats"
        seats={seats}
        usedSeats={usedSeats}
      />
    </Box>
  );
};

export default InfoBlock;
