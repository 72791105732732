import React from "react";

import theme from "application/theme";

import { Box, Typography } from "@mui/material";

import { CompanyNameStyled, RedIconBackground } from "./styles";
import { Button, ModalComponent } from "application";

import { DeleteIcon } from "application/assets/DeleteIcon";
import { useAppDispatch } from "application/store";
import {
  handleUpdateOrgCreditsStatus,
  handleUpdateSurveyStatus,
} from "../store/actions";
import { SurveyStatus } from "../consts";

interface Props {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  id: number;
  name: string;
  isCredit?: boolean;
}

export const DeclineModal = ({
  isOpen,
  setIsOpen,
  id,
  name,
  isCredit = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDecline = () => {
    isCredit
      ? dispatch(handleUpdateOrgCreditsStatus(id, SurveyStatus.DECLINED))
      : dispatch(handleUpdateSurveyStatus(id, SurveyStatus.DECLINED));
    handleClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={theme.spacing(3)}
      width={475}
    >
      <Box
        textAlign="center"
        display={"flex"}
        flexDirection={"column"}
        gap={theme.spacing(3)}
      >
        <Box display="flex" width={"100%"} justifyContent="center">
          <RedIconBackground>
            <DeleteIcon />
          </RedIconBackground>
        </Box>
        <Box>
          <Typography variant="subtitle1" color={theme.palette.common.grey800}>
            Are you sure to decline request of &nbsp;
            <CompanyNameStyled>{name}?</CompanyNameStyled>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button width={15} label="No" type="button" onClick={handleClose} />
          <Button
            label="Decline"
            red
            width={15}
            type="submit"
            onClick={handleDecline}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
