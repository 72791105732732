import { store } from 'application/store';
import { logError, initialState } from './errorStore';
import type { APIErrorResponseI, ErrorLogT } from './types';

export const handleError = (error: ErrorLogT) => {
  store.dispatch(logError(error));
  setTimeout(() => store.dispatch(logError(initialState)), 3000);
};

export const handleErrorNotification = (error: APIErrorResponseI | any) =>
handleError({
  error: error?.code,
  message: `${error.response?.data?.message || error?.message} `,
});

export const handleErrorNotificationMessage = (message: string) =>
  handleError({
    error: "",
    message: message,
  });
