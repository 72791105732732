import { Box, styled } from "@mui/material";
import theme from "application/theme";

export const IconBackground = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.palette.common.blue50};
  border-radius: 50%;
  width: ${theme.spacing(10.25)};
  height: ${theme.spacing(10.25)};
`;

export const RedIconBackground = styled(IconBackground)`
  background: ${theme.palette.common.red50};
`;

export const GreenIconBackground = styled(IconBackground)`
  background: ${theme.palette.common.green50};
`;

export const CompanyNameStyled = styled("span")`
  color: ${theme.palette.common.turquoise600};
  display: inline;
  font-weight: 500;
`;
