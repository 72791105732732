import {Box} from "@mui/system";
import {ToggleButton} from "application";
import React from "react";
import {Control} from "react-hook-form";
import {OrganizationProfile} from "../store/types";
import {Typography} from "@mui/material";
import theme from "application/theme";


interface Props {
    control: Control<OrganizationProfile, any>
}

const OrganizationStructureAccessToggler = ({control}: Props) => {
    return (
        <Box display='flex' flexDirection='column' gap={theme.spacing(1)}>
            <Typography variant="overline"
                        color={theme.palette.common.grey800}>Access to organization structure</Typography>
            <ToggleButton
                          fieldName='organizationStructureAccessPermitted'
                          control={control}/>

        </Box>
    )
}
export default OrganizationStructureAccessToggler;