import React from "react";
import { ModalComponent } from "application";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { DeleteUserIcon } from "application/assets/DeleteUserIcon";
import Button from "application/components/Button";
import { logout } from "integration/cognito";
import { useNavigate } from "react-router-dom";
import { PathNames } from "application/routes";
import { useAppDispatch } from "application/store";
import { setIsAuth } from "common/auth/store/authStore";

export const LogoutConfirmationModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onConfirm = () => {
    logout();
    dispatch(setIsAuth(false));
    navigate(PathNames.login);
  };
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={4}
      width={475}
    >
      <Box textAlign="center">
        <Box display="flex" justifyContent="center" mb={4}>
          <DeleteUserIcon />
        </Box>
        <Typography variant="h5" color={theme.palette.common.grey800}>
          Are you sure?
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={3}
        >
          <Button
            label="No"
            width={15}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Yes"
            width={15}
            type="submit"
            red
            onClick={onConfirm}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
