import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const SelectStyled = styled(Select)`
  border-radius: ${theme.spacing(1)};
  width: 100%;
  cursor: pointer;
  font-size: ${theme.spacing(2)};
  height: ${theme.spacing(5.5)};
  text-align: left;

  &.MuiInputLabel-root {
    font-size: ${theme.spacing(1.75)};
    color: ${theme.palette.common.grey800};
    font-weight: ${theme.typography.fontWeightRegular};
  }
  &.MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.palette.common.grey400};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.palette.common.blue700};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.palette.common.grey400};
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  text-align: left;
  font-size: ${theme.spacing(2)};
  color: ${theme.palette.common.grey900};
`;
