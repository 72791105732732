import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch, useAppSelector } from "application/store";
import { QuantityPicker, ModalComponent, Button } from "application";

import theme from "application/theme";
import { Box } from "@mui/material";

import { addedOrgResponse } from "common/organization/store";
import { OrganizationForm } from "common/organization/form/OrganizationForm";
import { OrganizationProfile } from "common/organization";
import { schema } from "common/organization/form/formSchema";
import {
  ButtonContainer,
  FormContainer,
  FormItemContainer,
  FormWrapper,
} from "common/organization/styles";

import { handleAddOrganizationFromSurvey } from "../store/actions";
import {
  closeActivateSurveyModal,
  setSelectedSurvey,
  surveySelector,
  viewActivateSurveyModalSelector,
} from "../store";

export const ActivateSurveyModal = () => {
  const dispatch = useAppDispatch();
  const addedOrganization = useAppSelector(addedOrgResponse);
  const isOpen = useAppSelector(viewActivateSurveyModalSelector);
  const survey = useAppSelector(surveySelector);
  
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
    watch,
      control
  } = useForm<OrganizationProfile>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...survey,
      seats: 1,
    },
  });
  const onClose = () => {
    dispatch(closeActivateSurveyModal());
    dispatch(setSelectedSurvey({}));
  };

  const onSubmit = (formData: OrganizationProfile) => {
    dispatch(handleAddOrganizationFromSurvey(formData, survey.id));
    onClose();
  };

  const {organizationStructureAccessPermitted} = watch();

  useEffect(() => {
    !addedOrganization.id && reset();
  }, [addedOrganization, reset]);

  const descriptionText =
    "Then this organization will appear in requests tab up to activation.";

  return (
    <ModalComponent
      isOpen={isOpen}
      padding={3}
      width={"65%"}
      handleClose={onClose}
      title={"Activate new organization"}
      description={descriptionText}
    >
      <FormWrapper>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormItemContainer>
            <OrganizationForm
              control={control}
              register={register}
              errors={errors}
              dirtyFields={dirtyFields}
              watch={watch}
            />
            <Box display="flex" gap={theme.spacing(3)}>
              <QuantityPicker
                  label={"Company credits"}
                  register={register("companyDataCredits")}
                  max={999999}
              />
              {
                organizationStructureAccessPermitted &&
                  <>
                    <QuantityPicker
                        label={"Email credits"}
                        register={register("emailCredits")}
                    />
                    <QuantityPicker
                        label={"Phone credits"}
                        register={register("phoneCredits")}
                    />
                  </>
              }

            </Box>
          </FormItemContainer>
          <ButtonContainer>
            <Button label="Cancel" width={15} type="button" onClick={onClose} />
            <Button
              label="Save"
              width={15}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            />
          </ButtonContainer>
        </FormContainer>
      </FormWrapper>
    </ModalComponent>
  );
};
