import { ActivationStatus } from "common/organization/store/types";

export interface IModal {
  isOpen: boolean;
  handleClose: () => void;
}

export enum ArchiveReason {
  brakeRules = "BREAK_RULES",
}

export interface ArchivedOrganizationI {
  id: number;
  companyName: string;
  website: string;
  activationStatus: ActivationStatus;
  organizationType: string;
  emailCredits: number;
  phoneCredits: number;
  companyDataCredits: number;
  usedEmailCredits: number;
  usedPhoneCredits: number;
  usedCompanyDataCredits: number;
  contactPerson: string;
  jobPosition: string;
  location: string;
  phone: string;
  emailContactPerson: string;
  timezone: string;
  seats: number;
  usedSeats: number;
  registrationDate: string;
  activationDate: string;
  lastDateOfActivity: string;
  archiveReason: ArchiveReason;
  hubspotUrl: string;
  archivationDate: string | null;
  archivedBy: string | null;
  organizationStructureAccessPermitted: boolean;
}

export interface ArchivedOrganizationsResponse {
  totalPages: number;
  totalElements: number;
  size: number;
  content: ArchivedOrganizationI[];
  numberOfElements: number;
  pageable: {
    offset: 0;
    sort: {
      empty: boolean;
      unsorted: boolean;
      sorted: boolean;
    };
    paged: boolean;
    unpaged: boolean;
    pageNumber: number;
    pageSize: number;
  };
}

export interface SuggestionI {
  id: number;
  organizationName: string;
}
