import React, { useState } from "react";

import { Box } from "@mui/system";
import theme from "application/theme";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { ArchiveBtnStyled, StructureWrapper, ViewBtnStyled } from "../styles";

import { useAppDispatch, useAppSelector } from "application/store";
import {
  openActivateSurveyModal,
  openViewModal,
  requestsSelector,
  surveySelector,
} from "../store";
import { DeclineModal } from "../modal/DeclineModal";
import { Button, TableHeadComponent } from "application";
import { formatDate, getTimeByTimeZone } from "application/helpers";
import { ActivateSurveyModal } from "../modal/ActivateSurveyModal";
import EmptyTableRequests from "../emptyTableData";
import { handleGetSurveyById } from "../store/actions";
import { ViewSurveyRequestModal } from "../modal/ViewSurveyRequestModal";
import { ISurveys } from "../store/types";

const tableHead = [
  "Organization Name",
  "Customer Name",
  "Work Email",
  "Work Phone",
  "Location",
  "Request Date",
  "Actions",
];

const RequestsFromSurveyTable = () => {
  const dispatch = useAppDispatch();
  const { surveys } = useAppSelector(requestsSelector);
  const [isOpenDecline, setOpenDecline] = useState(false);
  const [orgTableData, setOrgTableData] = useState<ISurveys>({} as ISurveys);
  const survey = useAppSelector(surveySelector);

  const handleView = (id: number) => {
    dispatch(handleGetSurveyById(id));
    dispatch(openViewModal());
  };

  const handleAccept = (id: number) => {
    dispatch(handleGetSurveyById(id));
    dispatch(openActivateSurveyModal());
  };

  return (
    <StructureWrapper>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: theme.spacing(1) }}
      >
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
          <TableHeadComponent tableHead={tableHead} />
          <TableBody>
            {surveys.length ? (
              surveys.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    height: theme.spacing(7.5),
                    "&:last-child td, &:last-child th": {
                      border: theme.spacing(2),
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.organizationName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.contactFirstName} {row.contactLastName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.contactEmail}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.contactPhone}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                          variant="caption"
                          color={theme.palette.common.grey800}
                        >
                          {row.location}
                        </Typography>
                        <Typography
                          variant="caption"
                          color={theme.palette.common.grey600}
                        >
                          {getTimeByTimeZone(row.timezone)}
                        </Typography>
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.requestDate ? formatDate(row.requestDate) : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box gap={2} display="flex" alignItems="center">
                      <ArchiveBtnStyled
                        variant="body2"
                        onClick={() => {
                          setOrgTableData(row);
                          setOpenDecline(true);
                        }}
                      >
                        Decline
                      </ArchiveBtnStyled>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        <ViewBtnStyled
                          variant="body2"
                          onClick={() => handleView(row.id)}
                        >
                          View
                        </ViewBtnStyled>
                      </Typography>
                      <Button
                        label="Accept"
                        fontSize={theme.spacing(1.75)}
                        height={4}
                        onClick={() => handleAccept(row.id)}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        {!surveys.length ? (
          <EmptyTableRequests
            title="No requests from surveys at the moment"
            subtitle="Requests from surveys will appear here as soon as someone will populate one"
          />
        ) : (
          <></>
        )}
      </TableContainer>
      <DeclineModal
        isOpen={isOpenDecline}
        setIsOpen={setOpenDecline}
        id={orgTableData.id}
        name={orgTableData.organizationName}
      />
      {survey.companyName && <ActivateSurveyModal />}
      <ViewSurveyRequestModal />
    </StructureWrapper>
  );
};

export default RequestsFromSurveyTable;
