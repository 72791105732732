import React from "react";
import { Typography } from "@mui/material";
import theme from "application/theme";
import { Box } from "@mui/system";
import { InfoCardWrapper, WarningBadge } from "common/organization/styles";
import { formatDate } from "application/helpers";
import {
  notActiveDays,
  showNotActiveDays,
  warningColor,
} from "common/organization/utils";
import { formatNumbersWithSeparator } from 'application/utils/numberUtils';

export interface PropsI {
  name: string;
  mr?: number;
  type: "credits" | "date" | "seats";
  credits?: number | null;
  usedCredits?: number | null;
  date?: string | null;
  seats?: number | null;
  usedSeats?: number | null;
  width?: string;
  displayInfoBadge?: boolean;
}

const InfoCard = (props: PropsI) => {
  const {
    name,
    mr,
    type,
    seats,
    usedSeats,
    credits,
    usedCredits,
    date,
    width,
  } = props;

  const valueByType = () => {
    switch (type) {
      case "credits":
        return { firstValue: usedCredits, secondValue: credits };
      case "date":
        return {
          firstValue: date ? formatDate(date) : date,
          secondValue: undefined,
        };
      case "seats":
        return { firstValue: usedSeats, secondValue: seats };
      default:
        return {
          firstValue: credits,
          secondValue: usedCredits,
        };
    }
  };

  const valueExist = valueByType().firstValue && valueByType().secondValue;
  const haveCredits = credits && usedCredits && type === "credits";
  const haveDate = date && type === "date";

  return (
    <InfoCardWrapper mr={mr} width={width}>
      <Typography
        variant="caption"
        color={theme.palette.common.grey800}
        mb={1.5}
      >
        {name}
      </Typography>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"}>
          {type !== "date" ?
            <>
              <Typography variant="h5" color={theme.palette.common.grey800}>
                {valueByType().firstValue}&nbsp;
              </Typography>
              {valueByType().secondValue && (
                <Typography
                  variant="h5"
                  color={theme.palette.common.grey600}
                  sx={{fontSize: 20, lineHeight: "32px"}}
                  display={"flex"}
                  alignItems={"end"}
                >
                  / {formatNumbersWithSeparator(valueByType().secondValue || 0)}
                </Typography>
              )}
            </>
            :
            <Typography variant="subtitle1" color={theme.palette.common.grey800}>
              {valueByType().firstValue ?? "-"}
            </Typography>
          }
        </Box>
        {!!haveCredits && (
          <WarningBadge
            bgcolor={
              warningColor(Number(Math.round((usedCredits / credits) * 100)))
                .bgColor
            }
            color={
              warningColor(Number(Math.round((usedCredits / credits) * 100)))
                .textColor
            }
          >
            {Math.round((usedCredits / credits) * 100)}%
          </WarningBadge>
        )}
        {!!haveDate && notActiveDays(date) >= 30 && (
          <WarningBadge
            bgcolor={warningColor(notActiveDays(date)).bgColor}
            color={warningColor(notActiveDays(date)).textColor}
          >
            {showNotActiveDays(notActiveDays(date))}
          </WarningBadge>
        )}
      </Box>
    </InfoCardWrapper>
  );
};
export default InfoCard;
