import theme from "application/theme";

export type buttonTypes =
  | "button"
  | "submit"
  | "reset"
  | undefined;

export const buttonHelper = (type: buttonTypes) => {
  switch (type) {
    case "button":
      return {
        background: theme.palette.common.white,
        color: theme.palette.common.grey800,
        hoverBg: theme.palette.common.grey100,
        border: `1px solid ${theme.palette.common.grey400}`,
      };
    case "submit":
      return {
        background: theme.palette.common.blue700,
        color: theme.palette.common.white,
        hoverBg: theme.palette.common.blue500,
        border: "none",
      };
    case "reset":
    default:
      return {
        background: theme.palette.common.blue700,
        color: theme.palette.common.white,
        hoverBg: theme.palette.common.blue500,
        border: "none",
      };
  }
};
