import { ISelect } from "application/components/SelectComponent/types";
import { TimezoneT } from "./types";

export const prepareTimezones = (timezones: TimezoneT): ISelect[] => {
  if (!timezones) return [];

  return timezones.map((timezone: string): ISelect => {
    return { value: timezone, label: timezone };
  });
};
