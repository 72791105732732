import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch, useAppSelector } from "application/store";
import {
  closeEditModal,
  isEditModalOpen,
  setCreatedOrganization,
} from "../store";
import { IEditModal, OrganizationProfile } from "common";

import { Button, ModalComponent } from "application";
import { OrganizationForm } from "../form/OrganizationForm";

import {
  ButtonContainer,
  FormContainer,
  FormItemContainer,
  FormWrapper,
} from "../styles";

import { schema } from "../form/formSchema";
import { preparedOrganizationData } from "../store/selector";

export const EditOrganizationModal = ({
  organization,
  onConfirm,
}: IEditModal) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isEditModalOpen);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
    watch,
      control
  } = useForm<OrganizationProfile>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: preparedOrganizationData(organization),
  });

  const onSubmit = (data: OrganizationProfile) => {
    onConfirm(data);
  };

  const handleCloseModal = () => {
    reset();
    dispatch(setCreatedOrganization({}));
    dispatch(closeEditModal());
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleCloseModal}
      padding={3}
      title={"Edit organization profile"}
    >
      <FormWrapper>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormItemContainer>
            <OrganizationForm
              control={control}
              register={register}
              errors={errors}
              dirtyFields={dirtyFields}
              watch={watch}
              disableContactPerson
            />
          </FormItemContainer>
          <ButtonContainer>
            <Button
              label="Cancel"
              width={15}
              type="button"
              onClick={handleCloseModal}
            />
            <Button
              label="Save"
              width={15}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            />
          </ButtonContainer>
        </FormContainer>
      </FormWrapper>
    </ModalComponent>
  );
};
