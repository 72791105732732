import React from "react";
import { ModalComponent } from "application";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { DeleteUserIcon } from "application/assets/DeleteUserIcon";
import Button from "application/components/Button";
import { StyledPersonName } from "./styles";
import { useAppDispatch } from "application/store";
import { handleDeleteSaasAdmin } from "./store/actions";
import { SaasAdminI } from "./store/types";

export const SaasAdminDeleteModal = ({
  isOpen,
  handleClose,
  adminToDelete
}: {
  isOpen: boolean;
  handleClose: () => void;
  adminToDelete: SaasAdminI;
}) => {
  const dispatch = useAppDispatch();
  const deleteAdmin = () => {
    if (adminToDelete?.id) dispatch(handleDeleteSaasAdmin(adminToDelete?.id));
  };
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={4}
      width={475}
    >
      <Box textAlign="center">
        <Box display="flex" justifyContent="center" mb={4}>
          <DeleteUserIcon />
        </Box>
        <Typography variant="h5" color={theme.palette.common.grey800}>
          Are you sure you want to delete
          <br />
          <StyledPersonName>{adminToDelete.name}</StyledPersonName>?
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={3}
        >
          <Button
            label="No"
            height={5.5}
            width={15}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Yes"
            height={5.5}
            width={15}
            type="submit"
            red
            onClick={deleteAdmin}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
