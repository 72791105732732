import React from "react";

import { useAppDispatch } from "application/store";
import { openAddModal } from "common/organization/store";
import { HeaderComponent } from "application";

import { NavBar, RequestsTables } from "common";

const RequestsPage = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <HeaderComponent
        title={"Requests"}
        addBtnText="Add new organization"
        addBtnAction={() => dispatch(openAddModal())}
      />
      <NavBar />
      <RequestsTables />
    </>
  );
};

export default RequestsPage;
