import { StorageFields } from 'application/constants/localStorage';

export const authService = {
  saveAccessToken(jwt: string) {
    localStorage.setItem(StorageFields.fbToken, jwt);
  },

  getAccessToken() {
    return localStorage.getItem(StorageFields.fbToken);
  },

  checkAuth() {
    return !!localStorage.getItem(StorageFields.fbToken);
  },

  removeToken() {
    localStorage.removeItem(StorageFields.fbToken);
  },

  eraseSessionData() {
    this.removeToken();
  },

  saveEmail(email: string) {
    localStorage.setItem(StorageFields.email, email);
  },

  getEmail() {
    return localStorage.getItem(StorageFields.email);
  },

  removeEmail() {
    localStorage.removeItem(StorageFields.email);
  },
};
