import { ButtonProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { StyledButton } from "./styles";
import { Box } from "@mui/system";
import theme from "application/theme";

type CustomButtonProps = {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  label: string | undefined;
  mr?: number;
  ml?: number;
  height?: number;
  width?: number;
  red?: boolean;
  fontSize?: string;
  fontWeight?: string;
};

const Button = (props: ButtonProps & CustomButtonProps) => {
  const {
    startAdornment,
    endAdornment,
    onClick,
    label,
    variant,
    mr,
    ml,
    height = 5.5,
    width,
    red,
    type = "submit",
    fontSize,
    fontWeight,
  } = props;
  return (
    <Box mr={mr} ml={ml}>
      <StyledButton
        variant={variant}
        onClick={onClick}
        type={type}
        disabled={props.disabled}
        red={red ? 1 : 0}
        sx={{
          height: theme.spacing(height),
          width: width && theme.spacing(width),
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {startAdornment}
          <Typography
            fontSize={fontSize && fontSize}
            fontWeight={fontWeight && fontWeight}
            variant="button"
            ml={startAdornment ? 1.25 : 0}
            mr={endAdornment ? 1.25 : 0}
          >
            {label}
          </Typography>
          {endAdornment}
        </Box>
      </StyledButton>
    </Box>
  );
};

export default Button;
