import React from "react";

export const ReactivateArrow = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <mask
          id="mask0_931_32652"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="42"
          height="42"
        >
          <rect id="Bounding box" width="42" height="42" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_931_32652)">
          <path
            id="refresh"
            d="M21 35C17.0917 35 13.7812 33.6438 11.0687 30.9312C8.35625 28.2188 7 24.9083 7 21C7 17.0917 8.35625 13.7812 11.0687 11.0687C13.7812 8.35625 17.0917 7 21 7C23.0125 7 24.9375 7.41563 26.775 8.24688C28.6125 9.07812 30.1875 10.2667 31.5 11.8125V7H35V19.25H22.75V15.75H30.1C29.1667 14.1167 27.8906 12.8333 26.2719 11.9C24.6531 10.9667 22.8958 10.5 21 10.5C18.0833 10.5 15.6042 11.5208 13.5625 13.5625C11.5208 15.6042 10.5 18.0833 10.5 21C10.5 23.9167 11.5208 26.3958 13.5625 28.4375C15.6042 30.4792 18.0833 31.5 21 31.5C23.2458 31.5 25.2729 30.8583 27.0813 29.575C28.8896 28.2917 30.1583 26.6 30.8875 24.5H34.5625C33.7458 27.5917 32.0833 30.1146 29.575 32.0688C27.0667 34.0229 24.2083 35 21 35Z"
            fill="#2863EC"
          />
        </g>
      </g>
    </svg>
  );
};
