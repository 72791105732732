import React from "react";

export const SuccessIcon = ({ color }: { color: string | undefined }) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.50016 8.00004L6.50016 10L10.5002 6.00004M14.1668 8.00004C14.1668 11.6819 11.1821 14.6667 7.50016 14.6667C3.81826 14.6667 0.833496 11.6819 0.833496 8.00004C0.833496 4.31814 3.81826 1.33337 7.50016 1.33337C11.1821 1.33337 14.1668 4.31814 14.1668 8.00004Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
