import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const ErrorPageWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.common.grey100};
  display: flex;
  justify-content: center;
  align-items: center;
`;
