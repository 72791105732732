import { styled } from "@mui/system";
import theme from "application/theme";
import { Button } from "@mui/material";
import { buttonHelper } from "./utils";

interface ButtonProps {
  red?: number;
}

export const StyledButton = styled(Button)<ButtonProps>`
  background: ${(props) =>
    props.red
      ? theme.palette.common.red500
      : buttonHelper(props.type).background};
  padding: ${theme.spacing(1.25, 2)};
  border: ${(props) => buttonHelper(props.type).border};
  border-radius: ${theme.spacing(1)};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: ${(props) => buttonHelper(props.type).color};

  svg path {
    fill: ${(props) => buttonHelper(props.type).color};
  }
  &:hover {
    background: ${(props) =>
      props.red
        ? theme.palette.common.red500
        : buttonHelper(props.type).hoverBg};
  }
  & .Mui-disabled,
  &:disabled {
    color: ${theme.palette.common.grey600};
    cursor: "not-allowed";
    background-color: ${theme.palette.common.grey300};
    &:hover {
      cursor: not-allowed;

      background: ${(props) =>
        props.type
          ? theme.palette.common.blue500
          : theme.palette.common.grey100};
    }
  }
`;
