import React, { lazy, Suspense } from "react";

import { Navigate } from "react-router-dom";

import { Loader } from "../../common/loader/Loader";
import { MainLayout } from "../../common/layout/mainLayout/MainLayout";

import { PathNames } from "./constants";

import {
  OrganizationsPage,
  OrganizationProfilePage,
  OrganizationsArchivePage,
  NotFoundPage,
  RequestsPage,
  AdminUserManagement,
} from "application";

const AuthPage = lazy(() => import("../pages/AuthPage"));

export const authRoutes = [
  {
    path: "/",
    title: "Organizations List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.organizations} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.login,
    title: "Organizations List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.organizations} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.registration,
    title: "Organizations List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.organizations} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: "Organizations List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.organizations} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.createPassword,
    title: "Organizations List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.organizations} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmChange,
    title: "Organizations List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.organizations} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.organizations,
    title: "Organizations List",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <OrganizationsPage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.organizationProfile}/:id`,
    title: "Organization Profile",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <OrganizationProfilePage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.archive}`,
    title: "Archive",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <OrganizationsArchivePage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.requests}/:name`,
    title: "Requests",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <RequestsPage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.saasAdminManagement}`,
    title: "Organization Profile",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <AdminUserManagement />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: "/*",
    title: "Error",
    component: <NotFoundPage />,
  },
];

export const publicRoutes = [
  {
    path: PathNames.login,
    title: "Login",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.registration,
    title: "Registration",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: "Forgot Password",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.createPassword,
    title: "Create New Password",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmChange,
    title: "Confirm Password",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: "/*",
    title: "Login",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.login} replace />
      </Suspense>
    ),
  },
];
