import React from "react";

import {
  closeSuccessActivatedModal,
  isSuccessActivatedModalOpen,
  organizationsSelector,
} from "../store";
import { useAppDispatch, useAppSelector } from "application/store";
import { handleGetOrganizationProfile } from "../store/actions";

import { QuestionIcon } from "application/assets/QuestionIcon";
import Button from "application/components/Button";
import theme from "application/theme";

import { Box, Typography } from "@mui/material";

import { CompanyNameStyled, GreenIconBackground } from "./styles";
import { ModalComponent } from "application";

export const SuccessActivatedModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isSuccessActivatedModalOpen);
  const { organizationProfile } = useAppSelector(organizationsSelector);

  const handleClose = () => {
    dispatch(closeSuccessActivatedModal());
    dispatch(handleGetOrganizationProfile(organizationProfile.id));
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={theme.spacing(3)}
      width={475}
    >
      <Box
        textAlign="center"
        display={"flex"}
        flexDirection={"column"}
        gap={theme.spacing(3)}
      >
        <Box display="flex" width={"100%"} justifyContent="center">
          <GreenIconBackground>
            <QuestionIcon color={theme.palette.common.green900} />
          </GreenIconBackground>
        </Box>
        <Box>
          <Typography variant="h5" color={theme.palette.common.grey800}>
            <CompanyNameStyled>
              {organizationProfile.companyName}
            </CompanyNameStyled>
            &nbsp; was successful activated and link with access was send to
            &nbsp;
            <CompanyNameStyled>
              {organizationProfile.contactPerson}.
            </CompanyNameStyled>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button
            label="Ok"
            height={5.5}
            width={15}
            type="submit"
            onClick={handleClose}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
