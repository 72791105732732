import { clientRequest } from "../requestHandler";
import { InviteSaasAdminI } from "./types";

export const getAllSaasAdmins = () =>
  clientRequest({
    url: "/api/admin/saas-admins",
    method: "GET",
  });

export const deleteSaasAdmin = (id: number) =>
  clientRequest({
    url: `/api/admin/saas-admins/${id}`,
    method: "DELETE",
  });

export const inviteSaasAdmin = (data: InviteSaasAdminI) =>
  clientRequest({
    url: "/api/admin/saas-admins",
    method: "POST",
    data,
  });

export const resendInvite = (email: string, name: string) =>
  clientRequest({
    url: `/api/admin/saas-admins/resend-invite`,
    method: "PUT",
    params: { email, name },
  });

export const confirmAdminRegistration = (data: {
  code: string;
  password: string;
  email: string;
}) =>
  clientRequest({
    url: `/api/admin/saas-admins/confirm`,
    method: "PATCH",
    data,
  });

export const getSaasAdminProfile = () =>
  clientRequest({
    url: "/api/admin/saas-admins/profile",
    method: "GET",
  });

export const setSaasAdminLastDayOfActivity = (id: number, date: string) =>
  clientRequest({
    url: `/api/admin/saas-admins/${id}/last-activity?date=${date}`,
    method: "PATCH",
  });
