import React from "react";

import { HeaderComponent } from "application";

import OrganizationsArchive from "common/organizationArchive/OrganizationsArchiveList";
import { Box } from "@mui/system";

const OrganizationsArchivePage = () => {
  return (
    <Box pb={4}>
      <HeaderComponent title={"Archive"} />
      <OrganizationsArchive />
    </Box>
  );
};

export default OrganizationsArchivePage;
