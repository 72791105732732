import theme from "application/theme";
import { ActivationStatus as ActivationStatusOrganization } from "common";
import { ActivationStatus } from "common/saasAdminUsersManagement/store/types";

export const badgeHelper = (status: ActivationStatusOrganization | ActivationStatus | string) => {
  switch (status) {
    case ActivationStatus.unconfirmed:
      return {
        background: theme.palette.common.grey300,
        color: theme.palette.common.grey800,
        text: "Unconfirmed"
      };
    case ActivationStatus.active:
      return {
        background: theme.palette.common.green100,
        color: theme.palette.common.green900,
        text: "Active",
      };
    case ActivationStatus.inviteSent:
      return {
        background: theme.palette.common.orange50,
        color: theme.palette.common.orange800,
        text: "Invite Sent",
      };
    case "ARCHIVED":
      return {
        background: theme.palette.common.grey300,
        color: theme.palette.common.grey800,
        text: "Archived"
      };
    case "BLOCKED":
    case "DECLINED":
    case "WAITING_FOR_ACTIVATION":
    default:
      return {
        background: theme.palette.common.red50,
        color: theme.palette.common.red600,
        text: "Not Active"
      };
  }
};
