import React from "react";
import { Box } from "@mui/system";
import theme from "application/theme";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BtnStyled, LinkStyled } from "./styles";
import { useAppDispatch, useAppSelector } from "application/store";
import { SaasAdminDeleteModal } from "../ SaasAdminUsersDeleteModal";
import BadgeWithCheck from "application/components/BadgeWithCheck";
import {
  saasAdminSelector,
  setDeleteSaasAdmin,
  setIsDeleteModalOpen,
} from "../store";
import { formatDate } from "application/helpers";
import { handleResendInviteSaasAdmin } from "../store/actions";
import { SaasAdminI } from "../store/types";

const tableHead = [
  "Manager Name",
  "Email",
  "Registration date",
  "Last date of activity",
  "Status",
  "Uploaded companies manually",
  "Actions",
];

const SaasAdminTable = () => {
  const {
    saasAdminList,
    isDeleteModalOpen,
    deleteSaasAdmin,
    saasAdminProfile,
  } = useAppSelector(saasAdminSelector);
  const dispatch = useAppDispatch();

  const resendInviteSaasAdmin = (email: string, name: string) => {
    dispatch(handleResendInviteSaasAdmin(email, name));
  };

  const handleDeleteClick = (admin: SaasAdminI) => {
    dispatch(setIsDeleteModalOpen(true));
    dispatch(setDeleteSaasAdmin(admin));
  };
  const closeDeleteModal = () => {
    dispatch(setIsDeleteModalOpen(false));
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: theme.spacing(1) }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ background: theme.palette.common.grey200 }}>
            <TableRow sx={{ height: theme.spacing(7.5) }}>
              {tableHead.map((e) => (
                <TableCell key={e}>
                  <Typography
                    variant="body2"
                    color={theme.palette.common.grey800}
                  >
                    {e}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {saasAdminList.length > 0 &&
              saasAdminList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    height: theme.spacing(7.5),
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.email}
                    </Typography>
                  </TableCell>
                  {row.registrationDate ? (
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {formatDate(row.registrationDate)}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {formatDate(row.lastDateOfActivity)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ minWidth: theme.spacing(19) }}>
                    <BadgeWithCheck status={row.status} />
                  </TableCell>
                  {row.manuallyUploadedCompanies ? (
                    <TableCell>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                        sx={{ display: "inline-block", alignItems: "center" }}
                        mr={2}
                      >
                        {row.manuallyUploadedCompanies}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  <TableCell>
                    {saasAdminProfile.id !== row.id ? (
                      <Box display="flex" alignItems="center">
                        <BtnStyled
                          variant="caption"
                          onClick={() => handleDeleteClick(row)}
                        >
                          Delete
                        </BtnStyled>
                        {row.status !== "ACTIVE" ? (
                          <LinkStyled
                            onClick={() =>
                              resendInviteSaasAdmin(row.email, row.name)
                            }
                          >
                            Resend link
                          </LinkStyled>
                        ) : null}
                      </Box>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SaasAdminDeleteModal
        isOpen={isDeleteModalOpen}
        handleClose={closeDeleteModal}
        adminToDelete={deleteSaasAdmin}
      />
    </Box>
  );
};

export default SaasAdminTable;
