import React from "react";

import Logo from "./assets/Logo.svg";
import { LoginPageContentWrapper, BuilderLogo, ImageAuth } from "./styles";

const AuthImage = () => {
  return (
    <LoginPageContentWrapper>
      <ImageAuth>
        <BuilderLogo src={Logo} alt="logo" />
      </ImageAuth>
    </LoginPageContentWrapper>
  );
};

export default AuthImage;
