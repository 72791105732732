import React from "react";

export const Not404Found = () => {
  return (
    <svg
      width="158"
      height="129"
      viewBox="0 0 158 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="73.2853" cy="67.548" r="60.9523" fill="#EAECF0" />
      <circle cx="9.28566" cy="21.8335" r="6.09523" fill="#9DA3AD" />
      <circle cx="140.333" cy="102.595" r="4.57142" fill="#9DA3AD" />
      <circle cx="8.52333" cy="111.738" r="7.61904" fill="#9DA3AD" />
      <circle cx="149.475" cy="41.6425" r="7.61904" fill="#9DA3AD" />
      <circle cx="134.999" cy="14.9759" r="5.33333" fill="#9DA3AD" />
      <path
        d="M73.2853 42.3688L78.5516 40.163L73.286 42.4062V105.21L121.811 84.8759V22.0441L122.047 21.945L121.811 21.8402V21.7346L121.689 21.7863L73.6682 0.5L24.5234 21.4344L24.7561 21.5343L24.5234 84.1109L73.2853 105.173L73.2853 42.3688Z"
        fill="#F9FAFB"
      />
      <path
        d="M73.2853 42.3698V105.173L24.5234 84.1118L24.7561 21.5352L73.2853 42.3698Z"
        fill="#F7F7F7"
      />
      <path
        d="M73.2861 42.406V105.21L121.811 84.8756V21.7344L73.2861 42.406Z"
        fill="url(#paint0_linear_2840_11413)"
      />
      <path
        d="M73.2853 42.3688L122.047 21.945L73.6682 0.5L24.5234 21.4344L73.2853 42.3688Z"
        fill="#D6DADF"
      />
      <g filter="url(#filter0_b_2840_11413)">
        <rect
          x="50.9043"
          y="76.5"
          width="44"
          height="44"
          rx="22"
          fill="#344054"
          fillOpacity="0.4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.321 89C67.3964 89 63.4043 92.9921 63.4043 97.9167C63.4043 102.841 67.3964 106.833 72.321 106.833C74.7201 106.833 76.8979 105.886 78.5007 104.345C78.5338 104.297 78.5716 104.252 78.614 104.21C78.6563 104.167 78.7016 104.129 78.749 104.096C80.2902 102.494 81.2376 100.316 81.2376 97.9167C81.2376 92.9921 77.2455 89 72.321 89ZM80.7151 104.896C82.2902 103.004 83.2376 100.571 83.2376 97.9167C83.2376 91.8876 78.3501 87 72.321 87C66.2919 87 61.4043 91.8876 61.4043 97.9167C61.4043 103.946 66.2919 108.833 72.321 108.833C74.9755 108.833 77.4087 107.886 79.3009 106.311L82.6972 109.707C83.0877 110.098 83.7209 110.098 84.1114 109.707C84.5019 109.317 84.5019 108.683 84.1114 108.293L80.7151 104.896Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_2840_11413"
          x="33.4498"
          y="59.0455"
          width="78.9091"
          height="78.9091"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.72727" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2840_11413"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2840_11413"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2840_11413"
          x1="73.2854"
          y1="43.6352"
          x2="128.269"
          y2="54.5819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F2F4F7" />
        </linearGradient>
      </defs>
    </svg>
  );
};
