import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "application/theme";
import React, {useEffect, useState} from "react";
import { RootState } from "application/store";
import CustomSelect from "application/components/CustomSelect";
import { range } from "ramda";
import { useSelector } from "react-redux";
import { PaginationWrapper, StyledIconButton } from "./styles";
import { useNavigate } from "react-router-dom";

export interface PaginationI {
  totalElements: number;
  totalPages: number;
  numberOfElements: number;
  pageRequest: (page: number) => void;
  currentPage: number;
  currentPath: string;
}

const Pagination = ({
  totalElements,
  totalPages,
  numberOfElements,
  pageRequest,
  currentPage,
  currentPath,
}: PaginationI) => {
  const [page, setPage] = useState(currentPage - 1);
  const navigate = useNavigate();
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const pages = range(1, totalPages + 1);
  const disableNext = page >= totalPages - 1;
  const disablePrev = page <= 0;
  useEffect(() => {
    setPage(currentPage - 1);
  }, [currentPage])

  const nextPage = () => {
    if (!disableNext) {
      setPage(page + 1);
      pageRequest(page + 1);
      navigate(`${currentPath}?${page + 2}`);
    }
  };
  const prevPage = () => {
    if (!disablePrev) {
      setPage(page - 1);
      pageRequest(page - 1);
      navigate(`${currentPath}?${page}`);
    }
  };
  const onSelectChange = (value: string) => {
    setPage(Number(value) - 1);
    pageRequest(Number(value) - 1);
    navigate(`${currentPath}?${Number(value)}`);
  };

  return (
    <PaginationWrapper>
      <Box pl={2.5}>
        {totalElements && !isLoading ? (
          <Typography
            sx={{ fontSize: theme.spacing(1.5), lineHeight: "140%" }}
            color={theme.palette.common.grey800}
          >
            {`${1 + page * 10}-${page * 10 + numberOfElements}`} of{" "}
            {totalElements} results
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      {totalElements > 0 && (
        <Box display="flex">
          <CustomSelect
            label={`Page ${(page + 1).toString()}`}
            values={pages}
            selected={page + 1}
            onChange={(value) => onSelectChange(value)}
            height={totalPages * 24}
            maxHeight={166}
            top
            itemWidth={theme.spacing(11.25)}
            fontWeight={400}
          />
          <Box display="flex">
            <StyledIconButton onClick={prevPage} disabled={disablePrev}>
              <NavigateBefore
                sx={{
                  color: disablePrev
                    ? theme.palette.common.grey400
                    : theme.palette.common.grey800,
                }}
              />
            </StyledIconButton>
            <StyledIconButton onClick={nextPage} disabled={disableNext}>
              <NavigateNext
                sx={{
                  color: disableNext
                    ? theme.palette.common.grey400
                    : theme.palette.common.grey800,
                }}
              />
            </StyledIconButton>
          </Box>
        </Box>
      )}
    </PaginationWrapper>
  );
};
export default Pagination;
