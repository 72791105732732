import React, { FC } from "react";

import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

import theme from "application/theme";
import { ErrorPageWrapper } from "./styles";
import { Button } from "application";

import { NoInternetConnectionImg } from "./noInternetConnectionImg";

export const NoInternetConnectionPage: FC = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <ErrorPageWrapper>
      <Box display="flex" flexDirection="column" gap={2.5} alignItems="center">
        <NoInternetConnectionImg />
        <Typography variant="subtitle2" color={theme.palette.common.grey900}>
          Ooops! No Internet connection found.
        </Typography>
        <Typography variant="overline" color={theme.palette.common.grey700}>
          Check your connection.
        </Typography>
        <Button label="Try Again" onClick={refreshPage} width={30} />
      </Box>
    </ErrorPageWrapper>
  );
};
