import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";
import {
  ICreditRequests,
  ISelectedSurvey,
  ISurveys,
  RenewSubscriptionsI,
  SuggestionsI,
} from "./types";
import { OrganizationProfile } from "common/organization";

export const initialState = {
  requestsData: [] as OrganizationProfile[],
  suggestions: [] as SuggestionsI[],
  surveys: [] as ISurveys[],
  selectedSurvey: {} as ISelectedSurvey,
  creditRequests: [] as ICreditRequests[],
  renewRequests: [] as RenewSubscriptionsI[],
  viewModal: false,
  activateSurveyModal: false,
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setRequestsData: (state, { payload }) => {
      state.requestsData = payload;
    },
    setSearchSuggestions: (state, { payload }) => {
      state.suggestions = payload;
    },
    setSurveys: (state, { payload }) => {
      state.surveys = payload;
    },
    setCreditRequests: (state, { payload }) => {
      state.creditRequests = payload;
    },
    setRenewRequests: (state, { payload }) => {
      state.renewRequests = payload;
    },
    setSelectedSurvey: (state, { payload }) => {
      state.selectedSurvey = payload;
    },
    openViewModal: (state) => {
      state.viewModal = true;
    },
    closeViewModal: (state) => {
      state.viewModal = false;
    },
    openActivateSurveyModal: (state) => {
      state.activateSurveyModal = true;
    },
    closeActivateSurveyModal: (state) => {
      state.activateSurveyModal = false;
    },
  },
});

export default requestsSlice.reducer;

export const requestsSelector = () => store.getState().requests;

export const surveySelector = () => store.getState().requests.selectedSurvey;

export const viewModalSelector = () => store.getState().requests.viewModal;

export const viewActivateSurveyModalSelector = () =>
  store.getState().requests.activateSurveyModal;

export const { setRequestsData } = requestsSlice.actions;
export const { setSearchSuggestions } = requestsSlice.actions;
export const { setSurveys } = requestsSlice.actions;
export const { setCreditRequests } = requestsSlice.actions;
export const { setRenewRequests } = requestsSlice.actions;
export const { setSelectedSurvey } = requestsSlice.actions;
export const { openViewModal } = requestsSlice.actions;
export const { closeViewModal } = requestsSlice.actions;
export const { openActivateSurveyModal } = requestsSlice.actions;
export const { closeActivateSurveyModal } = requestsSlice.actions;
