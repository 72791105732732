import { Typography } from "@mui/material";
import theme from "application/theme";
import React from "react";
import { ArrowOutward } from "application/assets/ArrowOutward";
import {
  ContactCardWrapper,
  ContactLink,
  TypographyWithOverflow,
} from "./styles";

type ContactCardT = {
  name: string;
  label: string;
  link?: boolean;
  url?: string;
  withIcon?: boolean;
};

const ContactCard = ({
  name,
  label,
  link,
  withIcon,
  url = "#",
}: ContactCardT) => {
  return (
    <ContactCardWrapper>
      <Typography variant="caption" color={theme.palette.common.grey800}>
        {name}
      </Typography>
      {label ? (
        <>
          {link ? (
            <ContactLink href={url} target="_blank" rel="noopener noreferrer">
              <TypographyWithOverflow variant="button" mr={0.5}>
                {label}
              </TypographyWithOverflow>
              {withIcon && <ArrowOutward />}
            </ContactLink>
          ) : (
            <Typography variant="button" color={theme.palette.common.grey800}>
              {label}
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="button" mr={0.5}>
          -
        </Typography>
      )}
    </ContactCardWrapper>
  );
};

export default ContactCard;
