import { IconButton, InputAdornment, TextFieldProps } from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useState } from "react";
import { MTheme } from "application/theme";
import { HidePasswordIcon } from "../assets/HidePasswordIcon";
import { ShowPasswordIcon } from "../assets/ShowPasswordIcon";
import { TextFieldStyled } from "../styles";

export const PasswordInput = (
  props: TextFieldProps & {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    customError?: boolean | null;
    errorMessage?: string;
  }
) => {
  const { label, setValue, customError, errorMessage } = props;
  const theme: MTheme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextFieldStyled
      fullWidth
      type={showPassword ? "text" : "password"}
      label={label}
      size="small"
      error={customError !== null && customError}
      onChange={(e) => setValue(e.target.value)}
      helperText={customError !== null && customError && errorMessage}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? (
                <ShowPasswordIcon
                  color={
                    customError
                      ? theme.palette.common.red600
                      : theme.palette.common.grey600
                  }
                />
              ) : (
                <HidePasswordIcon
                  color={
                    customError
                      ? theme.palette.common.red600
                      : theme.palette.common.grey600
                  }
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
