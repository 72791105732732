import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";
import { SaasAdminI } from "./types";

export const initialState = {
  saasAdminList: [] as SaasAdminI[],
  saasAdminProfile: {} as SaasAdminI,
  suggestions: [
    {
      id: 0,
      organizationName: "",
    },
  ],
  isDeleteModalOpen: false,
  isAddAdminModalOpen: false,
  deleteSaasAdmin: {} as SaasAdminI,
};

export const saasAdminUsersManagementSlice = createSlice({
  name: "saasAdminManagement",
  initialState,
  reducers: {
    setSaasAdminList: (state, { payload }) => {
      state.saasAdminList = payload;
    },
    setIsDeleteModalOpen: (state, { payload }) => {
      state.isDeleteModalOpen = payload;
    },
    setIsAddAdminModalOpen: (state, { payload }) => {
      state.isAddAdminModalOpen = payload;
    },
    setDeleteSaasAdmin: (state, { payload }) => {
      state.deleteSaasAdmin = payload;
    },
    setSuggestions: (state, { payload }) => {
      state.suggestions = payload;
    },
    setSaasAdminProfile: (state, { payload }) => {
      state.saasAdminProfile = payload;
    },
  },
});

export default saasAdminUsersManagementSlice.reducer;

export const saasAdminSelector = () => store.getState().saasAdminManagement;

export const { setSaasAdminList } = saasAdminUsersManagementSlice.actions;
export const { setIsDeleteModalOpen } = saasAdminUsersManagementSlice.actions;
export const { setIsAddAdminModalOpen } = saasAdminUsersManagementSlice.actions;
export const { setDeleteSaasAdmin } = saasAdminUsersManagementSlice.actions;
export const { setSuggestions } = saasAdminUsersManagementSlice.actions;
export const { setSaasAdminProfile } = saasAdminUsersManagementSlice.actions;
