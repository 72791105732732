import React, { FC, useState } from "react";
import { Typography } from "@mui/material";
import CryptoJS from "crypto-js";
import { InfoWrapper, FormWrapper, SubmitButton } from "../styles";
import { useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import { AuthPageWrapper } from "../AuthPageLayout";
import { CreatePasswordForm } from "../PasswordInputs/CreatePasswordForm";
import { useLocation, useNavigate } from "react-router-dom";
import { PathNames } from "application/routes";
import { confirmAdminRegistration } from "integration/api/saasAdmin";

export const Registration: FC = () => {
  const theme: MTheme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.search.split("?email=").pop()?.split("&")[0] ?? "";
  const code = location.search.split("code=")[1] ?? "";

  const [password, setPassword] = useState<string | null>(null);
  const onSubmit = async () => {
    if (password) {
      const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
      const parsedKey = CryptoJS.enc.Base64.parse(key);
      const parsedPassword = CryptoJS.enc.Utf8.parse(password);
      const encryptedPassword = CryptoJS.AES.encrypt(
        parsedPassword,
        parsedKey,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
      ).toString();
      const response = await confirmAdminRegistration({
        password: encryptedPassword,
        code,
        email,
      });
      if (response) {
        navigate(PathNames.login);
      }
    }
  };

  return (
    <AuthPageWrapper>
      <InfoWrapper mt={12}>
        <Typography variant="h4" mb={1.5}>
          Welcome to BuilderBinder!
        </Typography>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={6}>
          Please, create password for your account.
        </Typography>
        <FormWrapper>
          <CreatePasswordForm getPassword={setPassword} />
          <SubmitButton type="submit" variant="contained" onClick={onSubmit}>
            Set password and continue
          </SubmitButton>
        </FormWrapper>
      </InfoWrapper>
    </AuthPageWrapper>
  );
};
