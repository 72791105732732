import {
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const ListWrapper = styled(Box)`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const StyledPersonName = styled("span")`
  font-weight: 500;
  font-size: ${theme.spacing(3)};
  line-height: 140%;
  color: ${theme.palette.common.turquoise600};
`;
