import { warningColor } from '../../utils';
import { formatNumbersWithSeparator, getPercents } from 'application/utils/numberUtils';
import { StyledWarning } from './styles';
import React from 'react';
import { Typography } from '@mui/material';
import theme from 'application/theme';

interface CreditsItemProps {
  credits: number,
  usedCredits: number,
}

const CreditsItem = ({
                       credits,
                       usedCredits,
                     }: CreditsItemProps) => {
  const getWarningColor = () => {
    return warningColor(getPercents(usedCredits, credits))
  }

  return <><Typography
    variant="caption"
    color={theme.palette.common.grey800}
    mr={2}
    sx={{display: "inline-block", alignItems: "center"}}
  >
    {`${formatNumbersWithSeparator(usedCredits || 0)}/${formatNumbersWithSeparator(credits || 0)}`}
  </Typography>
    {!!credits &&
      <StyledWarning
        bgcolor={getWarningColor().bgColor}
        color={getWarningColor().textColor}
        sx={{display: "inline-block", alignSelf: "center"}}
      >
        {getPercents(usedCredits, credits)}%
      </StyledWarning>
    }
  </>
}

export default CreditsItem;
