import theme from "application/theme";
import React from "react";

export const SuccessIcon = ({
  color = theme.palette.common.green800,
}: {
  color?: string;
}) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <mask
          id="mask0_263_16241"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="42"
          height="42"
        >
          <rect id="Bounding box" width="42" height="42" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_263_16241)">
          <path
            id="check"
            d="M16.7123 31.4998L6.7373 21.5248L9.23105 19.0311L16.7123 26.5123L32.7686 10.4561L35.2623 12.9498L16.7123 31.4998Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
