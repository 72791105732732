import {Control, Controller} from "react-hook-form";
import {CustomSwitch} from "./styles";
import React from "react";

interface ToggleButtonProps {
    defaultValue?: boolean;
    fieldName: string;
    control: Control<any, any>;
}
export const ToggleButton = ({ fieldName, defaultValue, control }: ToggleButtonProps) => {
    return (
        <Controller
            name={fieldName}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
                <CustomSwitch
                    name={field.name}
                    checked={field.value || false}
                    onChange={field.onChange}
                />
            )}
        />
    )
}