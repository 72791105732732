import React from "react";

import { Box, Typography } from "@mui/material";

import { HeaderWrapper } from "./styles";

import { Button } from "application";

import theme from "application/theme";

import { ExportIcon } from "../../assets/ExportIcon";
import AddIcon from "@mui/icons-material/Add";

interface HeaderProps {
  title: string;
  addBtnText?: string;
  exportBtnText?: string;
  addBtnAction?: () => void;
  exportBtnAction?: () => void;
}
const HeaderComponent = ({
  title,
  addBtnText,
  addBtnAction,
  exportBtnText,
  exportBtnAction,
}: HeaderProps) => {
  return (
    <HeaderWrapper>
      <Typography
        fontSize={24}
        fontWeight={500}
        color={theme.palette.common.grey900}
      >
        {title}
      </Typography>
      <Box display={"flex"} gap={theme.spacing(2)}>
        <>
          {exportBtnAction && (
            <Button
              onClick={exportBtnAction}
              label={exportBtnText || ""}
              startAdornment={<ExportIcon color={theme.palette.common.white} />}
              height={5.5}
              type={"button"}
            />
          )}
          {addBtnAction && (
            <Button
              label={addBtnText || ""}
              onClick={addBtnAction}
              startAdornment={<AddIcon />}
              height={5.5}
              type="submit"
            />
          )}
        </>
      </Box>
    </HeaderWrapper>
  );
};

export default HeaderComponent;
