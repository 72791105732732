import React from "react";

import { useAppDispatch, useAppSelector } from "application/store";
import { handleActivateOrganizations } from "../store/actions";
import {
  closeActivateModal,
  isActivatedModalOpen,
  organizationsSelector,
} from "../store";

import { QuestionIcon } from "application/assets/QuestionIcon";
import Button from "application/components/Button";
import { ModalComponent } from "application";

import theme from "application/theme";
import { Box, Typography } from "@mui/material";
import { CompanyNameStyled, GreenIconBackground } from "./styles";
import { ActivationStatus } from "..";
import { handleActivateRequestOrg } from "common/requests/store/actions";

export const ActivateModal = ({
  status,
}: {
  status: ActivationStatus | string;
}) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isActivatedModalOpen);
  const { organizationProfile } = useAppSelector(organizationsSelector);

  const handleClose = () => {
    dispatch(closeActivateModal());
  };

  const onSubmit = () => {
    if (status === "WAITING_FOR_ACTIVATION") {
      dispatch(handleActivateRequestOrg(organizationProfile.id));
    } else {
      dispatch(handleActivateOrganizations(organizationProfile.id));
    }
    handleClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={theme.spacing(3)}
      width={475}
    >
      <Box
        textAlign="center"
        display={"flex"}
        flexDirection={"column"}
        gap={theme.spacing(3)}
      >
        <Box display="flex" width={"100%"} justifyContent="center">
          <GreenIconBackground>
            <QuestionIcon color={theme.palette.common.green900} />
          </GreenIconBackground>
        </Box>
        <Box>
          <Typography variant="h5" color={theme.palette.common.grey800}>
            Are you sure to activate&nbsp;
            <CompanyNameStyled>
              {organizationProfile.companyName}?
            </CompanyNameStyled>
          </Typography>
        </Box>
        <Typography
          variant="caption"
          color={theme.palette.common.grey700}
          p={theme.spacing(0, 2)}
        >
          After activation contact person:&nbsp;
          <CompanyNameStyled>
            {organizationProfile.contactPerson}
          </CompanyNameStyled>
          &nbsp; will get link with access to the system.
        </Typography>
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button
            label="No"
            height={5.5}
            width={15}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Yes"
            height={5.5}
            width={15}
            type="submit"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};
