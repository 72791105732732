import React from "react";

import { useAppDispatch } from "application/store";
import { openAddModal } from "common/organization/store";

import Organizations from "common/organization/OrganizationsList";

import { HeaderComponent } from "application";
import { handleExportAllOrgProfile } from "common/organization/store/actions";

const OrganizationPage = () => {
  const dispatch = useAppDispatch();
  return (
    <>
      <HeaderComponent
        title={"Active Organizations List"}
        addBtnText="Add new organization"
        exportBtnText="Export All"
        exportBtnAction={() => dispatch(handleExportAllOrgProfile())}
        addBtnAction={() => dispatch(openAddModal())}
      />
      <Organizations />
    </>
  );
};

export default OrganizationPage;
