import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";
import theme from "application/theme";
import { ModalComponent } from "application";
import { closeViewModal, surveySelector, viewModalSelector } from "../store";
import { formatDate } from "application/helpers";

interface InfoBoxProps {
  label: string;
  value: React.ReactNode;
}

const InfoBox: React.FC<InfoBoxProps> = ({ label, value }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" color={theme.palette.common.grey800}>
        {label}
      </Typography>
      <Typography
        variant="overline"
        color={theme.palette.common.grey800}
        pt={1.25}
        pb={1.25}
      >
        {value}
      </Typography>
    </Box>
  );
};

export const ViewSurveyRequestModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(viewModalSelector);
  const survey = useSelector(surveySelector);

  const handleClose = () => {
    dispatch(closeViewModal());
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={theme.spacing(3)}
      title="View request"
      width={540}
    >
      <Box mt={4} display="flex" flexDirection="column" gap={theme.spacing(2)}>
        {survey.companyName && (
          <InfoBox label="Organization name" value={survey.companyName} />
        )}
        {survey.website && (
          <InfoBox label="Company website" value={survey.website} />
        )}
        {survey.contactPerson && (
          <InfoBox label="Customer name" value={survey.contactPerson} />
        )}
        {survey.emailContactPerson && (
          <InfoBox
            label="Work email"
            value={
              <Box display="flex" alignItems="center" gap={1}>
                <EmailOutlined
                  sx={{
                    color: theme.palette.common.grey600,
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                  }}
                />
                {survey.emailContactPerson}
              </Box>
            }
          />
        )}
        {survey.timezone && (
          <InfoBox label="Timezone" value={survey.timezone} />
        )}
        {survey.requestDate && (
          <InfoBox
            label="Request date"
            value={formatDate(survey.requestDate)}
          />
        )}
      </Box>
    </ModalComponent>
  );
};
